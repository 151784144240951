import {
  Box, Button, Checkbox, Divider, FormControlLabel, FormGroup, Grid,
  LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow, TextField, Typography,
} from '@mui/material';
import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import Fuse from 'fuse.js';
import config from '../../../../config';
import RequestService from '../../../../services/RequestService';
import WushCard from '../../../../components/WushCard';
import Loader from '../../../../components/loader/Loader';

const Pickups = ({ clientStore, itemCategoryStore }) => {
  const [reservationTypeFilter, setReservationTypeFilter] = React.useState([]);
  const [itemCategoryFilter, setItemCategoryFilter] = React.useState([]);
  const [paymentStatusFilter, setPaymentStatusFilter] = React.useState([]);
  const [selectedPickups, setSelectedPickups] = React.useState([]);
  const [search, setSearch] = React.useState();
  const [pickups, setPickups] = React.useState(null);

  const history = useHistory();

  const fuseOptions = {
    keys: [
      { name: 'id', weight: 1.0 },
      'reservationId',
      'reservation.propertyName',
      'reservation.customerName',
    ],
  };

  clientStore.getClients();
  itemCategoryStore.getItemCategories();

  if (!pickups) {
    RequestService.get(`${config.backendUrl}/pickups`, (response) => {
      setPickups(response.data);
    });
  }

  if (!pickups) return <Loader />;

  if (!itemCategoryStore.itemCategoriesPreLoaded) return (<LinearProgress />);

  const fusePickups = new Fuse(pickups, fuseOptions);

  const filterPickups = () => pickups.filter((delivery) => (
    reservationTypeFilter.includes(delivery.reservation.reservationType)
      || delivery.reservation.itemCategoryNames.some(
        (itemCategoryName) => itemCategoryFilter.includes(itemCategoryName),
      )
      || paymentStatusFilter.includes(delivery.reservation.paid ? 'paid' : 'unpaid')
  ));

  const handleFilterCheck = (filterValue, checked) => {
    if (checked) {
      setReservationTypeFilter(reservationTypeFilter.concat([filterValue]));
    } else {
      setReservationTypeFilter(reservationTypeFilter.filter((e) => e !== filterValue));
    }
  };

  const handleCategoryFilterCheck = (filterValue, checked) => {
    if (checked) {
      setItemCategoryFilter(itemCategoryFilter.concat([filterValue]));
    } else {
      setItemCategoryFilter(itemCategoryFilter.filter((e) => e !== filterValue));
    }
  };

  const handlePaymentStatusFilterCheck = (filterValue, checked) => {
    if (checked) {
      setPaymentStatusFilter(paymentStatusFilter.concat([filterValue]));
    } else {
      setPaymentStatusFilter(paymentStatusFilter.filter((e) => e !== filterValue));
    }
  };

  const handleSearch = (value) => {
    setSearch(value);
  };

  const filteredpickups = () => {
    if (reservationTypeFilter.length > 0
        || itemCategoryFilter.length > 0
        || paymentStatusFilter.length > 0
    ) {
      return filterPickups();
    }
    if (search) {
      return fusePickups.search(search).map((item) => item.item);
    }

    return pickups;
  };

  const createPickupRoute = () => {
    RequestService.post(`${config.backendUrl}/pickup-routes`, {
      pickupRoute:
        {
          pickupIds: selectedPickups,
        },
    }, (response) => {
      history.push(`/reservation-management/pickup-routes/${response.data.id}`);
    });
  };

  const handleCheckPickup = (filterValue, checked) => {
    if (checked) {
      setSelectedPickups(selectedPickups.concat([filterValue]));
    } else {
      setSelectedPickups(selectedPickups.filter((e) => e !== filterValue));
    }
  };

  return (
    <Box pt={2}>
      <Grid container>
        <Grid item md={8} textAlign="left">
          <Box mb={2}>
            <TextField
              onChange={(e) => handleSearch(e.target.value)}
              value={search}
              label="Search"
              fullWidth
            />
          </Box>
        </Grid>
        <Grid item md={4} textAlign="right">
          <Box mb={2}>
            <Button variant="contained" onClick={createPickupRoute} disabled={selectedPickups.length === 0}>
              Create Route
            </Button>
          </Box>
        </Grid>
        <Grid item md={3}>
          <Box mr="2rem">
            <WushCard>
              <Typography variant="h5">
                Reservation Type
              </Typography>
              <Divider />
              <Box p="1rem">
                <FormGroup>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        onChange={(e) => handleFilterCheck('equipment', e.target.checked)}
                        checked={reservationTypeFilter.includes('equipment')}
                      />
)}
                    label="Equipment"
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        onChange={(e) => handleFilterCheck('linens', e.target.checked)}
                        checked={reservationTypeFilter.includes('linens')}
                      />
)}
                    label="Linens"
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        onChange={(e) => handleFilterCheck('events', e.target.checked)}
                        checked={reservationTypeFilter.includes('events')}
                      />
)}
                    label="Events"
                  />
                </FormGroup>
              </Box>
              <Typography variant="h5">
                Item Category
              </Typography>
              <Divider />
              <Box p="1rem">
                <FormGroup>
                  {itemCategoryStore.itemCategories.map((itemCategory) => (
                    <FormControlLabel
                      control={(
                        <Checkbox
                          onChange={(e) => handleCategoryFilterCheck(
                            itemCategory.name, e.target.checked,
                          )}
                          checked={itemCategoryFilter.includes(itemCategory.name)}
                        />
)}
                      label={itemCategory.name}
                    />
                  ))}
                </FormGroup>
              </Box>
              <Typography variant="h5">
                Payment Status
              </Typography>
              <Divider />
              <Box p="1rem">
                <FormGroup>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        onChange={(e) => handlePaymentStatusFilterCheck('paid', e.target.checked)}
                        checked={paymentStatusFilter.includes('paid')}
                      />
)}
                    label="Paid"
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        onChange={(e) => handlePaymentStatusFilterCheck('unpaid', e.target.checked)}
                        checked={paymentStatusFilter.includes('unpaid')}
                      />
)}
                    label="Unpaid"
                  />
                </FormGroup>
              </Box>
            </WushCard>
          </Box>
        </Grid>
        <Grid item md={9}>
          {
            filteredpickups().map((delivery) => (
              <Box mb={2}>
                <WushCard>
                  <Box>
                    <Grid container>
                      <Grid item md={1}>
                        <Box p={2} pt={5}>
                          <Checkbox
                            checked={selectedPickups.includes(delivery.id)}
                            onChange={(e) => handleCheckPickup(delivery.id, e.target.checked)}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        </Box>
                      </Grid>
                      <Grid item md={3}>
                        <Box p={2}>
                          <Box mb={2}>
                            <Box>
                              <Typography variant="p">
                                Reservation #
                                {delivery.reservationId}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography variant="p">
                                {delivery.reservation.customerName}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography variant="p">
                                {delivery.reservation.customerPhone}
                              </Typography>
                            </Box>
                          </Box>
                          <Box>
                            <Typography variant="body1">
                              {delivery.reservation.propertyName}
                            </Typography>
                            <Typography variant="body1">
                              {delivery.reservation.propertyAddress}
                            </Typography>
                            <Typography variant="body1">
                              {`${delivery.reservation.propertyCity}, ${delivery.reservation.propertyState} ${delivery.reservation.propertyZip}`}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item md={8}>
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  Item Name
                                </TableCell>
                                <TableCell>
                                  Quantity
                                </TableCell>
                                <TableCell>
                                  Delivery Date
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {delivery.reservationItems.map((reservationItem) => (
                                <TableRow>
                                  <TableCell>
                                    <Typography variant="body1">{reservationItem.itemName}</Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography variant="body1">{reservationItem.reservedQuantity}</Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography variant="body1">{reservationItem.startDate}</Typography>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  </Box>
                </WushCard>
              </Box>
            ))
          }
        </Grid>
      </Grid>
    </Box>
  );
};

Pickups.propTypes = {
  clientStore: PropTypes.any,
};

export default observer(Pickups);
