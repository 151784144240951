import {
  Box, Checkbox, Divider, FormControlLabel, FormGroup, Grid,
  Link, Typography,
} from '@mui/material';
import React from 'react';
import { observer } from 'mobx-react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { DataGrid } from '@material-ui/data-grid';
import WushCard from '../../components/WushCard';
import Loader from '../../components/loader/Loader';

const Reservations = ({ reservationStore, clientStore, itemCategoryStore }) => {
  const [reservationTypeFilter, setReservationTypeFilter] = React.useState([]);
  const [itemCategoryFilter, setItemCategoryFilter] = React.useState([]);
  const [paymentStatusFilter, setPaymentStatusFilter] = React.useState([]);

  reservationStore.getReservations();
  clientStore.getClients();
  itemCategoryStore.getItemCategories();

  if (!reservationStore.reservationsPreLoaded) return <Loader />;
  if (!itemCategoryStore.itemCategoriesPreLoaded) return <Loader />;

  const filteredReservations = () => reservationStore.reservations.filter((reservation) => (
    reservationTypeFilter.includes(reservation.reservationType)
      || reservation.itemCategoryNames.some(
        (itemCategoryName) => itemCategoryFilter.includes(itemCategoryName),
      )
      || paymentStatusFilter.includes(reservation.paid ? 'paid' : 'unpaid')
  ));

  const handleFilterCheck = (filterValue, checked) => {
    if (checked) {
      setReservationTypeFilter(reservationTypeFilter.concat([filterValue]));
    } else {
      setReservationTypeFilter(reservationTypeFilter.filter((e) => e !== filterValue));
    }
  };

  const handleCategoryFilterCheck = (filterValue, checked) => {
    if (checked) {
      setItemCategoryFilter(itemCategoryFilter.concat([filterValue]));
    } else {
      setItemCategoryFilter(itemCategoryFilter.filter((e) => e !== filterValue));
    }
  };

  const handlePaymentStatusFilterCheck = (filterValue, checked) => {
    if (checked) {
      setPaymentStatusFilter(paymentStatusFilter.concat([filterValue]));
    } else {
      setPaymentStatusFilter(paymentStatusFilter.filter((e) => e !== filterValue));
    }
  };

  const columns = [
    {
      field: 'id',
      headerName: '#',
      width: 70,
      editable: false,
    },
    {
      field: 'customer',
      headerName: 'Customer',
      width: 150,
      editable: false,
    },
    {
      field: 'property',
      headerName: 'Property',
      width: 150,
      editable: false,
    },
    {
      field: 'deliveryDate',
      headerName: 'Delivery Date',
      width: 150,
      editable: false,
    },
    {
      field: 'pickupDate',
      headerName: 'Pickup Date',
      width: 150,
      editable: false,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      editable: false,
    },
    {
      field: 'paymentStatus',
      headerName: 'Payment Status',
      width: 150,
      editable: false,
      renderCell: ({ value, id }) => (
        <Link
          variant="contained"
          color={value === 'Paid' ? 'primary' : 'secondary'}
          href={`/reservation-management/invoices/${id}`}
        >
          {value}
        </Link>
      ),
    },
  ];

  const reservations = reservationTypeFilter.length > 0
    || itemCategoryFilter.length > 0
    || paymentStatusFilter.length > 0 ? filteredReservations() : reservationStore.reservations;

  const reservationRows = reservations.map((r) => (
    {
      id: r.id,
      date: r.startDate && format(new Date(r.startDate), 'PP'),
      customer: r.customerName,
      property: r.propertyName,
      deliveryDate: r.startDate,
      pickupDate: r.endDate,
      paymentStatus: r.paid ? 'Paid' : 'Unpaid',
      status: r.status,
    }
  ));

  return (
    <Box pt={2}>
      <Grid container>
        <Grid item md={3}>
          <Box mr="2rem">
            <WushCard>
              <Typography variant="h5">
                Reservation Type
              </Typography>
              <Divider />
              <Box p="1rem">
                <FormGroup>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        onChange={(e) => handleFilterCheck('equipment', e.target.checked)}
                        checked={reservationTypeFilter.includes('equipment')}
                      />
)}
                    label="Equipment"
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        onChange={(e) => handleFilterCheck('linens', e.target.checked)}
                        checked={reservationTypeFilter.includes('linens')}
                      />
)}
                    label="Linens"
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        onChange={(e) => handleFilterCheck('events', e.target.checked)}
                        checked={reservationTypeFilter.includes('events')}
                      />
)}
                    label="Events"
                  />
                </FormGroup>
              </Box>
              <Typography variant="h5">
                Item Category
              </Typography>
              <Divider />
              <Box p="1rem">
                <FormGroup>
                  {itemCategoryStore.itemCategories.map((itemCategory) => (
                    <FormControlLabel
                      control={(
                        <Checkbox
                          onChange={(e) => handleCategoryFilterCheck(
                            itemCategory.name, e.target.checked,
                          )}
                          checked={itemCategoryFilter.includes(itemCategory.name)}
                        />
)}
                      label={itemCategory.name}
                    />
                  ))}
                </FormGroup>
              </Box>
              <Typography variant="h5">
                Payment Status
              </Typography>
              <Divider />
              <Box p="1rem">
                <FormGroup>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        onChange={(e) => handlePaymentStatusFilterCheck('paid', e.target.checked)}
                        checked={paymentStatusFilter.includes('paid')}
                      />
)}
                    label="Paid"
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        onChange={(e) => handlePaymentStatusFilterCheck('unpaid', e.target.checked)}
                        checked={paymentStatusFilter.includes('unpaid')}
                      />
)}
                    label="Unpaid"
                  />
                </FormGroup>
              </Box>
            </WushCard>
          </Box>
        </Grid>
        <Grid item md={9}>
          <Box height="1000px">
            <DataGrid
              rows={reservationRows}
              columns={columns}
              pageSize={25}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

Reservations.propTypes = {
  reservationStore: PropTypes.any,
  clientStore: PropTypes.any,
};

export default observer(Reservations);
