import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import PersonIcon from '@mui/icons-material/Person';
import PaymentIcon from '@mui/icons-material/Payment';
import GroupsIcon from '@mui/icons-material/Groups';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import KeyIcon from '@mui/icons-material/Key';
import { useHistory } from 'react-router';
import WushButtonCard from '../../components/WushButtonCard';

const Account = ({ authStore, domainStore }) => {
  const history = useHistory();
  if (authStore.needsNewPassword) history.push('/change-password');

  return (
    <Box pt={2} pb={15}>
      <Box mb={2}>
        <Typography variant="h4">Account</Typography>
      </Box>
      <Box>
        <Grid container>
          <Grid item md={4} width="100%">
            <Box pr={2} pb={2}>
              <WushButtonCard minHeight="11rem" onClick={() => history.push('/profile')}>
                <Box mb={3}>
                  <PersonIcon fontSize="large" />
                </Box>
                <Box>
                  <Typography variant="body1"><strong>Personal Info</strong></Typography>
                  <Typography variant="body1">Update your personal information.</Typography>
                </Box>
              </WushButtonCard>
            </Box>
          </Grid>
          <Grid item md={4} width="100%">
            <Box pr={2} pb={2}>
              <WushButtonCard minHeight="11rem" onClick={() => history.push('/payments')}>
                <Box mb={3}>
                  <PaymentIcon fontSize="large" />
                </Box>
                <Box>
                  <Typography variant="body1"><strong>Payment Info</strong></Typography>
                  <Typography variant="body1">Update your payment information.</Typography>
                </Box>
              </WushButtonCard>
            </Box>
          </Grid>
          {!domainStore.organization && (
          <Grid item md={4} width="100%">
            <Box pr={2} pb={2}>
              <WushButtonCard minHeight="11rem" onClick={() => history.push('/organization')}>
                <Box mb={3}>
                  <GroupsIcon fontSize="large" />
                </Box>
                <Box>
                  <Typography variant="body1"><strong>Organization Info</strong></Typography>
                  <Typography variant="body1">View and update your current organization.</Typography>
                </Box>
              </WushButtonCard>
            </Box>
          </Grid>
          )}
          <Grid item md={4} width="100%">
            <Box pr={2} pb={2}>
              <WushButtonCard minHeight="11rem" onClick={() => history.push('/events')}>
                <Box mb={3}>
                  <FitnessCenterIcon fontSize="large" />
                </Box>
                <Box>
                  <Typography variant="body1"><strong>Class Registration</strong></Typography>
                  <Typography variant="body1">View and register for upcoming classes.</Typography>
                </Box>
              </WushButtonCard>
            </Box>
          </Grid>
          <Grid item md={4} width="100%">
            <Box pr={2} pb={2}>
              <WushButtonCard minHeight="11rem" onClick={() => history.push('/my-events')}>
                <Box mb={3}>
                  <CalendarMonthIcon fontSize="large" />
                </Box>
                <Box>
                  <Typography variant="body1"><strong>My Calendar</strong></Typography>
                  <Typography variant="body1">View your calendar of registered classes.</Typography>
                </Box>
              </WushButtonCard>
            </Box>
          </Grid>
          <Grid item md={4} width="100%">
            <Box pr={2} pb={2}>
              <WushButtonCard minHeight="11rem" onClick={() => history.push('/change-password')}>
                <Box mb={3}>
                  <KeyIcon fontSize="large" />
                </Box>
                <Box>
                  <Typography variant="body1"><strong>Change Password</strong></Typography>
                  <Typography variant="body1">Update your account password</Typography>
                </Box>
              </WushButtonCard>
            </Box>
          </Grid>
          <Grid item md={4} width="100%">
            <Box pr={2} pb={2}>
              <WushButtonCard minHeight="11rem" onClick={() => history.push('/referrals')}>
                <Box mb={3}>
                  <GroupAddIcon fontSize="large" />
                </Box>
                <Box>
                  <Typography variant="body1"><strong>Referrals/Promo Codes</strong></Typography>
                  <Typography variant="body1">See referrals and codes</Typography>
                </Box>
              </WushButtonCard>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Account;
