import {
  Backdrop, Box, Button, CircularProgress, Typography,
} from '@mui/material';
import React, { useMemo, useState } from 'react';

import TokenIcon from '@mui/icons-material/Token';
import { useHistory } from 'react-router';
import WushCard from '../../components/WushCard';
import WushModal from '../../components/WushModal';
import config from '../../config';
import RequestService from '../../services/RequestService';
import EventInfo from './components/EventInfo';
import WushCalendar from './components/WushCalendar';
import { mapCalendarEventToWush, mapWushEventToCalendar } from './services';

const MyEventSeries = ({ alertStore }) => {
  const [myEventSeries, setMyEventSeries] = useState(null);
  const [showViewModal, setShowViewModal] = useState(false);
  const [creditsRemaining, setCreditsRemaining] = useState(null);
  const [viewingEvent, setViewingEvent] = useState(null);

  const [paymentProcessing, setPaymentProcessing] = useState(false);

  const history = useHistory();

  const fetchMyEventSeries = (callback) => {
    RequestService.get(`${config.backendUrl}/public/event_series?onlyEnrolled=true`, (response) => {
      setMyEventSeries(response.data);
      if (callback) callback();
    });
  };

  const handleSelectEvent = (event, remainingCredits) => {
    setViewingEvent(mapCalendarEventToWush(event));
    setCreditsRemaining(remainingCredits);
    setShowViewModal(true);
  };

  const handleEnrollment = (paymentMethodId) => {
    if (!viewingEvent.userEnrolled) {
      setShowViewModal(false);
      setPaymentProcessing(true);
      RequestService.post(`${config.backendUrl}/public/event_registrations`, { event_registration: { eventId: viewingEvent.id, paymentMethodId } }, () => {
        fetchMyEventSeries(() => setPaymentProcessing(false));
        alertStore.setAlertMessage('Successfully registered for event!', 'success');
      });
    }
  };

  const handleUnenrollment = () => {
    if (viewingEvent.userEnrolled) {
      RequestService.delete(`${config.backendUrl}/public/event_registrations/${viewingEvent.registrationId}`, {}, () => {
        fetchMyEventSeries(() => setShowViewModal(false));
        alertStore.setAlertMessage('Successfully unregistered for event!', 'success');
      });
    }
  };

  const { scrollToTime } = useMemo(
    () => ({
      scrollToTime: new Date(1970, 1, 1, 4),
    }),
    [],
  );

  if (!myEventSeries) fetchMyEventSeries();
  if (!myEventSeries) return <CircularProgress />;

  return (
    <Box pt={2}>
      <Box mb={2}>
        <Typography variant="h4">My Event Series</Typography>
      </Box>
      {myEventSeries.map((eventSerie) => {
        const seriesCreditsRemaining = (
          eventSerie.registration.eventCount - eventSerie.enrolledEvents.length
        );
        return (
          <WushCard>
            <Box mb={2}>
              <Typography variant="h5">{eventSerie.name}</Typography>
              <Button onClick={() => history.push(`/event-series/${eventSerie.id}`)}>Add Credits</Button>
              <Typography variant="h6">
                <TokenIcon />
                {' '}
                {seriesCreditsRemaining}
              </Typography>
              <Typography variant="h6">
                Enrolled in:
                {eventSerie.enrolledEvents.length}
                /
                {eventSerie.registration.eventCount}
              </Typography>
            </Box>
            <WushCalendar
              events={eventSerie.events.map(mapWushEventToCalendar)}
              scrollToTime={scrollToTime}
              onSelectEvent={(event) => handleSelectEvent(event, seriesCreditsRemaining)}
              defaultView="month"
              popup
            />
          </WushCard>
        );
      })}
      <WushModal
        open={showViewModal}
        onClose={() => setShowViewModal(false)}
      >
        {viewingEvent
          && (
          <EventInfo
            event={viewingEvent}
            handleEnrollment={handleEnrollment}
            handleUnenrollment={handleUnenrollment}
            creditsRemaining={creditsRemaining}
          />
          )}
      </WushModal>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={paymentProcessing}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default MyEventSeries;
