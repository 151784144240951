import { observable, action, decorate, computed } from 'mobx';
import config from '../config';
import { ACCOUNT_TYPES } from '../constants';
import RequestService from '../services/RequestService';

class AuthStore {
  constructor() {
    this.buildFromSession();
  }

  loggedIn = false;
  loginFailure = false;
  loginLoading = false;
  accountType = null;
  needsNewPassword = false;
  user = null;

  login = (email, password, successCallback, failureCallback) => {
    this.loginLoading = true;
    RequestService.post(`${config.backendUrl}/login`, { user: { email, password } },
      (response) => {
        this.loggedIn = true;
        this.loginFailure = false;
        this.loginLoading = false;
        this.accountType = response.data.account_type;
        this.needsNewPassword = response.data.needs_new_password;
        this.user = response.data;
        this.writeToSession(response.headers.authorization);
        successCallback();
      }, (error) => {
        this.loginFailure = true;
        this.loginLoading = false;
        this.writeToSession()
        failureCallback && failureCallback()
      }
    );
  }

  register = (email, password, organizationId, successCallback) => {
    RequestService.post(`${config.backendUrl}/signup`, { user: { email, password, organizationId } },
      (response) => {
        this.loggedIn = true;
        this.loginFailure = false;
        this.loginLoading = false;
        this.accountType = response.data.account_type;
        this.writeToSession(response.headers.authorization);
        successCallback();
      }, (error) => {
        this.loginFailure = true;
        this.loginLoading = false;
        this.writeToSession()
      }
    );
  }

  resetPassword = (email, successCallback, failureCallback) => {
    RequestService.post(`${config.backendUrl}/reset-password`, { user: { email } },
      (response) => {
        successCallback && successCallback();
      }, (error) => {
        failureCallback && failureCallback();
      }
    );
  }

  changePassword = (password, successCallback, failureCallback) => {
    RequestService.post(`${config.backendUrl}/change-password`, { user: { password } },
      (response) => {
        this.needsNewPassword = false
        this.writeToSession()
        successCallback && successCallback();
      }, (error) => {
        failureCallback && failureCallback();
      }
    );
  }

  logout = (successCallback) => {
    RequestService.delete(`${config.backendUrl}/logout`,
      () => {
        this.loggedIn = false;
        sessionStorage.clear();
        successCallback();
      });
  }

  writeToSession = (token) => {
    const json = JSON.stringify(this);
    sessionStorage.setItem('AuthStore', json);
    if (token) sessionStorage.setItem('Token', token);
  }

  buildFromSession = () => {
    const json = JSON.parse(sessionStorage.getItem('AuthStore'));
    if (json) {
      this.needsNewPassword = json.needsNewPassword
      this.accountType = json.accountType;
      this.loggedIn = json.loggedIn;
      this.user = json.user
    }
  }

  get isAdmin() {
    return this.accountType === ACCOUNT_TYPES.ADMIN
  }
}

decorate(AuthStore, {
  loggedIn: observable,
  login: action,
  loginFailure: observable,
  loginLoading: observable,
  isAdmin: computed,
  user: observable
});

export default AuthStore;
