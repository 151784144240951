import {
  Box, LinearProgress, Step, StepLabel, Stepper,
} from '@mui/material';
import { FieldArray, Formik } from 'formik';
import { observer } from 'mobx-react';
import React from 'react';
import { useHistory } from 'react-router';
import StepWizard from 'react-step-wizard';
import CustomerSelection from '../new/CustomerSelection';
import ItemSelection from '../new/ItemSelection';
import OrderSummary from '../new/OrderSummary';

const steps = [
  'Choose Items',
  'Select Customer and Property',
  'View Summary',
];

const Navigator = ({ currentStep }) => (
  <Box sx={{ width: '100%' }} mb="2rem">
    <Stepper activeStep={currentStep} alternativeLabel>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  </Box>
);

const handleItemSelect = (addItem, selectedItems, item) => {
  if (!selectedItems.filter((selectedItem) => (
    selectedItem.id === item.id
  )).length) {
    addItem(item);
  }
};

const NewReservation = ({
  itemStore, customerStore, propertyStore, reservationStore,
}) => {
  const history = useHistory();
  itemStore.getItems();
  customerStore.getCustomers();
  propertyStore.getProperties();
  if (!itemStore.itemsPreLoaded
      || !propertyStore.propertiesPreLoaded
      || !customerStore.customersPreLoaded) return <LinearProgress />;
  return (
    <Box pt={2}>
      <Formik
        initialValues={{
          selectedItems: [],
          selectedProperty: null,
          selectedCustomer: null,
        }}
        onSubmit={(values) => {
          reservationStore.createReservation(values, (reservation) => {
            history.push(`/reservation-management/invoices/${reservation.id}`);
          });
        }}
      >
        {
          ({ values, setFieldValue, handleSubmit }) => (
            <FieldArray
              name="selectedItems"
              render={(arrayHelpers) => (
                <StepWizard
                  nav={<Navigator />}
                  isHashEnabled
                >
                  <ItemSelection
                    items={itemStore.items}
                    onItemSelect={(item) => (
                      handleItemSelect(arrayHelpers.push, values.selectedItems, item)
                    )}
                    removeItem={arrayHelpers.remove}
                    selectedItems={values.selectedItems}
                    setFieldValue={setFieldValue}
                    hashKey="item-selection"
                  />
                  <CustomerSelection
                    customers={customerStore.customers}
                    selectedCustomer={values.selectedCustomer}
                    onSelect={(fieldName, item) => (
                      setFieldValue(fieldName, item)
                    )}
                    properties={propertyStore.properties}
                    selectedProperty={values.selectedProperty}
                    hashKey="customer-selection"
                    createCustomer={customerStore.createCustomer}
                  />
                  <OrderSummary
                    hashKey="order-summary"
                    values={values}
                    createReservation={handleSubmit}
                  />
                </StepWizard>
              )}
            />
          )
        }
      </Formik>
    </Box>
  );
};

export default observer(NewReservation);
