import { Box, CircularProgress, Typography } from '@mui/material';
import React, {
  useCallback,
  useEffect, useState,
} from 'react';

import { useRouteMatch } from 'react-router';
import WushModal from '../../../../components/WushModal';
import config from '../../../../config';
import RequestService from '../../../../services/RequestService';
import WushCalendar from '../../../events/components/WushCalendar';
import { mapCalendarEventToWush, mapWushEventToCalendar } from '../../../events/services';
import IframeContainer from '../../components/IframeContainer';

const EventCategory = () => {
  const [eventCategory, setEventCategory] = useState({});
  const [eventCategoryLoading, setEventCategoryLoading] = useState(true);

  const [selectedEvent, setSelectedEvent] = useState(null);

  const match = useRouteMatch();
  const { eventCategoryId } = match.params;

  const fetchEventCategory = () => {
    RequestService.get(`${config.backendUrl}/public/event_categories/${eventCategoryId}`, (response) => {
      setEventCategory(response.data);
      setEventCategoryLoading(false);
    });
  };

  useEffect(() => {
    fetchEventCategory();
  }, []);

  const handleSelectEvent = useCallback(
    (event) => {
      setSelectedEvent(mapCalendarEventToWush(event));
    },
  );

  if (eventCategoryLoading) return <Box textAlign="center" justifyContent="center"><CircularProgress /></Box>;

  return (
    <IframeContainer>
      <Typography variant="h6">{eventCategory.name}</Typography>
      <WushCalendar
        events={eventCategory.events.map(mapWushEventToCalendar)}
        defaultView="month"
        views={['month']}
        style={{ height: '400px' }}
        onSelectEvent={handleSelectEvent}
      />
      <WushModal
        open={selectedEvent}
        onClose={() => setSelectedEvent(null)}
        fullScreen
      >
        {selectedEvent && (
        <Box
          textAlign="center"
        >
          {selectedEvent.name}
        </Box>
        )}
      </WushModal>
    </IframeContainer>
  );
};

export default EventCategory;
