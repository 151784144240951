import React from 'react';
import { Grid } from '@mui/material';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import ScannerTicketPrint from './components/ScannerTicketPrint';

const ScannerPrint = ({ scannerTickets }) => (
  <div className="scanner-print-container">
    {scannerTickets.map((scannerTicket, index) => (
      index % 6 === 0
        && (
        <div className="ticket-page">
          <Grid container className="scanner-ticket-row">
            <Grid item md={6} className="scanner-ticket">
              <ScannerTicketPrint scannerTicket={scannerTickets[index]} />
            </Grid>
            <Grid item md={6} className="scanner-ticket">
              <ScannerTicketPrint scannerTicket={scannerTickets[index + 1]} />
            </Grid>
          </Grid>
          <Grid container className="scanner-ticket-row">
            <Grid item md={6} className="scanner-ticket">
              <ScannerTicketPrint scannerTicket={scannerTickets[index + 2]} />
            </Grid>
            <Grid item md={6} className="scanner-ticket">
              <ScannerTicketPrint scannerTicket={scannerTickets[index + 3]} />
            </Grid>
          </Grid>
          <Grid container className="scanner-ticket-row">
            <Grid item md={6} className="scanner-ticket">
              <ScannerTicketPrint scannerTicket={scannerTickets[index + 4]} />
            </Grid>
            <Grid item md={6} className="scanner-ticket">
              <ScannerTicketPrint scannerTicket={scannerTickets[index + 5]} />
            </Grid>
          </Grid>
        </div>
        )
    ))}
  </div>
);

ScannerPrint.propTypes = {
  scannerTickets: PropTypes.array,
};

export default observer(ScannerPrint);
