import {
  Box, Button, Chip, CircularProgress, Divider, Grid, IconButton, List, ListItem,
  ListItemButton, Typography,
} from '@mui/material';
import { format } from 'date-fns';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import Cards from 'react-credit-cards';
import { observer } from 'mobx-react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import WushButtonCard from '../../components/WushButtonCard';
import WushModal from '../../components/WushModal';
import config from '../../config';
import RequestService from '../../services/RequestService';
import AddPaymentMethod from './AddPaymentMethod';

const Payments = ({ customerStore }) => {
  const [payments, setPayments] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState(null);
  const [showAddPaymentMethodModal, setShowAddPaymentMethodModal] = useState(null);
  const [showDeletePaymentMethodModal, setShowDeletePaymentMethodModal] = useState(null);
  const [paymentIdToDelete, setPaymentIdToDelete] = useState(null);

  const history = useHistory();
  const fetchPayments = () => {
    RequestService.get(`${config.backendUrl}/public/payments`, (response) => {
      setPayments(response.data);
    });
  };

  const fetchPaymentMethods = () => {
    RequestService.get(`${config.backendUrl}/public/payment_methods`, (response) => {
      setPaymentMethods(response.data);
    });
  };

  const updateDefault = (paymentMethodId) => {
    RequestService.post(`${config.backendUrl}/public/payment_methods/${paymentMethodId}/make_default`, {}, () => {
      customerStore.updateStoredCustomer('defaultPaymentMethodId', paymentMethodId);
    });
  };

  const removePaymentMethod = () => {
    RequestService.delete(`${config.backendUrl}/public/payment_methods/${paymentIdToDelete}`, {}, () => {
      fetchPaymentMethods();
      setShowDeletePaymentMethodModal(false);
    });
  };

  if (!payments) fetchPayments();
  if (!paymentMethods) fetchPaymentMethods();

  if (!payments || !paymentMethods) return <CircularProgress />;
  return (
    <Box pt={2}>
      <Box mb={2}>
        <Typography variant="h4">Payment Methods</Typography>
      </Box>
      <Box>
        {paymentMethods && paymentMethods.map((paymentMethod) => (
          <Box mb={3} pr={25}>
            <WushButtonCard
              onClick={() => {}}
            >
              <Grid container>
                <Grid item md={8}>
                  <Box>
                    {paymentMethod.card.brand.toUpperCase()}
                    {' '}
                    ending in
                    {' '}
                    {paymentMethod.card.last4}
                  </Box>
                  <Box>
                    {paymentMethod.id === customerStore.customer.defaultPaymentMethodId
                      ? <Chip size="small" label="Default" color="primary" />
                      : (
                        <Button onClick={() => updateDefault(paymentMethod.id)}>
                          Make Default
                        </Button>
                      )}
                  </Box>
                </Grid>
                <Grid item md={3}>
                  <Cards
                    name=" "
                    expiry={`${paymentMethod.card.exp_month}/${paymentMethod.card.exp_year}`}
                    number={`**** **** **** ${paymentMethod.card.last4}`}
                    preview
                    issuer={paymentMethod.card.brand}
                  />
                </Grid>
                <Grid item md={1}>
                  <IconButton onClick={() => {
                    setShowDeletePaymentMethodModal(true);
                    setPaymentIdToDelete(paymentMethod.id);
                  }}
                  >
                    <DeleteForeverIcon fontSize="large" />
                  </IconButton>
                </Grid>
              </Grid>
            </WushButtonCard>
          </Box>
        ))}
      </Box>
      <Box mb={5}>
        <Button variant="contained" onClick={() => setShowAddPaymentMethodModal(true)}>Add Payment Method</Button>
      </Box>
      <Box mb={5}>
        <Divider />
      </Box>
      <WushModal
        open={showAddPaymentMethodModal}
        onClose={() => setShowAddPaymentMethodModal(false)}
      >
        <Box>
          <AddPaymentMethod handleSuccess={() => {
            setShowAddPaymentMethodModal(false); fetchPaymentMethods();
          }}
          />
        </Box>
      </WushModal>
      <WushModal
        open={showDeletePaymentMethodModal}
        onClose={() => setShowDeletePaymentMethodModal(false)}
      >
        <Box>
          <Box mb={4}>
            <Typography variant="h5">Are you sure you want to remove?</Typography>
          </Box>
          <Button onClick={() => removePaymentMethod()} variant="contained" color="secondary">Remove</Button>
          <Button onClick={() => setShowDeletePaymentMethodModal(false)}>Cancel</Button>
        </Box>
      </WushModal>

      <Typography variant="h4">Payment Info</Typography>
      <Box>
        <List>
          {
            payments.map((payment) => (
              <ListItem>
                <ListItemButton onClick={() => (payment.eventSeriesId ? history.push(`/event-series/${payment.eventSeriesId}`) : history.push('/events'))}>
                  <Grid container>
                    <Grid item md={5}>
                      Paid $
                      {payment.amount}
                      {' '}
                      for
                      {' '}
                      <strong>{payment.eventName || payment.eventSeriesName}</strong>
                      {' '}
                      on
                      {' '}
                      {payment.paymentDate && format(new Date(payment.paymentDate), 'EEEE LLLL dd, yyyy')}
                    </Grid>
                    {payment.paymentMethod && (
                    <Grid item md={4}>
                      {payment.paymentMethod?.toUpperCase()}
                      {' '}
                      ending in
                      {' '}
                      {payment.paymentMethodLast4}
                    </Grid>
                    )}
                    <Grid item md={3}>
                      {payment.paymentStatus === 'paid' ? <Chip size="small" label="Paid" color="primary" />
                        : <Chip size="small" label="Refunded" color="info" /> }
                    </Grid>
                  </Grid>

                </ListItemButton>
              </ListItem>
            ))
          }
        </List>
      </Box>
    </Box>
  );
};

export default observer(Payments);
