import { Box } from '@mui/material';
import React from 'react';

const WushCard = ({ children, noPadding = false }) => (
  <Box border="1px solid lightgrey" borderRadius="10px" p={noPadding ? '0' : '0.5rem'} boxShadow="1px 1px 3px lightgrey">
    {children}
  </Box>
);

export default WushCard;
