import { observable, decorate } from 'mobx';
import config from '../config';
import RequestService from '../services/RequestService';
import BaseStore from './BaseStore';

class SearchStore extends BaseStore {
  searchResults = []
  searchResultsPreLoaded = false
  searchString

  getSearchResults = (searchString) => {
    (!this.searchResultsPreLoaded || searchString !== this.searchString) && RequestService.get(`${config.backendUrl}/search?search=${searchString}`, (response) => {
      this.searchString = searchString
      this.searchResults = response.data
      this.searchResultsPreLoaded = true
    })
  }
}


decorate(SearchStore, {
  searchResults: observable,
  searchResultsPreLoaded: observable
})

export default SearchStore;