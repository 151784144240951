import config from "../../config"
import RequestService from "../RequestService"

const signWaiver = (id, signedByEmail, signedByName, signatureFile, successCallback, failureCallback) => {
  let formData = new FormData()
  formData.append("signed_by_email", signedByEmail)
  formData.append("signed_by_name", signedByName)
  formData.append("signature_file", signatureFile)
  RequestService.post(`${config.backendUrlV2}/customer_waivers/${id}/sign`, formData, successCallback, failureCallback)
}

export default signWaiver;