import { observable, decorate, action } from 'mobx';
import config from '../config';
import RequestService from '../services/RequestService';
import BaseStore from './BaseStore';

class ClientStore extends BaseStore {

  clients = null;
  clientsPreLoaded = false;

  getClients = () => {
    const endpoint = 'clients'
    !this.clientsPreLoaded && RequestService.get(`${config.backendUrl}/${endpoint}`, (response) => {
      response.data.sort((a,b) => { return a.id - b.id })
      this.clients = response.data
      this.clientsPreLoaded = true
      this.writeToSession()
    })
  }

  addClient = () => {
    const endpoint = 'clients'
    RequestService.post(`${config.backendUrl}/${endpoint}`, {client: {name: "placeholder"}}, (response) => {
      let updated = this.clients;
      updated.splice(0, 0, response.data)
      this.clients = updated
      this.writeToSession()
    })
  }

  updateClient = (client) => {
    console.log(client)
    const updatePayload = {
      client: {
        [client.field]: client.value
      }
    }
    RequestService.post(`${config.backendUrl}/clients/${client.id}`, updatePayload, (response) => {
      let updateClients = this.clients;
      updateClients.splice(updateClients.findIndex((client) => client.id === client.id), 1, response.data)
      this.writeToSession()
    })
  }

  deleteClient = (id) => {
    RequestService.delete(`${config.backendUrl}/clients/${id}`, () => {
      let updateClients = this.clients;
      updateClients.splice(updateClients.findIndex((client) => client.id === id), 1)
      this.clients = updateClients
      this.writeToSession()
    })
  }

  writeToSession = () => {
    const json = JSON.stringify(this);
    sessionStorage.setItem('ClientStore', json);
  }

  buildFromSession = () => {
    const json = JSON.parse(sessionStorage.getItem('ClientStore'))
    if (json) {
      this.clients = json.clients
    }
  }
}

decorate(ClientStore, {
  clients: observable,
  clientsPreLoaded: observable,
  getclients: action,
});

export default ClientStore;