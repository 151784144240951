import {
  Box, Grid, Modal,
} from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { format } from 'date-fns';
import ItemCart from './components/ItemCart';
import ItemSearch from './components/ItemSearch';
import EditItemForm from './components/EditItemForm';
import NextBackButton from './components/NextBackButton';

const ItemSelect = ({
  items, nextStep, onItemSelect, selectedItems, setItemQuantity, setFieldValue, removeItem,
}) => {
  const [addItemModalOpen, setAddItemModalOpen] = React.useState(false);
  const [addingItem, setAddingItem] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const toggle = () => setOpen(!open);

  const handleItemSelect = (item) => {
    const updatedItem = item;
    if (selectedItems.length > 0) {
      updatedItem.dateRange = selectedItems[0].dateRange;
      updatedItem.receivingMethod = selectedItems[0].receivingMethod;
      updatedItem.returningMethod = selectedItems[0].returningMethod;
    }
    onItemSelect(updatedItem);
    setAddItemModalOpen(true);
    setAddingItem(updatedItem);
  };

  const handleDateRangeChange = (dateRange) => {
    setFieldValue(
      `selectedItems.${selectedItems.findIndex((item) => item.id === addingItem.id)}.dateRange.startDate`,
      format(dateRange.startDate, "yyyy'-'MM'-'dd"),
    );
    setFieldValue(
      `selectedItems.${selectedItems.findIndex((item) => item.id === addingItem.id)}.dateRange.endDate`,
      format(dateRange.endDate, "yyyy'-'MM'-'dd"),
    );
    setAddingItem(selectedItems[selectedItems.findIndex((item) => item.id === addingItem.id)]);
    toggle();
  };

  return (
    <Grid container>
      <Grid item md={4}>
        <Box m="1rem">
          <ItemSearch
            items={items.sort((a, b) => (a.name > b.name ? 1 : -1))}
            onItemSelect={handleItemSelect}
          />
        </Box>

      </Grid>
      <Grid item md={8}>
        <Box m="1rem">
          <ItemCart
            selectedItems={selectedItems}
            setItemQuantity={setItemQuantity}
            handleItemSelect={handleItemSelect}
          />
        </Box>
      </Grid>
      <NextBackButton nextStep={nextStep} />
      <Modal
        open={addingItem && addItemModalOpen}
        onClose={() => setAddItemModalOpen(false)}
      >
        <EditItemForm
          handleDateRangeChange={handleDateRangeChange}
          addingItem={addingItem}
          selectedItems={selectedItems}
          toggle={toggle}
          open={open}
          removeItem={removeItem}
          setAddItemModalOpen={setAddItemModalOpen}
        />
      </Modal>
    </Grid>
  );
};

export default observer(ItemSelect);
