import { observable, decorate, action } from 'mobx';
import config from '../config';
import RequestService from '../services/RequestService';
import BaseStore from './BaseStore';

class EventCategoryStore extends BaseStore {
  constructor() {
    super()
    this.buildFromSession();
  }

  eventCategories = null;
  eventCategoriesPreLoaded = false;

  getEventCategories = () => {
    const endpoint = 'event_categories'
    !this.eventCategoriesPreLoaded && RequestService.get(`${config.backendUrl}/${endpoint}`, (response) => {
      response.data.sort((a,b) => { return a.id - b.id })
      this.eventCategories = response.data
      this.eventCategoriesPreLoaded = true
      this.writeToSession()
    })
  }

  addEventCategory = () => {
    const endpoint = 'event_categories'
    RequestService.post(`${config.backendUrl}/${endpoint}`, {eventCategory: {name: "placeholder"}}, (response) => {
      let updated = this.eventCategories;
      updated.splice(0, 0, response.data)
      this.eventCategories = updated
      this.writeToSession()
    })
  }

  updateEventCategory = (eventCategory) => {
    const updatePayload = {
      eventCategory: {
        [eventCategory.field]: eventCategory.value
      }
    }
    RequestService.patch(`${config.backendUrl}/event_categories/${eventCategory.id}`, updatePayload, (response) => {
      let updateeventCategories = this.eventCategories;
      updateeventCategories.splice(updateeventCategories.findIndex((category) => category.id === eventCategory.id), 1, response.data)
      this.writeToSession()
    })
  }

  deleteEventCategory = (id) => {
    RequestService.delete(`${config.backendUrl}/event_categories/${id}`, () => {
      let updateEventCategories = this.eventCategories;
      updateEventCategories.splice(updateEventCategories.findIndex((eventCategory) => eventCategory.id === id), 1)
      this.eventCategories = updateEventCategories
      this.writeToSession()
    })
  }

  writeToSession = () => {
    const json = JSON.stringify(this);
    sessionStorage.setItem('EventCategoryStore', json);
  }

  buildFromSession = () => {
    const json = JSON.parse(sessionStorage.getItem('EventCategoryStore'))
    if (json) {
      this.eventCategories = json.eventCategories
      this.eventCategoriesPreLoaded = json.eventCategoriesPreLoaded
    }
  }
}

decorate(EventCategoryStore, {
  eventCategories: observable,
  eventCategoriesPreLoaded: observable,
  geteventCategories: action,
});

export default EventCategoryStore;