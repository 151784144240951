import {
  Autocomplete, Box, Button, LinearProgress, TextField, Typography,
} from '@mui/material';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import WushCard from '../../components/WushCard';

const Organization = ({
  organizationStore, customerStore, alertStore, domainStore,
}) => {
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [changeOrganization, setChangeOrganization] = useState(false);
  organizationStore.getOrganizations();
  if (!organizationStore.organizationsPreLoaded) return <LinearProgress />;

  const handleJoin = () => {
    organizationStore.joinOrganization(selectedOrganization.id, () => {
      setChangeOrganization(false);
      customerStore.joinOrganization(selectedOrganization);
      alertStore.setAlertMessage(`Successfully joined ${selectedOrganization.name}`, 'success');
    });
  };

  return (
    <Box pt={2}>
      <Box mb={2}>
        <Typography variant="h4">Organization</Typography>
      </Box>
      {customerStore.customer.organization
        && (
        <Box>
          <Box mb={2}>
            <WushCard>
              <Typography variant="h5">Your Organization</Typography>
              {customerStore.customer.organization.name}
            </WushCard>
          </Box>
          {!changeOrganization && !domainStore.organization && (
          <Box>
            <Button variant="outlined" onClick={() => setChangeOrganization(true)}>Change Organization</Button>
          </Box>
          )}
        </Box>
        )}
      {(!customerStore.customer.organization || changeOrganization)
      && (
      <Box>
        <Box mb={2}>
          <Autocomplete
            getOptionLabel={(option) => option.name || 'N/A'}
            options={organizationStore.organizations}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search organization"
                InputProps={{
                  ...params.InputProps,
                  type: 'search',
                }}
              />
            )}
            onChange={(e, value) => setSelectedOrganization(value)}
          />
        </Box>
        <Box>
          <Button onClick={handleJoin} variant="contained" disabled={!selectedOrganization}>
            Join
          </Button>
          <Button onClick={() => setChangeOrganization(false)}>
            Cancel
          </Button>
        </Box>
      </Box>
      )}

    </Box>
  );
};

export default observer(Organization);
