import {
  Box, Button, Divider, Stack, Typography,
} from '@mui/material';
import React from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router';
import PersonalInformationForm from '../account/components/PersonalInformationForm';
import AdditionalInformationForm from '../account/components/AdditionalInformationForm';
import OrganizationPersonalInformationForm from '../account/components/OrganizationPersonalInformationForm';

const Profile = ({ customerStore, alertStore }) => {
  const history = useHistory();

  const formik = useFormik({
    initialValues: customerStore.customer,
    onSubmit: (values) => customerStore.updateCustomer(values, () => {
      alertStore.setAlertMessage('Success!', 'success');
    }),
  });
  return (
    <Box pt={2} mb={25} textAlign="left">
      <Box mb={2}>
        <Typography variant="h4">Profile</Typography>
      </Box>
      <Box mb={2}>
        <PersonalInformationForm formik={formik} />
        <Box mb={2}>
          <Divider />
        </Box>
        <OrganizationPersonalInformationForm formik={formik} />
        <Box mb={2}>
          <Divider />
        </Box>
        <AdditionalInformationForm formik={formik} />
      </Box>
      <Box textAlign="center">
        <Stack direction="vertical">
          <Button variant="contained" onClick={formik.handleSubmit}>Save</Button>
          <Button onClick={() => history.push('/account')}>Cancel</Button>
        </Stack>
      </Box>
    </Box>
  );
};

export default Profile;
