import { observable, decorate } from 'mobx';
import config from '../config';
import RequestService from '../services/RequestService';
import BaseStore from './BaseStore';

class BagStore extends BaseStore {
  bags = null;
  bagsPreLoaded = false;

  getBags = () => {
    !this.bagsPreLoaded && RequestService.get(`${config.backendUrl}/bags`, (response) => {
      response.data.sort((a,b) => { return a.id - b.id })
      this.bags = response.data
      this.bagsPreLoaded = true
      this.writeToSession()
    })
  }

  addBag= () => {
    this.addRecord('bags', {bag: {maxWeight: 0}}, this.bags)
  }

  updateBag = (bag) => {
    const updatePayload = {
      bag: {
        [bag.field]: bag.value
      }
    }
    RequestService.post(`${config.backendUrl}/bags/${bag.id}`, updatePayload, (response) => {
      let updatedBags = this.bags;
      updatedBags.splice(updatedBags.findIndex((b) => b.id === bag.id), 1, response.data)
      this.writeToSession()
    })
  }

  deleteBag = (id) => {
    RequestService.delete(`${config.backendUrl}/bags/${id}`, () => {
      let updatedBags = this.bags
      updatedBags.splice(updatedBags.findIndex((bag) => bag.id === id), 1)
      this.bags = updatedBags
      this.writeToSession()
    })
  }

  writeToSession = () => {
    const json = JSON.stringify(this);
    sessionStorage.setItem('BagStore', json);
  }
  
  buildFromSession = () => {
    const json = JSON.parse(sessionStorage.getItem('BagStore'))
    if (json) {
      this.bags = json.bags
    }
  }
}

decorate(BagStore, {
  bags: observable,
  bagsPreLoaded: observable
});

export default BagStore;
