import React from 'react';
import Archives from './containers/Archives';
import Scanner from './Scanner';
import ScannerBase from './ScannerBase';

export const PAGES = {
  SCANNER: {
    title: "Scanner",
    baseRoute: "",
    component: <ScannerBase />,
  },
  SERVICE_TICKETS: {
    title: "Service Tickets",
    baseRoute: "service-tickets",
    component: <Scanner serviceTickets />
  },
  ARCHIVES: {
    title: "Archives",
    baseRoute: "archives",
    component: <Archives />
  }
};