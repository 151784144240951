import { observable, decorate } from 'mobx';
import config from '../config';
import RequestService from '../services/RequestService';

class LeaderBoardStore {
  leaderBoard = [];
  leaderBoardPreLoaded = false;

  getLeaderBoard = (timespan, forceUpdate) => {
    (!this.leaderBoardPreLoaded || forceUpdate) && RequestService.get(`${config.backendUrl}/scanner/leader-board?timespan=${timespan}`, (response) => {
      this.leaderBoard = response.data
      this.leaderBoardPreLoaded = true
    })
  }

}

decorate(LeaderBoardStore, {
  leaderBoard: observable,
  leaderBoardPreLoaded: observable
});

export default LeaderBoardStore