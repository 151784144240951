import React from 'react';
import { Redirect, Switch } from 'react-router';
import UnauthenticatedRoute from './components/routes/UnauthenticatedRoute';
import EventCategory from './containers/iframe/containers/eventCategories/EventCategory';

const IframeRoutes = ({ childProps }) => (
  <Switch>
    <UnauthenticatedRoute path="/iframe/public-event-categories/:eventCategoryId" exact component={EventCategory} props={childProps} />

    <Redirect to="/home" />
  </Switch>
);

export default IframeRoutes;
