import { Box, Typography } from '@mui/material';
import React from 'react';

const IframeContainer = ({ children }) => (
  <Box textAlign="center">
    {children}
    <Box textAlign="left">
      <Typography color="lightgrey" variant="caption">
        Powered by
        <i>WUSHLife</i>
      </Typography>
    </Box>
  </Box>
);

export default IframeContainer;
