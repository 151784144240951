import React from 'react';
import { TextField, LinearProgress, Autocomplete } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  categoriesCombo: {
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: '8px',
  },
}));

const ItemCategoryAutocomplete = ({ itemCategoryStore, params, onUpdate }) => {
  const classes = useStyles();
  const categories = itemCategoryStore.itemCategories;
  if (!itemCategoryStore.itemCategoriesPreLoaded) return <LinearProgress />;
  return (
    <Autocomplete
      options={categories}
      getOptionLabel={(option) => (
        option.name
          || itemCategoryStore.itemCategories.filter((cat) => cat.id === option)[0].name
      )}
      getOptionSelected={(option, value) => option.name === value}
      className={classes.categoriesCombo}
      style={{ width: 300 }}
      /* eslint-disable-next-line */
      value={params.value}
      /* eslint-disable no-shadow */
      renderInput={(params) => <TextField {...params} label="Item Category" />}
      onChange={(_event, value) => {
        const updatedParams = { ...params };
        updatedParams.value = value?.id;
        onUpdate(updatedParams);
      }}
    />
  );
};

ItemCategoryAutocomplete.propTypes = {
  itemCategoryStore: PropTypes.any,
  params: PropTypes.object,
  onUpdate: PropTypes.func,
};

export default ItemCategoryAutocomplete;
