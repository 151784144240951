import {
  Box, Button, CircularProgress,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import RequestService from '../../../../services/RequestService';
import config from '../../../../config';

import CreditCardPayment from './CreditCardPayment';

const OrderSummary = ({
  event, formik, handleSuccess, previousStep, alertStore,
}) => {
  const [clientSecret, setClientSecret] = useState(null);
  const [publicKey, setPublicKey] = useState(null);
  const [customerId, setCustomerId] = useState(null);

  const setupCustomerOrder = () => {
    setClientSecret(null);
    RequestService.post(`${config.backendUrl}/public/anonymous-events/${event.id}/customer`, {
      customer: {
        firstName: formik.values.firstName,
        lastName: formik.values.lastName,
        email: formik.values.email,
        password: formik.values.password,
        phone: formik.values.phoneNumber,
        quantity: formik.values.quantity,
        dateOfBirth: formik.values.dateOfBirth,
        items: formik.values.cart.map((item) => ({
          id: item.itemId,
          quantity: item.quantity,
          amount: item.amount,
        })),
      },
      participants: formik.values.participants,
      customerIsParticipating: formik.values.customerIsParticipating,
    }, (response) => {
      setClientSecret(response.data.clientSecret);
      setPublicKey(response.data.publicKey);
      setCustomerId(response.data.customerId);
    }, () => {
      alertStore.setAlertMessage('An error occurred processing order', 'warning');
      previousStep();
    });
  };

  useEffect(() => {
    setupCustomerOrder();
  }, [formik.values.cart]);

  if (!clientSecret || !publicKey) return <CircularProgress />;

  return (
    <Box>
      <Box mb={2}>
        <CreditCardPayment
          clientSecret={clientSecret}
          publicKey={publicKey}
          handleSuccess={(paymentIntent) => handleSuccess(
            customerId, formik.values, paymentIntent,
          )}
          buttonText={formik.values.quantity > 0 ? 'Register' : 'Place Order'}
          alertStore={alertStore}
        />
      </Box>
      <Button fullWidth onClick={previousStep}>Back</Button>
    </Box>
  );
};

export default OrderSummary;
