import React from 'react';
import {
  Box, Chip, Grid, Stack, Typography,
} from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';
import { format } from 'date-fns';
import WushButtonCard from '../../../components/WushButtonCard';

const EventButtonCard = ({ onClick, event }) => (
  <WushButtonCard onClick={() => onClick && onClick(event)} minHeight="175px">
    <Grid container>
      <Grid item md={8}>
        <Typography variant="body1"><strong>{event?.name}</strong></Typography>
        {/* eslint-disable-next-line */}
        <div dangerouslySetInnerHTML={{ __html: event?.description }} />
      </Grid>
      <Grid item md={4}>
        <Box textAlign="right">
          {event.userEnrolled && <Chip label="Enrolled" color="primary" size="small" />}
        </Box>
        <Box textAlign="right">
          {event.isEventSeries && <Chip label="Series" color="secondary" size="small" />}
        </Box>
        <Box textAlign="right">
          {event.userWaitlisted && <Chip label="Waitlisted" color="info" size="small" />}
        </Box>
      </Grid>
    </Grid>
    {!event.isEventSeries && <Typography variant="body1">{format(new Date(event.startDatetime), 'EEEE LLLL dd, yyyy')}</Typography>}
    <Typography variant="body1">
      {event.isEventSeries ? format(new Date(event.startDatetime), 'MM/dd') : format(new Date(event.startDatetime), 'H:mm b')}
      {' '}
      -
      {' '}
      {event.isEventSeries ? format(new Date(event.endDatetime), 'MM/dd') : format(new Date(event.endDatetime), 'H:mm b')}
    </Typography>
    <Box>
      <Stack direction="horizontal">
        <GroupIcon />
        {event.registrationsCount}
        {' '}
        /
        {event.capacity}
      </Stack>
    </Box>
  </WushButtonCard>
);

export default EventButtonCard;
