import React from 'react';
import { PAGES as InventoryPages } from "./containers/inventory/constants";
import { PAGES as AdminPages } from "./containers/admin/constants";
import { PAGES as ReservationPages } from "./containers/reservations/constants";
import { PAGES as PropertyPages } from "./containers/properties/constants";
import { PAGES as ScannerPages } from "./containers/scanner/constants";
import { PAGES as EventPages } from './containers/events/constants';
import LibraryBooks from "@mui/icons-material/LibraryBooks";
import InventoryIcon from '@mui/icons-material/Inventory';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import HomeIcon from '@mui/icons-material/Home';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';

export const APPS = {
  MISSION_CONTROL: {
    title: "Home",
    baseRoute: "home",
    color: "#0c6b74",
    pages: {
      NEW_RESERVATION: {
        title: "New Reservation",
        directLink: "reservation-management/new-reservation",
        baseRoute: "reservation-management"
      }
    },
    icon: <HomeIcon />
  },
  INVENTORY: {
    title: "Inventory",
    baseRoute: "inventory-management",
    color: "#0c6b74",
    pages: InventoryPages,
    icon: <InventoryIcon />
  },
  RESERVATIONS: {
    title: "Reservations",
    baseRoute: "reservation-management",
    color: "#0c6b74",
    pages: ReservationPages,
    icon: <BookmarkAddIcon />
  },
  EVENTS: {
    title: "Events",
    baseRoute: "event-management",
    color: "#0c6b74",
    pages: EventPages,
    icon: <CalendarMonthIcon />
  },
  REPORTS: {
    title: "Reports",
    baseRoute: "reports",
    color: "#ee855b",
    icon: <LibraryBooks />
  },
  PROPERTIES: {
    title: "Properties",
    baseRoute: "property-management",
    color: "#0c6b74",
    pages: PropertyPages,
    icon: <MapsHomeWorkIcon />
  },
  SCANNER: {
    title: "Scanner",
    baseRoute: "scanner",
    color: "#0c6b74",
    pages: ScannerPages,
    icon: <QrCodeScannerIcon />
  },
  ADMIN: {
    title: "Admin",
    baseRoute: "admin",
    color: "#f9c538",
    pages: AdminPages,
    icon: <AdminPanelSettingsIcon />
  }
}

export const ACCOUNT_TYPES = {
  ADMIN: "admin",
  SHARED: "shared"
}