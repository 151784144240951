import {
  Box,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableCell,
  TableRow,
  TableHead,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FieldArray } from 'formik';
import React from 'react';

const PublicCart = ({ event, quantity, formik }) => {
  const calculateTotalCost = () => {
    const registrationCost = quantity * event.costDollars;
    const itemTotalCost = formik.values.cart.reduce((previousValue, cartItem) => {
      if (cartItem.parent.retailSalesPrice > 0) {
        const cartItemQuantity = cartItem.quantity;
        return previousValue + cartItem.parent.retailSalesPrice * cartItemQuantity;
      }

      return previousValue + parseInt(cartItem.amount, 10);
    }, 0);
    return registrationCost + itemTotalCost;
  };

  const renderCartItems = (cart, arrayHelpers) => (
    cart.map((item, index) => {
      const itemQuantity = item.quantity;
      let chargeToDisplay = 0;
      chargeToDisplay = itemQuantity * item.parent.retailSalesPrice;

      return (
        <TableRow>
          <TableCell>
            <Typography variant="body1">
              {item.item.name}
              {' '}
              {item.parent.name}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body1" fontWeight="bold">
              $
              {item.parent.retailSalesPrice > 0
                ? chargeToDisplay
                : item.amount}
            </Typography>
          </TableCell>
          <TableCell>
            {item.quantity ? (
              <FormControl size="small">
                <Select
                  value={cart[index].quantity}
                  onChange={(e) => {
                    if (e.target.value === 'Remove') {
                      arrayHelpers.remove(index);
                    } else {
                      formik.setFieldValue(`cart.${index}.quantity`, e.target.value);
                    }
                  }}
                  label="Quantity"
                >
                  {['Remove', 1, 2, 3, 4, 5, 6].map((itemQuantitySelect) => (
                    <MenuItem value={itemQuantitySelect}>{itemQuantitySelect}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <IconButton onClick={() => arrayHelpers.remove(index)}>
                <CloseIcon />
              </IconButton>
            )}
          </TableCell>
        </TableRow>
      );
    })
  );

  if (quantity === 0 && formik.values.cart.length === 0) return <Box />;

  // console.log(event);
  // console.log(quantity);

  return (
    <Box mt={5} pt={7} className="public-cart">
      <Typography variant="body1">
        <strong>Order Summary</strong>
      </Typography>
      <Table size="small">
        {quantity > 0 && (
          <>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography> Number of Participants</Typography>
                </TableCell>
                <TableCell>
                  <Typography> </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableRow>
              <TableCell>
                <Box textAlign="left">
                  <Typography variant="body1">Participants</Typography>
                </Box>
              </TableCell>
              <TableCell align="right">
                <Box>{quantity}</Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Box textAlign="left">
                  <Typography variant="body1">Cost</Typography>
                </Box>
              </TableCell>
              <TableCell align="right">
                <Box>
                  @ $
                  {event.costDollars}
                </Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Box textAlign="left">
                  <Typography variant="body1">Total</Typography>
                </Box>
              </TableCell>
              <TableCell align="right">
                <Box>
                  $
                  {event.costDollars}
                </Box>
              </TableCell>
            </TableRow>
          </>
        )}
        <FieldArray
          name="cart"
          render={(arrayHelpers) => <>{renderCartItems(formik.values.cart, arrayHelpers)}</>}
        />
        <TableRow>
          <TableCell>
            <Box textAlign="left">
              <Typography variant="subtitle1">
                <strong>Total</strong>
              </Typography>
            </Box>
          </TableCell>
          <TableCell align="right">
            <Box>
              <Typography variant="subtitle1">
                $
                {calculateTotalCost()}
              </Typography>
            </Box>
          </TableCell>
        </TableRow>
      </Table>
    </Box>
  );
};

export default PublicCart;
