import { observable, decorate } from 'mobx';
import config from '../config';
import RequestService from '../services/RequestService';
import BaseStore from './BaseStore';

class OrganizationStore extends BaseStore {
  constructor() {
    super()
    this.buildFromSession();
  }

  organizations = null;
  organizationsPreLoaded = false;

  getOrganizations = () => {
    !this.organizationsPreLoaded && RequestService.get(`${config.backendUrl}/public/organizations`, (response) => {
      this.organizations = response.data
      this.organizationsPreLoaded = true
      this.writeToSession()
    })
  }

  joinOrganization = (organizationId, callback) => {
    RequestService.post(`${config.backendUrl}/public/organizations/${organizationId}/join`, {}, (response) => {
      console.log(response.data)
      callback && callback()
    })
  }

  writeToSession = () => {
    const json = JSON.stringify(this);
    sessionStorage.setItem('OrganizationStore', json);
  }
  
  buildFromSession = () => {
    const json = JSON.parse(sessionStorage.getItem('OrganizationStore'))
    if (json) {
      this.organizations = json.organizations
      this.organizationsPreLoaded = json.organizationsPreLoaded
    }
  }
}

decorate(OrganizationStore, {
  organizations: observable,
  organizationsPreLoaded: observable
});

export default OrganizationStore;