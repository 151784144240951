import {
  Box, Button, Grid, Tooltip,
} from '@mui/material';
import React from 'react';

const NextBackButton = ({
  nextStep, previousStep, nextStepDisabled, nextStepText,
}) => {
  const renderNextButton = () => {
    if (nextStepText) {
      return (
        <Tooltip title={nextStepText}>
          <span>
            <Button variant="contained" onClick={nextStep} disabled={nextStepDisabled}>Next</Button>
          </span>
        </Tooltip>
      );
    }
    return (
      <Button variant="contained" onClick={nextStep} disabled={nextStepDisabled}>Next</Button>
    );
  };
  return (
    <Box width="100%" textAlign="center">
      <Grid container width="100%" textAlign="center">
        <Grid item md={6} style={{ position: 'absolute', left: 10 }}>
          <Box textAlign="left">
            {previousStep && <Button onClick={previousStep}>Previous</Button>}
          </Box>
        </Grid>
        <Grid item md={6} style={{ position: 'absolute', right: 10 }}>
          <Box textAlign="right">
            {nextStep
              && renderNextButton()}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NextBackButton;
