import {
  Box, Button, CircularProgress, List, ListItem, Typography,
} from '@mui/material';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router';
import config from '../../config';
import RequestService from '../../services/RequestService';

const Referrals = () => {
  const [referralCode, setReferralCode] = useState(null);
  const [referrals, setReferrals] = useState(null);

  const history = useHistory();

  const getReferralCode = () => {
    RequestService.get(`${config.backendUrl}/public/promo_codes/me`, (response) => {
      setReferralCode(response.data.referralCode);
    });
  };

  const getReferrals = () => {
    RequestService.get(`${config.backendUrl}/public/referrals`, (response) => {
      setReferrals(response.data.referrals);
    });
  };

  useEffect(() => {
    getReferralCode();
    getReferrals();
  }, []);

  return (
    <Box pt={2} mb={25} textAlign="left">
      <Box mb={2}>
        <Typography variant="h4">Referrals</Typography>
        <Box>
          <Typography variant="h6">
            Your referral code:
            {' '}
            <strong>{referralCode || <CircularProgress size={15} />}</strong>
          </Typography>
          <Typography variant="body1"><em>Give this code to your friends to use and you will receive discounts on future purchases!</em></Typography>
        </Box>
        <Box my={2}>
          <List>
            {
              referrals && referrals.map((referral) => (
                <ListItem>
                  Referred
                  {' '}
                  {referral.referred.firstName}
                  {' '}
                  {referral.referred.lastName}
                  {' '}
                  on
                  {' '}
                  {format(new Date(referral.createdAt), 'dd/MM/yyyy')}
                  {' '}
                  for $
                  {parseInt(referral.discountAmount, 10)}
                </ListItem>
              ))
            }
          </List>
        </Box>
        <Box mt={5}>
          <Button onClick={() => history.push('/account')}>
            Back
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Referrals;
