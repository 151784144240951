import {
  Backdrop,
  Box, CircularProgress, Grid, LinearProgress, TextField, Typography,
} from '@mui/material';
import React, { useState } from 'react';
import Fuse from 'fuse.js';
import { useHistory } from 'react-router';
import RequestService from '../../services/RequestService';
import config from '../../config';
import EventInfo from './components/EventInfo';
import WushModal from '../../components/WushModal';
import EventButtonCard from './components/EventButtonCard';

const Events = ({ alertStore, customerStore }) => {
  const [events, setEvents] = useState(null);
  const [eventSeries, setEventSeries] = useState(null);
  const [eventSearchParam, setEventSearchParam] = useState('');
  const [showViewModal, setShowViewModal] = useState(false);
  const [viewingEvent, setViewingEvent] = useState(null);
  const [paymentProcessing, setPaymentProcessing] = useState(false);

  const history = useHistory();

  const getEventSeries = (callback) => {
    RequestService.get(`${config.backendUrl}/public/event_series`, (response) => {
      setEventSeries(response.data);
      if (callback) callback();
    });
  };

  const getEvents = (callback) => {
    RequestService.get(`${config.backendUrl}/public/events`, (response) => {
      setEvents(response.data);
      if (callback) callback();
    });
  };
  if (!events) getEvents();
  if (!eventSeries) getEventSeries();
  if (!events || !eventSeries) return <LinearProgress />;
  if (events.length === 0 && eventSeries.length === 0) {
    return (
      <Box pt={2}>
        <Typography variant="body1">This organization does not have any upcoming events</Typography>
      </Box>
    );
  }

  const fuseOptions = { keys: ['name', 'description'] };

  const fuse = new Fuse(events.concat(eventSeries), fuseOptions);

  const results = fuse.search(eventSearchParam).map((item) => item.item);

  const handleEventClick = (event) => {
    if (event.isEventSeries) {
      history.push(`/event-series/${event.id}`);
    } else {
      setViewingEvent(event);
      setShowViewModal(true);
    }
  };

  const combinedEvents = eventSeries.concat(events);

  const renderEvents = () => (
    combinedEvents.length > 0 && (results.length > 0
      ? results
      : combinedEvents
        .sort((a, b) => (a && b && b.startDatetime - a.startDatetime)))
      .map((event) => (
        <Grid item md={4} width="100%">
          <Box pr={2} pb={2}>
            <EventButtonCard onClick={handleEventClick} event={event} />
          </Box>
        </Grid>
      ))
  );

  const handleEnrollment = (paymentMethodId) => {
    if (!viewingEvent.userEnrolled) {
      setShowViewModal(false);
      setPaymentProcessing(true);
      RequestService.post(`${config.backendUrl}/public/event_registrations`, { event_registration: { eventId: viewingEvent.id, paymentMethodId } }, () => {
        getEvents(() => setPaymentProcessing(false));
        alertStore.setAlertMessage('Successfully registered for event!', 'success');
      });
    }
  };

  const handleUnenrollment = () => {
    if (viewingEvent.userEnrolled) {
      RequestService.delete(`${config.backendUrl}/public/event_registrations/${viewingEvent.registrationId}`, {}, () => {
        getEvents(() => setShowViewModal(false));
        alertStore.setAlertMessage('Successfully unregistered for event!', 'success');
      });
    }
  };

  const handleJoinWaitList = () => {
    RequestService.post(`${config.backendUrl}/public/event_wait_lists`, {
      event_wait_list: {
        customerId: customerStore.customer.id,
        eventId: viewingEvent.id,
      },
    }, () => {
      getEvents(() => setShowViewModal(false));
      alertStore.setAlertMessage('Successfully joined waitlist!', 'success');
    });
  };

  return (
    <Box pt={2}>
      <Typography variant="h4">Events</Typography>
      <Box pr={5} mb={2}>
        <TextField
          fullWidth
          label="Search"
          onChange={(e) => setEventSearchParam(e.target.value)}
        />
      </Box>
      <Grid container>
        {renderEvents()}
      </Grid>
      <WushModal
        open={showViewModal}
        onClose={() => setShowViewModal(false)}
      >
        {viewingEvent
          && (
          <EventInfo
            event={viewingEvent}
            handleEnrollment={handleEnrollment}
            handleUnenrollment={handleUnenrollment}
            handleJoinWaitList={handleJoinWaitList}
          />
          )}
      </WushModal>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={paymentProcessing}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default Events;
