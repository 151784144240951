import {
  Box, Button, Divider, FormControl, Grid, InputAdornment, InputLabel,
  Link,
  MenuItem, Select, TextField, Typography,
} from '@mui/material';
import { FieldArray } from 'formik';
import React, { useState } from 'react';
import WushCard from '../../../../components/WushCard';
import NextBackButton from './NextBackButton';

const ItemSelection = ({
  event, nextStep, previousStep, skipRegistration, alertStore,
}) => {
  const [itemToAdd, setItemToAdd] = useState(null);
  const [customItemEntry, setCustomItemEntry] = useState(null);

  return (
    <Box mb={5}>
      <Grid container>
        <Grid item md={12} width="100%" textAlign="center">
          <Box>
            <Typography variant="body1">
              If you would like to purchase any additional items,
              {' '}
              please select them below. Otherwise,
              {' '}
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link href="#" onClick={nextStep}>proceed to checkout</Link>
            </Typography>
          </Box>
          <Box>
            <FieldArray
              name="cart"
              render={
                (arrayHelpers) => (
                  <Box>
                    { event.items.length > 0 && event.items.map((item) => (
                      <Typography variant="body1">
                        {item.parent.retailSalesPrice > 0
                          ? (
                            <Box mb={2}>
                              <WushCard>
                                <Box mb={2}>
                                  <Typography variant="h6" textAlign="center">
                                    {item.parent.name}
                                  </Typography>
                                </Box>
                                <Box textAlign="center">
                                  {item.parent.imageUrl && <img style={{ borderRadius: '10px' }} width={250} src={item.parent.imageUrl} alt="Item" />}
                                  <Typography variant="body1">
                                    {item.parent.description}
                                  </Typography>
                                </Box>
                                <Box textAlign="center">
                                  <FormControl sx={{ m: 1, minWidth: 200 }} size="sm">
                                    <InputLabel id="demo-select-small">Select variation</InputLabel>
                                    <Select
                                      value={itemToAdd}
                                      onChange={(e) => {
                                        setItemToAdd(e.target.value);
                                      }}
                                    >
                                      {
                                        item.variations.map((itemVariation) => (
                                          <MenuItem
                                            value={itemVariation.id}
                                          >
                                            {itemVariation.name}
                                          </MenuItem>
                                        ))
                                      }
                                      <Divider />
                                      <MenuItem
                                        value={-1}
                                      >
                                        I don`&apos;`t want one
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Box>
                                <Box textAlign="center">
                                  <Button
                                    onClick={() => {
                                      arrayHelpers.push({
                                        itemId: itemToAdd,
                                        item: item.variations.filter(
                                          (itemVariation) => itemVariation.id === itemToAdd,
                                        )[0],
                                        parent: item.parent,
                                        quantity: 1,
                                      });
                                      alertStore.setAlertMessage(
                                        'Added to cart!',
                                      );
                                    }}
                                    size="small"
                                    variant="contained"
                                    disabled={!itemToAdd || itemToAdd === -1}
                                  >
                                    Add to Cart
                                  </Button>
                                </Box>
                              </WushCard>
                            </Box>
                          )
                          : (
                            <Box mb={2}>
                              <WushCard>
                                <Box mb={2}>
                                  <Typography variant="h6" textAlign="center">
                                    {item.parent.name}
                                  </Typography>
                                </Box>
                                <Box mb={2}>
                                  <Typography variant="body1" textAlign="center">
                                    {item.parent.description}
                                  </Typography>
                                </Box>
                                <Box textAlign="center">
                                  <Box mb={2}>
                                    <TextField
                                      label="Enter custom amount"
                                      value={customItemEntry}
                                      type="number"
                                      onChange={(e) => setCustomItemEntry(e.target.value)}
                                      InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                      }}
                                    />
                                  </Box>
                                  <Box textAlign="center" mb={2}>
                                    <Button
                                      variant="contained"
                                      onClick={() => {
                                        arrayHelpers.push({
                                          itemId: item.parent.id,
                                          item,
                                          parent: item.parent,
                                          amount: customItemEntry,
                                        });
                                        alertStore.setAlertMessage(
                                          'Added to cart!',
                                        );
                                      }}
                                      size="small"
                                      disabled={customItemEntry <= 0}
                                    >
                                      Add to cart
                                    </Button>
                                  </Box>
                                </Box>
                              </WushCard>
                            </Box>
                          )}
                      </Typography>
                    ))}
                  </Box>
                )
              }
            />
          </Box>
        </Grid>
      </Grid>
      <NextBackButton
        nextStep={nextStep}
        previousStep={!skipRegistration && previousStep}
      />
    </Box>
  );
};

export default ItemSelection;
