import { Box, Button, CircularProgress } from '@mui/material';
import React, { useRef, useEffect, useState } from 'react';

import { useHistory, useRouteMatch } from 'react-router';
import { Form, Formik } from 'formik';
import SignHere from '../../components/SignHere';
import fetchCustomerWaiverByUuid from '../../services/waivers/fetchCustomerWaiverByUuid';
import signWaiver from '../../services/waivers/signWaiver';
import MuiFormikTextField from '../../components/formik/MuiFormikTextField';

const ViewWaiver = ({ alertStore }) => {
  const match = useRouteMatch();
  const { waiverUuid } = match.params;

  const history = useHistory();

  const [signatureFile, setSignatureFile] = useState(null);

  const sigRef = useRef(null);

  const [customerWaiver, setCustomerWaiver] = useState(null);

  useEffect(() => {
    fetchCustomerWaiverByUuid(waiverUuid, (response) => {
      setCustomerWaiver(response.data);
    });
  }, []);

  const convertDataUriToFile = (dataUri) => {
    let byteString;
    if (dataUri.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataUri.split(',')[1]);
    else byteString = unescape(dataUri.split(',')[1]);

    // separate out the mime component
    const mimeString = dataUri.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    // eslint-disable-next-line
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    setSignatureFile(new Blob([ia], { type: mimeString }));
  };

  const handleSubmit = (values) => {
    signWaiver(customerWaiver.id, values.name, values.email, signatureFile, () => {
      alertStore.setAlertMessage('Thank you for signing the waiver!', 'success');
      history.push('/');
    });
  };

  if (!customerWaiver) return <CircularProgress />;

  return (
    <Box pt={2}>
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: customerWaiver.waiver.content }} />
      <Box>
        <Formik
          onSubmit={handleSubmit}
          initialValues={{
            name: '',
            email: '',
          }}
        >
          {
            (formik) => (
              <Form>
                <Box mb={2}>
                  <MuiFormikTextField
                    name="name"
                    formik={formik}
                    value={formik.values.name}
                    label="Name"
                    fullWidth
                  />
                </Box>
                <Box mb={2}>
                  <MuiFormikTextField
                    name="email"
                    formik={formik}
                    value={formik.values.email}
                    label="Email"
                    fullWidth
                  />
                </Box>
                <SignHere
                  onEnd={() => convertDataUriToFile(sigRef.current.toDataURL())}
                  sigRef={sigRef}
                />
                <Button variant="contained" onClick={formik.handleSubmit}>
                  Submit
                </Button>
              </Form>
            )
          }
        </Formik>
      </Box>
    </Box>
  );
};

export default ViewWaiver;
