import {
  Box, Grid, IconButton, Typography,
} from '@mui/material';
import { Field } from 'formik';
import { observer } from 'mobx-react';
import React from 'react';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import HailIcon from '@mui/icons-material/Hail';
import EditIcon from '@mui/icons-material/Edit';
import { differenceInDays } from 'date-fns';

const calculateReservationDays = (item) => {
  const difference = item.dateRange
    ? differenceInDays(new Date(item.dateRange.endDate), new Date(item.dateRange.startDate))
    : 0;
  return difference;
};

const calculateItemCost = (item) => {
  const days = calculateReservationDays(item);
  let cost = days * parseInt(item.fullDayRate, 10);
  if (cost > parseInt(item.weekRate, 10)) {
    const weeks = Math.ceil(days / 7);
    const extraDays = days > 7 ? days % 7 : 0;
    const extraDaysCost = extraDays * parseInt(item.fullDayRate, 10);
    if (extraDaysCost < parseInt(item.weekRate, 10) && weeks > 1) {
      const lowWeeks = Math.floor(days / 7);
      cost = lowWeeks * parseInt(item.weekRate, 10);
      cost += extraDaysCost;
    } else {
      cost = weeks * parseInt(item.weekRate, 10);
    }
  }
  return cost * parseInt(item.selectedQuantity, 10) || 0;
};

const CartItem = ({ item, index, handleItemSelect }) => (
  <Box py="0.5rem">
    <Grid container>
      <Grid item md={4}>
        <Box>
          {item.name}
        </Box>
        <Box>
          <Typography fontSize="12px" variant="i">
            <i>
              {item?.dateRange?.startDate && `${item.dateRange.startDate} - ${item.dateRange.endDate}`}
            </i>
          </Typography>
        </Box>
      </Grid>
      <Grid item md={2}>
        <Field
          type="number"
          style={{ width: '50%' }}
          name={`selectedItems.${index}.selectedQuantity`}
        />
      </Grid>
      <Grid item md={2}>
        <Typography variant="span">
          $
          {item.selectedQuantity
            ? calculateItemCost(item)
            : 0}
        </Typography>
      </Grid>
      <Grid item md={2}>
        <IconButton aria-label="delete" onClick={() => handleItemSelect(item)} size="small">
          {item.receivingMethod === 'customer' ? <HailIcon /> : <LocalShippingIcon />}
        </IconButton>
      </Grid>
      <Grid item md={2}>
        <IconButton aria-label="delete" onClick={() => handleItemSelect(item)} size="small">
          <EditIcon />
        </IconButton>
      </Grid>
    </Grid>
  </Box>
);

export default observer(CartItem);
export { calculateItemCost, calculateReservationDays };
