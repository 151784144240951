import React from 'react';
import { observer } from 'mobx-react';
import {
  Box, Typography, Grid, CircularProgress, Button,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import WushCard from '../../components/WushCard';
import RequestService from '../../services/RequestService';
import config from '../../config';
import EventButtonCard from '../events/components/EventButtonCard';

const Home = ({ authStore, customerStore }) => {
  const [events, setEvents] = React.useState(null);

  const history = useHistory();

  const fetchEvents = () => {
    RequestService.get(`${config.backendUrl}/public/events/me`, (response) => {
      setEvents(response.data);
    });
  };

  const renderUpcomingEvents = () => {
    if (!events) return <CircularProgress />;
    return events.slice(0, 3).map((event) => (
      <Box mb={2}>
        <EventButtonCard event={event} />
      </Box>
    ));
  };

  const renderAnnouncements = () => <Typography variant="body1">Currently there are no announcements. Please check back later!</Typography>;

  if (!events) fetchEvents();

  if (authStore.needsNewPassword) history.push('/change-password');

  return (
    <Box pt={2}>
      <Box mb={2}>
        <Typography variant="h3">
          Welcome
          {' '}
          {customerStore.customer?.firstName}
          !
        </Typography>
      </Box>
      <Box mb={25}>
        <Grid container>
          <Grid item md={6}>
            <Box mb={3} mx={2}>
              <WushCard>
                <Box mb={2}>
                  <Typography variant="h5">Upcoming Events</Typography>
                </Box>
                {renderUpcomingEvents()}
                <Box>
                  {events?.length === 0
                    ? (
                      <Button variant="contained" onClick={() => history.push('/events')}>
                        Find Events
                      </Button>
)
                    : (
                      <Button onClick={() => history.push('/my-events')}>
                        View All
                      </Button>
)}
                </Box>
              </WushCard>
            </Box>
          </Grid>
          <Grid item md={6}>
            <Box mx={2}>
              <WushCard>
                <Box mb={2}>
                  <Typography variant="h5">Announcements</Typography>
                </Box>
                {renderAnnouncements()}
              </WushCard>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default observer(Home);
