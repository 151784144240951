import {
  Box,
} from '@mui/material';
import { useFormik } from 'formik';
import React from 'react';
import AdditionalInformationForm from '../components/AdditionalInformationForm';
import NextBackButton from '../components/NextBackButton';

const AdditionalInformation = ({ nextStep, previousStep, customerStore }) => {
  const formik = useFormik({
    initialValues: customerStore.customer,
    onSubmit: customerStore.updateCustomer,
  });

  const handleSaveAndNextStep = () => {
    customerStore.updateCustomer(formik.values, () => {
      nextStep();
    });
  };

  return (
    <Box pt={2} textAlign="center">
      <AdditionalInformationForm formik={formik} />
      <NextBackButton previousStep={previousStep} nextStep={handleSaveAndNextStep} />
    </Box>
  );
};

export default AdditionalInformation;
