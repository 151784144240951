import { observable, decorate, computed } from 'mobx';
import config from '../config';
import RequestService from '../services/RequestService';
import BaseStore from './BaseStore';

class ScannerStore extends BaseStore {
  constructor() {
    super()
    this.buildFromSession();
  }
  reservations = []
  openReservations = []
  activeReservations = []
  completeReservations = []
  serviceTicketCount = 0
  reservationsPreLoaded = false
  selectedReservation = null
  selectedReservationPreLoaded = false
  selectedReservationRows = []
  archivedReservations = []
  archivedReservationsPreLoaded = false

  currentVoice = "Alex"

  getReservations = (forceUpdate = false, serviceTickets) => {
    if(forceUpdate) {
      this.reservationsPreLoaded = false
    }
    (!this.reservationsPreLoaded || forceUpdate) && RequestService.get(`${config.backendUrl}/scanner/${serviceTickets ? 'service-tickets' : 'reservations'}`, (response) => {
      this.reservationsPreLoaded = true
      this.reservations = response.data.open
      this.openReservations = response.data.open
      this.activeReservations = response.data.active
      this.completeReservations = response.data.complete
      this.serviceTicketCount = response.data.serviceTicketCount
    })
  }

  getArchives = (forceUpdate, startDate, endDate) => {
    (!this.archivedReservationsPreLoaded || forceUpdate) && RequestService.get(`${config.backendUrl}/scanner/archives?startDate=${startDate}&endDate=${endDate}`, (response) => {
      this.archivedReservations = response.data
      this.archivedReservationsPreLoaded = true
    })
  }

  getReservation = (reservationId, forceUpdate = false) => {
    if(this.reservationsPreLoaded && !forceUpdate) {
      this.selectedReservation = this.reservations.filter((reservation) => reservation.id === reservationId)[0]
      this.selectedReservationPreLoaded = true
    }
    else {
      (!this.selectedReservationPreLoaded || forceUpdate) && RequestService.get(`${config.backendUrl}/scanner/reservations/${reservationId}`, (response) => {
        this.selectedReservation = response.data
        this.selectedReservationPreLoaded = true
      })
    }
  }

  scanItem = (itemLegacyScanId, scannerTicketId, callback, itemCompleteCallback, scannerTicketCompleteCallback, reservationCompleteCallback, failureCallback) => {
    RequestService.post(`${config.backendUrl}/scanner/scan/${scannerTicketId}/${itemLegacyScanId}`, {}, () => {
      let updatedReservation = this.selectedReservation
      let scannerTicketIndex = updatedReservation.scannerTickets.findIndex((scannerTicket) => scannerTicket.id === parseInt(scannerTicketId))
      let scannerTicket = updatedReservation.scannerTickets[scannerTicketIndex]
      let scannerTicketItemIndex = scannerTicket.scannerTicketItems.findIndex((scannerTicketItem) => 
        scannerTicketItem.itemLegacyScanId === parseInt(itemLegacyScanId) || scannerTicketItem.id === parseInt(itemLegacyScanId)
      )
      scannerTicket.scannerTicketItems[scannerTicketItemIndex].scannedQuantity += 1
      let updatedScannerTicketItem = scannerTicket.scannerTicketItems[scannerTicketItemIndex]
      if(this.reservationComplete(updatedReservation)) return reservationCompleteCallback()
      if(updatedScannerTicketItem.scannedQuantity === updatedScannerTicketItem.quantity) itemCompleteCallback(updatedScannerTicketItem)
      if(this.scannerTicketComplete(scannerTicket)) scannerTicketCompleteCallback()
      updatedReservation.scannerTickets[scannerTicketIndex] = scannerTicket
      this.selectedReservation = updatedReservation
      callback && callback()
    }, (error) => {
      failureCallback && failureCallback()
      callback && callback()
    })
  }

  selectRow = (id, value) => {
    if(value) {
      let updatedSelection = this.selectedReservationRows
      updatedSelection.push(id)
      this.selectedReservationRows = updatedSelection
    } else {
      let updatedSelection = this.selectedReservationRows
      updatedSelection.remove(id)
      this.selectedReservationRows = updatedSelection
    }
  }

  reservationComplete = (reservation) => (
    reservation.scannerTickets.filter((ticket) => !this.scannerTicketComplete(ticket)).length === 0
  )
  
  scannerTicketComplete = (scannerTicket) => (
    scannerTicket.scannerTicketItems.filter((ticketItem) => ticketItem.scannedQuantity - ticketItem.quantity !== 0).length === 0
  )

  deleteReservation = (reservation) => {
    RequestService.delete(`${config.backendUrl}/scanner/reservations/${reservation.id}`, () => {
      this.getReservations(true)
    })
  }

  updateServiceTicket = (reservation, status) => {
    RequestService.post(`${config.backendUrl}/scanner/service-tickets/${reservation.id}`, { scannerTicket: { status: status } }, (response) => {
      console.log(response.data)
      this.getReservations(true, true)
    })
  }

  processReservation = (reservation) => {
    RequestService.post(`${config.backendUrl}/scanner/reservations/${reservation.id}/process`, {}, (response) => {
      console.log('complete')
      let updatedOpenReservations = this.openReservations
      updatedOpenReservations = updatedOpenReservations.filter((reservation) => reservation.id !== reservation.id)
      this.openReservations = updatedOpenReservations
      let updatedCompleteReservations = this.completeReservations
      updatedCompleteReservations.unshift(response.data)
      console.log(updatedCompleteReservations)
      this.completeReservations = updatedCompleteReservations
    })
  }

  generateTickets = (propertyId, startDate, endDate) => {
    RequestService.post(`${config.backendUrl}/properties/${propertyId}/generate-scanner-tickets`, {startDate: startDate, endDate: endDate}, (response) => {
      let updatedReservations = this.openReservations
      updatedReservations.unshift(response.data)
      this.openReservations = updatedReservations
    })
  }

  archiveCompletedReservations = () => {
    let reservationIds = this.completeReservations.map((reservation) => reservation.id)
    RequestService.post(`${config.backendUrl}/scanner/reservations/archive`, {reservationIds: reservationIds}, (response) => {
      this.getReservations(true)
    })
  }

  createScannerTicket = (values, callback) => {
    console.log(values)
    RequestService.post(`${config.backendUrl}/scanner/reservations`, {scannerTicket: values}, (response) => {
      let updatedReservations = this.openReservations
      updatedReservations.splice(0, 0, response.data)
      this.openReservations = updatedReservations
      callback && callback()
    })
  }

  setCurrentVoice = (voice) => {
    console.log(voice)
    this.currentVoice = voice
    this.writeToSession()
  }

  writeToSession = () => {
    const json = JSON.stringify(this);
    sessionStorage.setItem('ScannerStore', json);
  }
  
  buildFromSession = () => {
    const json = JSON.parse(sessionStorage.getItem('ScannerStore'))
    if (json) {
      this.currentVoice = json.currentVoice
    }
  }

  get openReservationsCount() { return this.openReservations.length }
  get activeReservationsCount() { return this.activeReservations.length }
  get completeReservationsCount() { return this.completeReservations.length }
  get totalReservationsCount() { return this.openReservations.length + this.activeReservations.length + this.completeReservations.length }
}

decorate(ScannerStore, {
  reservations: observable,
  openReservations: observable,
  activeReservations: observable,
  completeReservations: observable,
  serviceTicketCount: observable,
  reservationsPreLoaded: observable,
  selectedReservation: observable,
  selectedReservationRows: observable,
  archivedReservations: observable,
  archivedReservationsPreLoaded: observable,
  currentVoice: observable,
  activeReservationsCount: computed,
  openReservationsCount: computed,
  completeReservationsCount: computed,
  totalReservationsCount: computed
})

export default ScannerStore;