import { observable, decorate, action } from 'mobx';
import config from '../config';
import RequestService from '../services/RequestService';
import BaseStore from './BaseStore';
import { APPS } from '../constants'
class ReservationStore extends BaseStore {
  
  reservations = null;
  reservationsPreLoaded = false;

  getReservations = () => {
    !this.reservationsPreLoaded && RequestService.get(`${config.backendUrl}/reservations`, (response) => {
      this.reservations = response.data
      this.reservationsPreLoaded = true
      this.writeToSession()
    })
  }

  addReservation = (callback) => {
    const endpoint = 'reservations'
    RequestService.post(`${config.backendUrl}/${endpoint}`, {reservation: {date: new Date()}}, (response) => {
      let updated = this.reservations;
      updated.splice(0, 0, response.data)
      this.reservations = updated
      this.writeToSession()
      callback(`/${APPS.RESERVATIONS.baseRoute}/${response.data.id}/rental-items`)
    })
  }

  createReservation = (values, callback) => {
    const endpoint = 'reservations'
    RequestService.post(`${config.backendUrl}/${endpoint}`, {reservation: values}, (response) => {
      callback && callback(response.data);
      let updated = this.reservations;
      updated.splice(0, 0, response.data)
      this.reservations = updated
      this.writeToSession();
    })
  }

  deleteReservation = (id) => {
    RequestService.delete(`${config.backendUrl}/reservationss/${id}`, () => {
      let updatedReservations = this.reservations;
      updatedReservations.splice(updatedReservations.findIndex((reservation) => reservation.id === id), 1)
      this.reservations = updatedReservations
      this.writeToSession()
    })
  }

  // updateReservation

  writeToSession = () => {
    const json = JSON.stringify(this);
    sessionStorage.setItem('ReservationStore', json);
  }

  buildFromSession = () => {
    const json = JSON.parse(sessionStorage.getItem('ReservationStore'))
    if (json) {
      this.reservations = json.reservations
    }
  }
}

decorate(ReservationStore, {
  reservations: observable,
  reservationsPreLoaded: observable,
  createReservation: action
});

export default ReservationStore;
