import { Box, Typography } from '@mui/material';
import React from 'react';

const AppDisabled = () => (
  <Box textAlign="center" pt={5}>
    <Typography variant="h4">
      Well this is embarassing...
    </Typography>
    <Typography variant="body1">
      We are currently experiencing technical difficulties. Please come back later!
    </Typography>
  </Box>
);

export default AppDisabled;
