import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, Checkbox, Grid, IconButton, LinearProgress, Modal, Paper, TextField, Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { useHistory } from 'react-router';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  p: 4,
};

const LinearProgressWithLabel = ({ currentValue, totalValue }) => (
  <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
    <Box sx={{ width: '80%', mr: 1 }}>
      <LinearProgress variant="determinate" value={(currentValue / totalValue) * 100} />
    </Box>
    <Box sx={{ minWidth: 35 }}>
      <Typography variant="body2" color="text.secondary">
        {
          `${currentValue} / ${totalValue}`
        }
      </Typography>
    </Box>
  </Box>
);

LinearProgressWithLabel.propTypes = {
  currentValue: PropTypes.number,
  totalValue: PropTypes.number,
};

const useStyles = makeStyles(() => ({
  paper: {
    height: 'auto',
    padding: '2rem',
    marginBottom: '2rem',
  },
  incompleteScannerTicket: {
    backgroundColor: '#ffcccb',
  },
  completeScannerTicket: {
    backgroundColor: '#bddad4',
  },
  activeScannerTicket: {
    border: '2px black dashed',
  },
  itemRow: {
    marginBottom: '1.5rem',
    borderBottom: '1px solid grey',
  },
}));

const scannerTicketComplete = (scannerTicket) => (
  scannerTicket.scannerTicketItems.some((ticketItem) => (
    ticketItem.scannedQuantity < ticketItem.quantity))
);

const ScannerTicket = ({
  scannerTicket, active, setActiveScannerTicket, scannerTicketItemId, setScannerTicketItemId,
  checkForSubmit, isServiceTicket, scanItem,
}) => {
  const [showViewItemModal, setShowViewItemModal] = useState(false);
  const [viewingItem, setViewingItem] = useState(null);

  const classes = useStyles();
  const history = useHistory();

  const viewItem = (ticketItem) => {
    setViewingItem(ticketItem);
    setShowViewItemModal(true);
  };

  const renderImageView = () => (
    <Box>
      <Typography variant="h5">{viewingItem.itemName}</Typography>
      <Typography variant="body1">{viewingItem.itemDescription}</Typography>
      {viewingItem.itemImageUrl
        ? <img width="100%" src={viewingItem.itemImageUrl} alt="Item" />
        : <Button onClick={() => history.push(`/inventory-management/items/${viewingItem.itemId}`)}>Upload Image</Button>}
    </Box>
  );

  return (
    <Paper
      onClick={() => setActiveScannerTicket(scannerTicket)}
      className={`
        ${classes.paper}
        ${scannerTicketComplete(scannerTicket) ? classes.incompleteScannerTicket : classes.completeScannerTicket} 
        ${active && classes.activeScannerTicket}
      `}
      elevation={2}
    >
      <Box mb={2}>
        <Typography variant="h5">
          {`Bag ID: ${scannerTicket.id}`}
        </Typography>
      </Box>
      <Grid container>
        {scannerTicket.scannerTicketItems.map((ticketItem) => (
          ticketItem.quantity > 0
          && (
          <Grid container className={classes.itemRow}>
            <Grid item md={1}>
              <Checkbox disabled checked={ticketItem.scannedQuantity === ticketItem.quantity} />
            </Grid>
            <Grid item md={1}>
              {ticketItem.itemLegacyScanId}
            </Grid>
            <Grid item md={2}>
              {ticketItem.itemName}
              <IconButton onClick={() => viewItem(ticketItem)}>
                <ZoomInIcon />
              </IconButton>

            </Grid>
            <Grid item md={6}>
              <LinearProgressWithLabel
                currentValue={ticketItem.scannedQuantity}
                totalValue={ticketItem.quantity}
              />
            </Grid>
            <Grid item md={1}>
              {ticketItem.employeeName}
            </Grid>
            {isServiceTicket && (
            <Grid item md={1}>
              <Button onClick={() => {
                scanItem(scannerTicket.id, ticketItem.id);
              }}
              >
                Scan
              </Button>
            </Grid>
            )}
          </Grid>
          )
        ))}

      </Grid>
      {active
        && (
        <TextField
          value={scannerTicketItemId}
          onKeyDown={checkForSubmit}
          onChange={(event) => setScannerTicketItemId(event.target.value)}
          autoFocus
        />
        )}
      <Modal
        open={showViewItemModal}
        onClose={() => setShowViewItemModal(false)}
      >
        <Box sx={style}>
          {viewingItem && renderImageView()}
        </Box>
      </Modal>
    </Paper>
  );
};

ScannerTicket.propTypes = {
  scannerTicket: PropTypes.node,
  active: PropTypes.bool,
  setActiveScannerTicket: PropTypes.func,
  scannerTicketItemId: PropTypes.number,
  setScannerTicketItemId: PropTypes.func,
  checkForSubmit: PropTypes.func,
  isServiceTicket: PropTypes.bool,
  scanItem: PropTypes.func,
};

export default ScannerTicket;
export { LinearProgressWithLabel };
