import {
  Box, Button, Divider, FormGroup, FormLabel, Grid, Typography,
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React from 'react';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '66%',
  height: '66%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const AddCustomerForm = ({ onSubmit, handleCancel }) => (
  <Box sx={modalStyle}>
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
      }}
      onSubmit={onSubmit}
    >
      {
          ({ handleSubmit }) => (
            <Form>
              <Box p="5rem">
                <Typography variant="h5">Add Customer</Typography>
                <Grid container>
                  <Grid item md={6}>
                    <Box p="0.25rem">
                      <FormGroup>
                        <FormLabel>First Name</FormLabel>
                        <Field name="firstName" />
                      </FormGroup>
                    </Box>
                  </Grid>
                  <Grid item md={6}>
                    <Box p="0.25rem">
                      <FormGroup>
                        <FormLabel>Last Name</FormLabel>
                        <Field name="lastName" />
                      </FormGroup>
                    </Box>
                  </Grid>
                  <Grid item md={12}>
                    <Box p="0.25rem">
                      <FormGroup>
                        <FormLabel>Address 1</FormLabel>
                        <Field name="address1" />
                      </FormGroup>
                    </Box>
                  </Grid>
                  <Grid item md={4}>
                    <Box p="0.25rem">
                      <FormGroup>
                        <FormLabel>City</FormLabel>
                        <Field name="city" />
                      </FormGroup>
                    </Box>
                  </Grid>
                  <Grid item md={4}>
                    <Box p="0.25rem">
                      <FormGroup>
                        <FormLabel>State</FormLabel>
                        <Field name="state" />
                      </FormGroup>
                    </Box>
                  </Grid>
                  <Grid item md={4}>
                    <Box p="0.25rem">
                      <FormGroup>
                        <FormLabel>Zip Code</FormLabel>
                        <Field name="zip" />
                      </FormGroup>
                    </Box>
                  </Grid>
                  <Grid item md={6}>
                    <Box p="0.25rem">
                      <FormGroup>
                        <FormLabel>Email</FormLabel>
                        <Field name="email" />
                      </FormGroup>
                    </Box>
                  </Grid>
                  <Grid item md={6}>
                    <Box p="0.25rem">
                      <FormGroup>
                        <FormLabel>Phone</FormLabel>
                        <Field name="phone" />
                      </FormGroup>
                    </Box>
                  </Grid>
                </Grid>
                <Box my="2rem">
                  <Divider />
                </Box>
                <Button variant="contained" onClick={handleSubmit}>Save</Button>
                <Button onClick={handleCancel}>Cancel</Button>
              </Box>
            </Form>
          )
        }
    </Formik>
  </Box>
);

export default AddCustomerForm;
