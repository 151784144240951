import { observable, decorate, action } from 'mobx';
import config from '../config';
import RequestService from '../services/RequestService';
import BaseStore from './BaseStore';

class PropertyStore extends BaseStore {
  constructor() {
    super()
    this.buildFromSession();
  }

  properties = null;
  propertiesPreLoaded = false;
  selectedProperty = null;
  selectedPropertyPreLoaded = false;
  bagsGenerating = false;
  bagStatus = null;
  bagStatusPreLoaded = false;

  getProperties = (forceUpdate = false, callback) => {
    (!this.propertiesPreLoaded || forceUpdate) && RequestService.get(`${config.backendUrl}/properties`, (response) => {
      response.data.sort((a,b) => { return a.id - b.id })
      this.properties = response.data
      this.propertiesPreLoaded = true
      this.writeToSession()
      callback && callback()
    })
  }

  addProperty = () => {
    this.addRecord('properties', {property: {name: 'placeholder'}}, this.properties)
  }

  updateProperty = (property) => {
    const updatePayload = {
      property: {
        [property.field]: property.value
      }
    }
    console.log(property)
    RequestService.post(`${config.backendUrl}/properties/${property.id}`, updatePayload, (response) => {
      console.log(response.data)
      this.selectedProperty = response.data
      let updatedProperties = this.properties;
      updatedProperties.splice(updatedProperties.findIndex((p) => p.id === property.id), 1, response.data)
      this.properties = updatedProperties
      console.log(response.data)
      this.writeToSession()
    })
  }

  updatePropertyBagsInStore = (property, bagItem1, bagItem2) => {
    let updatedProperties = this.properties
    console.log(updatedProperties)
    updatedProperties[0].bags[0].bagItems[0].quantity += 1
    this.properties = updatedProperties
  }

  deleteProperty = (id) => {
    RequestService.delete(`${config.backendUrl}/properties/${id}`, () => {
      let updatedProperties = this.properties
      updatedProperties.splice(updatedProperties.findIndex((property) => property.id === id), 1)
      this.properties = updatedProperties
      this.writeToSession()
    })
  }

  getProperty = (id, forceUpdate = false, callback) => {
    (!this.selectedPropertyPreLoaded || forceUpdate) && RequestService.get(`${config.backendUrl}/properties/${id}`, (response) => {
      this.selectedProperty = response.data
      this.selectedPropertyPreLoaded = true
      callback && callback()
    })
  }

  getBagStatus = (forceUpdate = false, id) => {
    (!this.bagStatusPreLoaded || forceUpdate) && RequestService.get(`${config.backendUrl}/properties/${id}/bag-status`, (response) => {
      this.bagStatus = response.data
      this.bagStatusPreLoaded = true
    })
  }

  generateBags = (id, maxBagWeight, iterations, useLearning, callback) => {
    this.bagsGenerating = true
    RequestService.post(
      `${config.backendUrl}/bag-items/${id}/generate`, 
      {maxBagWeight: maxBagWeight, iterations: iterations, useLearning: useLearning}, 
      () => {
        callback && callback()
        this.getProperty(id, true, () => this.bagsGenerating = false)
      }
    )
  }

  addBag = (propertyId, callback) => {
    RequestService.post(
      `${config.backendUrl}/bag-items/${propertyId}/add-bag`, 
      {}, 
      (response) => {
        callback && callback()
        this.selectedProperty = response.data
      }
    )
  }

  deleteBag = (bagId, callback) => {
    RequestService.delete(
      `${config.backendUrl}/bags/${bagId}`,
      (response) => {
        callback && callback()
        this.selectedProperty = response.data
      }
    )
  }

  writeToSession = () => {
    const json = JSON.stringify(this);
    sessionStorage.setItem('PropertyStore', json);
  }
  
  buildFromSession = () => {
    const json = JSON.parse(sessionStorage.getItem('PropertyStore'))
    if (json) {
      this.properties = json.properties
      this.propertiesPreLoaded = json.propertiesPreLoaded
      this.selectedProperty = json.selectedProperty
    }
  }
}

decorate(PropertyStore, {
  getProperties: action,
  properties: observable,
  propertiesPreLoaded: observable,
  selectedProperty: observable,
  selectedPropertyPreLoaded: observable,
  bagsGenerating: observable,
  bagStatus: observable,
  bagStatusPreLoaded: observable
});

export default PropertyStore; 