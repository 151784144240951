import {
  Box, Grid, TextField, Button, Typography, Autocomplete,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Field, FieldArray, Formik } from 'formik';
import React, { useState } from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import StepWizard from 'react-step-wizard';

const useStyles = makeStyles(() => ({
  commentsInput: {
    width: '100%',
    marginBottom: '2rem',
  },
}));

/* eslint-disable */
const Stepper = ({ nextStep, previousStep }) => (
  <Box>
    {previousStep && <Button onClick={previousStep}>Previous</Button>}
    <Button onClick={nextStep}>Next</Button>
  </Box>
);

const BasicInfo = ({
  propertyStore, setFieldValue, values, nextStep, previousStep,
}) => (
  <Box>
    <Box mb={1}>
      <Autocomplete
        options={propertyStore.properties}
        renderInput={(params) => <TextField {...params} label="Property" />}
        getOptionLabel={(option) => option.name}
        onChange={(_event, property) => setFieldValue('propertyId', property.id)}
      />
    </Box>
    <Box mb={1}>
      <TextField
        type="startDate"
        helperText="Start Date"
        value={values.startDate}
        onChange={(event) => setFieldValue('startDate', event.target.value)}
      />
    </Box>
    <Stepper nextStep={nextStep} />
  </Box>
);

const AddItems = ({
  itemStore, setSelectedItem, values, arrayHelpers, selectedItem, handleChange, nextStep, previousStep,
}) => (
  <Box>
    <Box mb={1}>
      <Autocomplete
        id="kit-combo-box"
        options={itemStore.items.sort((item) => (item.itemCategoryName === 'Linens' ? -1 : 1))}
        getOptionLabel={(option) => (option.name)}
        renderInput={(params) => <TextField {...params} label="Item" />}
        onChange={(event, value) => setSelectedItem(value)}
        groupBy={(option) => option.itemCategoryName}
      />
    </Box>
    <Box mb={1}>
      <Button
        onClick={() => {
          if (values
            .scannerTicketItems
            .filter((item) => item.itemId === selectedItem.id).length === 0
          ) {
            arrayHelpers.push({ itemId: selectedItem.id, itemName: selectedItem.name });
          }
        }}
        variant="contained"
        disabled={!selectedItem}
      >
        Add Item
      </Button>
    </Box>
    <Grid container>
      <Box mb={3}>
        {values.scannerTicketItems.length === 0
          && (
          <Typography variant="p">
            No items added.
          </Typography>
          )}
        {values.scannerTicketItems.map((scannerTicketItem, index) => (
          <Grid item md={12}>
            {`${scannerTicketItem.itemName}: `}
            <Field
              name={`scannerTicketItems.${index}.quantity`}
              onChange={handleChange}
              placeholder="Enter quantity"
            />
          </Grid>
        ))}
      </Box>
    </Grid>
    <Stepper nextStep={nextStep} previousStep={previousStep} />
  </Box>
);

const Comments = ({ classes, nextStep, previousStep }) => (
  <Box>
    <Grid container>
      <label>Comments:</label>
      <Field
        name="comments"
        component="textarea"
        className={classes.commentsInput}
      />
    </Grid>
    <Stepper nextStep={nextStep} previousStep={previousStep} />
  </Box>

);

const Review = ({
  values, scannerStore, propertyStore, afterSubmit, previousStep,
}) => (
  <Box>
    <Grid item md={12}>
      <b>Property:</b>
      {' '}
      {values.propertyId
        ? propertyStore.properties.filter((property) => property.id == values.propertyId)[0].name
        : 'No property selected'}
    </Grid>
    <Grid item md={12}>
      <b>Start Date:</b>
      {' '}
      {values.startDate}
    </Grid>
    <Grid item md={12}>
      <Box mb={3}>
        <b>Items: </b>
        <br />
        {values.scannerTicketItems.map((item) => (
          <span>
            {item.itemName}
            :
            {' '}
            {item.quantity}
            <br />
          </span>
        ))}
      </Box>
    </Grid>
    <Grid item md={12}>
      <Box mb={3}>
        <b>Comments: </b>
        {values.comments}
      </Box>
    </Grid>
    <Button
      onClick={previousStep}
    >
      Previous
    </Button>
    <Button
      variant="contained"
      color="primary"
      onClick={() => scannerStore.createScannerTicket(values, afterSubmit)}
      disabled={!values.startDate || !values.propertyId}
    >
      Save
    </Button>
  </Box>
);

/* eslint-enable */

const ServiceTicketForm = ({
  propertyStore, itemStore, scannerStore, afterSubmit,
}) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const classes = useStyles();
  return (
    <Formik
      initialValues={{
        startDate: format(new Date(), 'yyyy-MM-dd'),
        propertyId: null,
        scannerTicketItems: [],
        comments: '',
      }}
    >
      {({ values, setFieldValue, handleChange }) => (
        <FieldArray
          name="scannerTicketItems"
          render={(arrayHelpers) => (
            <Box>
              <Typography variant="h5">Create Service Ticket</Typography>
              <StepWizard>
                <BasicInfo
                  propertyStore={propertyStore}
                  values={values}
                  setFieldValue={setFieldValue}
                />
                <AddItems
                  itemStore={itemStore}
                  values={values}
                  setSelectedItem={setSelectedItem}
                  selectedItem={selectedItem}
                  arrayHelpers={arrayHelpers}
                  handleChange={handleChange}
                />
                <Comments classes={classes} />
                <Review
                  values={values}
                  scannerStore={scannerStore}
                  afterSubmit={afterSubmit}
                  propertyStore={propertyStore}
                />

              </StepWizard>
            </Box>
          )}
        />
      )}
    </Formik>
  );
};

ServiceTicketForm.propTypes = {
  propertyStore: PropTypes.any,
  itemStore: PropTypes.any,
  scannerStore: PropTypes.any,
  afterSubmit: PropTypes.func,
};

export default ServiceTicketForm;
