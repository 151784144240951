import {
  Box, Button, CircularProgress, Grid,
} from '@mui/material';
import React from 'react';
import Cards from 'react-credit-cards';
import RequestService from '../../../services/RequestService';
import config from '../../../config';
import WushButtonCard from '../../../components/WushButtonCard';
import AddPaymentMethod from '../../payments/AddPaymentMethod';

const CreditPayment = ({
  amount, handleSuccess, promoCode,
}) => {
  const [pastPaymentMethods, setPastPaymentMethods] = React.useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState(null);
  const [addPaymentMethod, setAddPaymentMethod] = React.useState(false);

  const fetchPaymentMethods = () => {
    RequestService.get(`${config.backendUrl}/public/payment_methods`, (response) => {
      setPastPaymentMethods(response.data);
    });
  };

  React.useEffect(() => {
    fetchPaymentMethods();
  }, []);

  if ((!pastPaymentMethods)) return <CircularProgress />;

  return (
    <Box>
      {(!pastPaymentMethods || pastPaymentMethods.length === 0 || addPaymentMethod)
        ? (
          <AddPaymentMethod
            handleSuccess={() => {
              fetchPaymentMethods();
              setAddPaymentMethod(false);
            }}
            buttonText="Add Card"
            onCancel={() => setAddPaymentMethod(false)}
          />
        )
        : (
          <Box>
            {pastPaymentMethods.map((paymentMethod) => (
              <Box mb={2}>
                <WushButtonCard
                  onClick={() => setSelectedPaymentMethod(paymentMethod)}
                  active={paymentMethod.id === selectedPaymentMethod?.id}
                >
                  <Grid container>
                    <Grid item md={8}>
                      {paymentMethod.card.brand.toUpperCase()}
                      {' '}
                      ending in
                      {' '}
                      {paymentMethod.card.last4}
                    </Grid>
                    <Grid item md={4}>
                      <Cards
                        name=" "
                        expiry={`${paymentMethod.card.exp_month}/${paymentMethod.card.exp_year}`}
                        number={`**** **** **** ${paymentMethod.card.last4}`}
                        preview
                        issuer={paymentMethod.card.brand}
                      />
                    </Grid>
                  </Grid>
                </WushButtonCard>
              </Box>
            ))}
            {selectedPaymentMethod && (
              <Box>
                <Button fullWidth variant="contained" onClick={() => handleSuccess(selectedPaymentMethod.id, promoCode)}>
                  Pay ($
                  {amount / 100}
                  )
                </Button>
              </Box>
            )}
            <Box>
              <Button fullWidth onClick={() => setAddPaymentMethod(true)}>
                Add Payment Method
              </Button>
            </Box>
          </Box>
        )}
    </Box>
  );
};

export default CreditPayment;
