import { observable, decorate } from 'mobx';
import config from '../config';
import RequestService from '../services/RequestService';
import BaseStore from './BaseStore';

class DomainStore extends BaseStore {
  constructor() {
    super()
    this.buildFromSession();
  }

  domain = null;
  organization = null;
  organizationPreLoaded = false;

  getOrganizationByDomain = (domain) => {
    this.domain = domain
    !this.organizationPreLoaded && RequestService.get(`${config.backendUrl}/public/organization_by_domain/${this.domain}`, (response) => {
      this.organization = response.data
      this.organizationPreLoaded = true
      this.writeToSession()
    }, () => {
      this.organization = false
      this.organizationPreLoaded = true
      this.writeToSession()
    })
  }

  writeToSession = () => {
    const json = JSON.stringify(this);
    sessionStorage.setItem('DomainStore', json);
  }
  
  buildFromSession = () => {
    console.log(window.pathname)
    const json = JSON.parse(sessionStorage.getItem('DomainStore'))
    if (json) {
      this.organization = json.organization
      this.organizationPreLoaded = json.organizationPreLoaded
    }
  }
}

decorate(DomainStore, {
  domain: observable,
  organization: observable,
  organizationPreLoaded: observable
});

export default DomainStore;