import {
  Box, Button, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography,
} from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router';
import WushCard from '../../../../components/WushCard';

const PickupRouteCard = ({ pickupRoute }) => {
  const history = useHistory();
  return (
    <WushCard>
      <Box p={2}>
        <Grid container>
          <Grid item md={4}>
            <Box>
              <b>
                Route #
                {pickupRoute.id}
              </b>
            </Box>
            <Box>
              <Typography variant="body1">
                Number of tickets:
                {' '}
                {pickupRoute.pickups.length}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body1">
                Date created:
                {' '}
                {pickupRoute.createdAt}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body1">
                Assigned to:
                {' '}
                {pickupRoute.employee?.firstName}
                {' '}
                {pickupRoute.employee?.lastName}
              </Typography>
            </Box>
          </Grid>
          <Grid item md={8}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      Customer name
                    </TableCell>
                    <TableCell>
                      Res #
                    </TableCell>
                    <TableCell>
                      Items in reservation
                    </TableCell>
                    <TableCell>
                      Delivery location
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pickupRoute.pickups.map((pickup) => (
                    <TableRow>
                      <TableCell>
                        <Typography variant="body1">
                          {pickup.reservation.customerName}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">
                          {pickup.reservation.id}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {pickup.reservation.reservationItems.map((item) => `${item.reservedQuantity} ${item.itemName}`).join(', ')}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption" display="block">
                          {pickup.reservation.propertyName}
                        </Typography>
                        <Typography variant="caption" display="block">
                          {pickup.reservation.propertyAddress}
                        </Typography>
                        <Typography variant="caption" display="block">
                          {pickup.reservation.propertyCity}
                          ,
                          {pickup.reservation.propertyState}
                          {' '}
                          {pickup.reservation.propertyZip}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Box>
          <Button variant="contained" onClick={() => history.push(`/reservation-management/pickup-routes/${pickupRoute.id}`)}>View</Button>
        </Box>
      </Box>
    </WushCard>
  );
};

export default PickupRouteCard;
