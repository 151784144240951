import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  Box, Button, Divider, TextField, Typography,
} from '@mui/material';
import { observer } from 'mobx-react';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { useHistory } from 'react-router';
import Loader from '../../components/loader/Loader';
import WushCard from '../../components/WushCard';

const useStyles = makeStyles(() => ({
  emailContainer: {
    width: '300px',
  },
  passwordContainer: {
    width: '300px',
  },
  buttonContainer: {
    verticalAlign: 'middle',
  },
  errorContainer: {
    marginLeft: '33%',
    marginTop: '10px',
  },
}));

const Login = ({
  baseStore, authStore, alertStore, onSuccess, onlyLogin = false,
}) => {
  const classes = useStyles();
  const history = useHistory();
  baseStore.getHealthCheck();
  if (authStore.loggedIn && !onSuccess) return <Redirect to="/home" />;
  if (baseStore.healthLoading) return <Loader message="WUSH is waking up..." />;
  if (authStore.loginLoading) return <Loader />;

  return (
    <Box pt={!onlyLogin && 10} textAlign="center" width="100%">
      <Box maxWidth="md" mr="auto" ml="auto">
        <WushCard>
          <Box p={5}>
            <Box mb={2}>
              <Typography variant="h3" className={classes.headerContainer}>Login</Typography>
            </Box>
            <Formik
              initialValues={{ email: '', password: '' }}
              onSubmit={(values) => authStore.login(
                values.email,
                values.password,
                () => {
                  if (onSuccess) {
                    onSuccess();
                  } else {
                    history.push('/home');
                  }
                },
                () => alertStore.setAlertMessage('Invalid user name or password!', 'error'),
              )}
            >
              {
                ({ values, handleChange, handleSubmit }) => (
                  <Form>
                    <Box>
                      <Box mb={2}>
                        <TextField
                          fullWidth
                          required
                          id="email"
                          label="Email"
                          type="email"
                          autoComplete="current-email"
                          variant="outlined"
                          onChange={handleChange}
                          value={values.email}
                        />
                        <TextField
                          fullWidth
                          required
                          id="password"
                          label="Password"
                          type="password"
                          margin="normal"
                          autoComplete="current-password"
                          variant="outlined"
                          onChange={handleChange}
                          value={values.password}
                          onKeyUp={(e) => { if (e.key === 'Enter') handleSubmit(); }}
                        />
                      </Box>
                      <Box mb={2}>
                        <Button variant="contained" fullWidth className={classes.buttonContainer} onClick={handleSubmit}>
                          Login
                        </Button>
                      </Box>
                      {!onlyLogin && (
                      <>
                        <Box mb={2}>
                          <Divider />
                        </Box>
                        <Box mb={2}>
                          <Button variant="outlined" fullWidth className={classes.buttonContainer} onClick={() => history.push('/register')}>
                            Sign Up
                          </Button>
                        </Box>
                        <Box>
                          <Button fullWidth className={classes.buttonContainer} onClick={() => history.push('/reset-password')}>
                            Forgot Password
                          </Button>
                        </Box>
                      </>
                      )}
                    </Box>
                  </Form>
                )
              }
            </Formik>
          </Box>
        </WushCard>
      </Box>
    </Box>
  );
};

Login.propTypes = {
  authStore: PropTypes.object,
  baseStore: PropTypes.object,
};

export default observer(Login);
