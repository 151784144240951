import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import SampleChart from './SampleChart';

const Dashboard = () => (
  <Container fluid className="dashboard text-align-left">
    <Col md={4}>
      <h1>Dashboard</h1>
      <Row className="mt-5 mb-5">
        <h3>Sample Chart Alpha</h3>
        <SampleChart />
      </Row>
      <Row>
        <h3>Sample Chart Bravo</h3>
        <SampleChart />
      </Row>
    </Col>
  </Container>
);

export default Dashboard;
