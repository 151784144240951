import React from 'react';
import PeopleIcon from '@mui/icons-material/People';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Dashboard from './containers/dashboard/Dashboard';
import Employees from './containers/employees/Employees';

export const PAGES = {
  DASHBOARD: {
    title: "Dashboard",
    baseRoute: "dashboard",
    icon: <DashboardIcon />,
    component: Dashboard
  },
  EMPLOYEES: {
    title: "Employees",
    baseRoute: "employees",
    icon: <PeopleIcon />,
    component: Employees
  }
}