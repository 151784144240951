import React from 'react';
import { Row, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const HomeSidebar = () => (
  <Container className="sidebar" fluid>
    <Row>
      <Link className="sidebar-link" to="/home">Home</Link>
    </Row>
  </Container>
);

export default HomeSidebar;
