import {
  Box,
  Button, Checkbox, TableCell, TableRow,
} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { observer } from 'mobx-react';

const ReservationRow = ({
  reservation, scannerStore, selectRow, allowScanning = true, allowProcessing = false,
}) => {
  const history = useHistory();
  const handleDelete = () => scannerStore.deleteReservation(reservation);
  const handleProcess = () => scannerStore.updateServiceTicket(reservation, 'active');
  const hanldeUnProcess = () => scannerStore.updateServiceTicket(reservation, 'open');
  const handleComplete = () => scannerStore.updateServiceTicket(reservation, 'complete');
  const hanldeUnComplete = () => scannerStore.updateServiceTicket(reservation, 'active');

  const renderAction = () => {
    if (reservation.serviceTicketStatus === 'active') {
      return (
        <Box>
          <Button onClick={hanldeUnProcess} color="primary">Un-process</Button>
          <Button onClick={handleComplete} color="primary">Complete</Button>
        </Box>
      );
    }
    if (reservation.serviceTicketStatus === 'open') {
      return <Button onClick={handleProcess} color="primary">Process</Button>;
    }
    if (reservation.serviceTicketStatus === 'complete') {
      return <Button onClick={hanldeUnComplete} color="primary">Un-complete</Button>;
    }
    return <>No action</>;
  };

  return (
    <TableRow
      key={reservation.id}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell>
        {selectRow && <Checkbox onChange={(event, value) => selectRow(reservation.id, value)} /> }
      </TableCell>
      <TableCell component="th" scope="row">
        {reservation.propertyName}
      </TableCell>
      <TableCell><Button color="primary" variant="contained" onClick={() => history.push(`/scanner/${reservation.id}`)}>{allowScanning ? 'scan' : 'view'}</Button></TableCell>
      <TableCell>{reservation.startDate}</TableCell>
      <TableCell align="right">{reservation.scannerTickets.length}</TableCell>
      <TableCell align="right">{reservation.totalItems}</TableCell>
      <TableCell align="right">{reservation.scannedItems}</TableCell>
      <TableCell align="right">
        <Button onClick={handleDelete} color="secondary">Delete</Button>
        {allowProcessing && renderAction()}
      </TableCell>
    </TableRow>
  );
};

ReservationRow.propTypes = {
  reservation: PropTypes.object,
  scannerStore: PropTypes.any,
  allowScanning: PropTypes.bool,
  allowProcessing: PropTypes.bool,
  selectRow: PropTypes.func,
};

export default observer(ReservationRow);
