import { Box, Button, Grid } from '@mui/material';
import React from 'react';

const NextBackButton = ({ nextStep, previousStep }) => (
  <Box width="100%" textAlign="center">
    <Grid container>
      <Grid item md={6}>
        <Box textAlign="left">
          {previousStep && <Button onClick={previousStep}>Previous</Button>}
        </Box>
      </Grid>
      <Grid item md={6}>
        <Box textAlign="right">
          {nextStep && <Button variant="contained" onClick={nextStep}>Next</Button>}
        </Box>
      </Grid>
    </Grid>
  </Box>
);

export default NextBackButton;
