import {
  Box, Divider, Grid, Typography,
} from '@mui/material';
import React from 'react';
import WushCard from '../../../../components/WushCard';
import CurrentPropertyItems from './CurrentPropertyItems';

const PropertyInformation = ({ property }) => (
  <Box>
    <Typography variant="body1">
      {property.name}
    </Typography>
    <Typography variant="body1">
      {property.address1}
    </Typography>
    <Typography variant="body1">
      {`${property.city}, ${property.state} ${property.zip}`}
    </Typography>
  </Box>
);

const SelectedProperty = ({ property }) => (
  <WushCard>
    <Grid container>
      <Grid item md={6}>
        <Box mb="0.5rem">
          <Typography variant="h6">Property Details</Typography>
        </Box>
        <Divider />
        {property ? (
          <Box p="1rem">
            <PropertyInformation property={property} />
          </Box>
        )
          : (
            <Box p="1rem">
              Please select a property
            </Box>
          )}
      </Grid>
      <Grid item md={6}>
        <Box mb="0.5rem">
          <Typography variant="h6">Items at Property</Typography>
        </Box>
        <Divider />
        <Box p="1rem">
          <CurrentPropertyItems property={property} />
        </Box>
      </Grid>
    </Grid>
  </WushCard>
);

export default SelectedProperty;
export { PropertyInformation };
