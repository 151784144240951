import React from 'react';
import { observer } from 'mobx-react';
import { Button } from '@mui/material';
import { DataGrid } from '@material-ui/data-grid';
import { makeStyles } from '@mui/styles';
import { format } from 'date-fns';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  employeesTableContainer: {
    height: '500px',
  },
  employeesContainer: {
    paddingTop: '2rem',
  },
  buttonContainer: {
    marginBottom: '1rem',
  },
}));

const Employees = ({ employeeStore }) => {
  const classes = useStyles();

  const columns = [
    {
      field: 'firstName',
      headerName: 'First name',
      width: 150,
      editable: true,
    },
    {
      field: 'lastName',
      headerName: 'Last name',
      width: 150,
      editable: true,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 300,
      editable: true,
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      width: 300,
      editable: false,
      type: 'date',
    },
    {
      field: 'Actions',
      headerName: '',
      width: 100,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="secondary"
          onClick={() => employeeStore.deleteEmployee(params.id)}
        >
          Delete
        </Button>
      ),
      editable: false,
      sortable: false,
    },
  ];

  const employeeRows = employeeStore.employees.map((employee) => ({
    id: employee.id,
    firstName: employee.firstName,
    lastName: employee.lastName,
    email: employee.email,
    createdAt: employee.createdAt && format(new Date(employee.createdAt), 'PP'),
  }));

  return (
    <div className={classes.employeesContainer}>
      <div className={classes.buttonContainer}>
        <Button
          variant="contained"
          color="primary"
          onClick={employeeStore.addEmployee}
        >
          Add Employee
        </Button>
      </div>
      <div className={classes.employeesTableContainer}>
        <DataGrid
          rows={employeeRows}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          onCellEditCommit={employeeStore.updateEmployee}
        />
      </div>
    </div>
  );
};

Employees.propTypes = {
  employeeStore: PropTypes.any,
};

export default observer(Employees);
