import { Box, Grid } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import CustomerSearch from './components/CustomerSearch';
import NextBackButton from './components/NextBackButton';
import PropertySearch from './components/PropertySearch';
import SelectedCustomer from './components/SelectedCustomer';
import SelectedProperty from './components/SelectedProperty';

const CustomerSelection = ({
  nextStep, previousStep, customers, selectedCustomer, onSelect, properties,
  selectedProperty, createCustomer,
}) => (
  <Grid container>
    <Grid item md={6}>
      <Box m="1rem">
        <CustomerSearch
          customers={customers.sort((a, b) => (a.firstName > b.firstName ? 1 : -1))}
          onSelect={(customer) => onSelect('selectedCustomer', customer)}
          createCustomer={createCustomer}
        />
      </Box>
    </Grid>
    <Grid item md={6}>
      <Box m="1rem">
        <PropertySearch
          properties={properties}
          onSelect={(property) => onSelect('selectedProperty', property)}
        />
      </Box>
    </Grid>
    <Grid item md={6}>
      <Box m="1rem">
        <SelectedCustomer customer={selectedCustomer} />
      </Box>
    </Grid>
    <Grid item md={6}>
      <Box m="1rem">
        <SelectedProperty property={selectedProperty} />
      </Box>
    </Grid>
    <NextBackButton nextStep={nextStep} previousStep={previousStep} />
  </Grid>
);

export default observer(CustomerSelection);
