/* eslint-disable */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

const AuthenticatedRoute = ({ component: C, props: cProps, onlyAdmin: onlyAdmin, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if(onlyAdmin && (!cProps.authStore.loggedIn || !cProps.authStore.isAdmin)) return <Redirect to="/login" />
      if(!cProps.authStore.loggedIn) return <Redirect to="/login" />
      return <C {...props} {...cProps} />
    }}
  />
);

AuthenticatedRoute.propTypes = {
  component: PropTypes.any,
  props: PropTypes.object,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }),
};

export default observer(AuthenticatedRoute);
/* eslint-enable */
