import { Box } from '@mui/material';
import React from 'react';
import Loader from '../../../../components/loader/Loader';
import config from '../../../../config';
import RequestService from '../../../../services/RequestService';
import DeliveryRouteCard from './DeliveryRouteCard';

const DeliveryRoutes = () => {
  const [deliveryRoutes, setDeliveryRoutes] = React.useState(null);

  if (!deliveryRoutes) {
    RequestService.get(`${config.backendUrl}/delivery-routes`, (response) => {
      setDeliveryRoutes(response.data);
    });
  }

  if (!deliveryRoutes) return <Loader />;

  return (
    <Box p={2}>
      {deliveryRoutes.map((deliveryRoute) => (
        <Box mb={2}>
          <DeliveryRouteCard deliveryRoute={deliveryRoute} />
        </Box>
      ))}
    </Box>
  );
};

export default DeliveryRoutes;
