import { TextField } from '@mui/material';
import React from 'react';

const MuiFormikTextField = ({
  name, formik, value, ...rest
}) => (
  <TextField
    onChange={(e) => formik.setFieldValue(name, e.target.value)}
    value={value}
    size="small"
    inputProps={{
      style: {
        padding: 2,
        paddingLeft: 5,
        height: 36,
      },
    }}
    {...rest}
  />
);

export default MuiFormikTextField;
