import { Box } from '@mui/material';
import React from 'react';
import Loader from '../../../../components/loader/Loader';
import config from '../../../../config';
import RequestService from '../../../../services/RequestService';
import PickupRouteCard from './PickupRouteCard';

const PickupRoutes = () => {
  const [pickupRoutes, setPickupRoutes] = React.useState(null);

  if (!pickupRoutes) {
    RequestService.get(`${config.backendUrl}/pickup-routes`, (response) => {
      setPickupRoutes(response.data);
    });
  }

  if (!pickupRoutes) return <Loader />;

  return (
    <Box p={2}>
      {pickupRoutes.map((pickupRoute) => (
        <Box mb={2}>
          <PickupRouteCard pickupRoute={pickupRoute} />
        </Box>
      ))}
    </Box>
  );
};

export default PickupRoutes;
