import {
  Box, Step, StepLabel, Stepper, CircularProgress,
} from '@mui/material';
import React from 'react';
import StepWizard from 'react-step-wizard';
import { observer } from 'mobx-react';
import AdditionalInformation from './containers/AdditionalInformation';
import PaymentInformation from './containers/PaymentInformation';
import PersonalInformation from './containers/PersonalInformation';
import SelectOrganization from './containers/SelectOrganization';

const steps = [
  'Personal Information',
  'Additional Information',
  'Select Organization',
  'Payment Information',
];

const Navigator = ({ currentStep }) => (
  <Box sx={{ width: '100%' }} mb="2rem">
    <Stepper activeStep={currentStep} alternativeLabel>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  </Box>
);

const SetupAccount = ({ customerStore, organizationStore, alertStore }) => {
  customerStore.getCustomer();
  organizationStore.getOrganizations();
  if (!customerStore.customerPreLoaded) return <CircularProgress />;
  return (
    <Box pt={5}>
      <StepWizard
        nav={<Navigator />}
        isHashEnabled
      >
        <PersonalInformation customerStore={customerStore} />
        <AdditionalInformation customerStore={customerStore} />
        <SelectOrganization organizationStore={organizationStore} customerStore={customerStore} />
        <PaymentInformation alertStore={alertStore} />
      </StepWizard>
    </Box>
  );
};

export default observer(SetupAccount);
