import {
  Box, Divider, Grid, ListItem, ListItemButton, ListItemText, TextField, Typography,
} from '@mui/material';
import React from 'react';
import Fuse from 'fuse.js';
import { FixedSizeList } from 'react-window';
import WushCard from '../../../../components/WushCard';

const PropertySearch = ({ properties, onSelect }) => {
  const [searchField, setSearchField] = React.useState(null);

  const fuse = properties ? new Fuse(properties, {
    keys: ['name'],
  }) : new Fuse();

  const searchedProperties = searchField
    ? fuse.search(searchField).map((item) => item.item)
    : properties;
  const renderRow = (props) => {
    const { index, style } = props;

    return (
      <ListItem
        style={style}
        key={index}
        component="div"
        disablePadding
      >
        <ListItemButton onClick={() => onSelect(searchedProperties[index])}>
          <ListItemText primary={`${searchedProperties[index]?.name}`} />
        </ListItemButton>
      </ListItem>
    );
  };

  return (
    <WushCard>
      <Grid container>
        <Grid item md={8}>
          <Box mb="0.75rem" textAlign="left">
            <Typography variant="h5">Property Search</Typography>
          </Box>
        </Grid>
      </Grid>
      <Box mb="1rem">
        <TextField
          fullWidth
          id="outlined-name"
          label="Search Item"
          value={searchField}
          onChange={(e) => { setSearchField(e.target.value); }}
        />
      </Box>
      <Divider />
      <Box>
        <FixedSizeList
          height={400}
          itemSize={46}
          itemCount={searchedProperties.length}
          overscanCount={5}
        >
          {renderRow}
        </FixedSizeList>
      </Box>
    </WushCard>
  );
};

export default PropertySearch;
