import {
  Box, Button, LinearProgress, Typography,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { mapCalendarEventToWush, mapWushEventToCalendar } from './services';
import config from '../../config';
import RequestService from '../../services/RequestService';
import WushCalendar from './components/WushCalendar';
import WushModal from '../../components/WushModal';
import EventInfo from './components/EventInfo';

const MyEvents = ({ alertStore }) => {
  const [myEvents, setMyEvents] = useState(null);
  const [showViewModal, setShowViewModal] = useState(false);
  const [viewingEvent, setViewingEvent] = useState(null);

  const history = useHistory();

  const { scrollToTime } = useMemo(
    () => ({
      scrollToTime: new Date(1970, 1, 1, 4),
    }),
    [],
  );

  const fetchMyEvents = () => {
    RequestService.get(`${config.backendUrl}/public/events/me`, (response) => {
      setMyEvents(response.data.map(mapWushEventToCalendar));
    });
  };

  if (!myEvents) {
    fetchMyEvents();
  }

  const handleUnenrollment = () => {
    if (viewingEvent.userEnrolled) {
      RequestService.delete(`${config.backendUrl}/public/event_registrations/${viewingEvent.registrationId}`, {}, () => {
        fetchMyEvents(() => setShowViewModal(false));
        alertStore.setAlertMessage('Successfully unregistered for event!', 'success');
      });
    }
  };

  if (!myEvents) return <LinearProgress />;

  const handleSelectEvent = (event) => {
    setViewingEvent(mapCalendarEventToWush(event));
    setShowViewModal(true);
  };

  return (
    <Box pt={2}>
      <Box mb={2}>
        <Typography variant="h4">My Calendar</Typography>
      </Box>
      <Box mb={2}>
        <Button onClick={() => history.push('/my-event-series')}>View My Event Series</Button>
      </Box>
      {myEvents && (
        <WushCalendar
          events={myEvents}
          scrollToTime={scrollToTime}
          onSelectEvent={handleSelectEvent}
          popup
        />
      )}
      <WushModal
        open={showViewModal}
        onClose={() => setShowViewModal(false)}
      >
        {viewingEvent && <EventInfo event={viewingEvent} handleUnenrollment={handleUnenrollment} />}
      </WushModal>
    </Box>
  );
};

export default MyEvents;
