import {
  Box, Button, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography,
} from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import WushCard from '../../../components/WushCard';
import { calculateItemCost } from './components/CartItem';
import { calculateSubtotal, calculateTax } from './components/ItemCart';
import NextBackButton from './components/NextBackButton';
import { CustomerInformation } from './components/SelectedCustomer';
import { PropertyInformation } from './components/SelectedProperty';

const OrderSummary = ({ previousStep, values, createReservation }) => (
  <Box>
    <Grid container>
      <Grid item md={6}>
        <Box mb="1rem">
          <Typography variant="h5">Order Summary</Typography>
        </Box>
      </Grid>
      <Grid item md={6}>
        <Box textAlign="right">
          <Button onClick={() => {}} variant="contained">Send Quote</Button>
        </Box>
      </Grid>
    </Grid>
    <Box mb="1rem">
      <WushCard>
        <Box p="2rem">
          <Grid container>
            <Grid item md={6}>
              <Box>
                {values.selectedCustomer
                  && <CustomerInformation customer={values.selectedCustomer} />}
              </Box>
            </Grid>
            <Grid item md={6} textAlign="right">
              <Box>
                {values.selectedProperty
                  && <PropertyInformation property={values.selectedProperty} />}
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Box width="100%">
              {values.selectedItems.length > 0 && (
              <Box>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          Item Summary
                        </TableCell>
                        <TableCell>
                          Quantity
                        </TableCell>
                        <TableCell>
                          Rental Dates
                        </TableCell>
                        <TableCell>
                          Receiving
                        </TableCell>
                        <TableCell>
                          Return
                        </TableCell>
                        <TableCell>
                          Price
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {values.selectedItems.map((item) => (
                        <TableRow>
                          <TableCell>{item.name}</TableCell>
                          <TableCell>{item.selectedQuantity}</TableCell>
                          <TableCell>
                            {`${item.dateRange?.startDate} - ${item.dateRange?.endDate}`}
                          </TableCell>
                          <TableCell>{item.receivingMethod}</TableCell>
                          <TableCell>{item.returningMethod}</TableCell>
                          <TableCell>
                            $
                            {item.selectedQuantity
                              ? calculateItemCost(item)
                              : 0}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              )}
            </Box>
          </Grid>
          <Grid container>
            <Grid md={6} />
            <Grid md={6}>
              <Box mt="1rem" textAlign="right">
                <Typography variant="span">
                  Tax: $
                  {calculateTax(values.selectedItems)}
                </Typography>
              </Box>
              <Box textAlign="right">
                <b>Order Total: </b>
                $
                {calculateSubtotal(values.selectedItems) + calculateTax(values.selectedItems)}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </WushCard>
    </Box>
    <Grid container>
      <Grid item md={12}>
        <Box textAlign="right">
          <Button onClick={createReservation} variant="contained">Create</Button>
        </Box>
      </Grid>
    </Grid>
    <NextBackButton previousStep={previousStep} />
  </Box>
);

export default observer(OrderSummary);
