import {
  Button, Checkbox, Chip, Fade, IconButton, LinearProgress, Modal, Typography,
  Container,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React from 'react';
import PropTypes from 'prop-types';
import { DataGrid } from '@material-ui/data-grid';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import KitForm from './components/KitForm';
import ItemCategoryAutocomplete from '../../components/ItemCategoryAutocomplete';

const useStyles = makeStyles((theme) => ({
  kitsWrapper: {
    paddingTop: '2rem',
  },
  buttonContainer: {
    marginBottom: '1rem',
  },
  kitsTableContainer: {
    height: '500px',
  },
  paper: {
    position: 'absolute',
    width: '75%',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: 'center',
  },
  kitItemDiv: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  categoriesCombo: {
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: '8px',
  },
}));

const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    padding: '1rem',
  };
};

const Kits = ({ kitStore, itemStore, itemCategoryStore }) => {
  kitStore.getKits();
  itemStore.getItems();
  itemCategoryStore.getItemCategories();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [editingKit, setEditingKit] = React.useState(null);
  const [modalStyle] = React.useState(getModalStyle);

  if (!kitStore.kitsPreLoaded) return (<LinearProgress />);

  const columns = [
    {
      field: 'kit',
      headerName: '',
      width: 50,
      renderCell: (params) => (
        <IconButton
          size="small"
          onClick={() => { setOpen(true); setEditingKit(params.value); }}
        >
          <EditOutlinedIcon />
        </IconButton>
      ),
      editable: false,
      sortable: false,
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
      editable: true,
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 200,
      editable: true,
    },
    {
      field: 'itemCategoryId',
      headerName: 'Category',
      width: 300,
      renderCell: (params) => (
        <ItemCategoryAutocomplete
          itemCategoryStore={itemCategoryStore}
          params={params}
          onUpdate={kitStore.updateKitField}
        />
      ),
      editable: false,
      sortable: false,
    },
    {
      field: 'customItemPrice',
      headerName: 'Custom Price',
      width: 100,
      editable: false,
      renderCell: (params) => (
        <Checkbox
          // eslint-disable-next-line
          checked={params.value}
          onChange={(_e, value) => {
            // eslint-disable-next-line
            kitStore.updateKitField({ field: 'customItemPrice', value, id: params.id });
          }}
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      ),
    },
    {
      field: 'kitItems',
      headerName: 'Items',
      width: 250,
      // eslint-disable-next-line
      renderCell: (params) => (
        <div className={classes.kitItemDiv}>
          {/* eslint-disable-next-line */}
          {params.value.map((kitItem) => <Chip size="small" key={kitItem.id} 
            label={`${kitItem.itemName}: ${kitItem.quantity}`}
          />)}
        </div>
      ),
    },
    {
      field: 'halfDayRate',
      headerName: 'Half day',
      width: 150,
      editable: false,
    },
    {
      field: 'fullDayRate',
      headerName: 'Full day',
      width: 150,
      editable: false,
    },
    {
      field: 'weekRate',
      headerName: 'Week',
      width: 150,
      editable: false,
    },
    {
      field: 'Actions',
      headerName: '',
      width: 100,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="secondary"
          onClick={() => kitStore.deleteKit(params.id)}
        >
          Delete
        </Button>
      ),
      editable: false,
      sortable: false,
    },
  ];

  const kitRows = kitStore.kits.map((kit) => (
    {
      id: kit.id,
      kit,
      name: kit.name,
      itemCategoryId: kit.itemCategoryId,
      itemCategoryName: kit.itemCategoryName,
      kitItems: kit.kitItems,
      description: kit.description,
      halfDayRate: kit.halfDayRate,
      fullDayRate: kit.fullDayRate,
      weekRate: kit.weekRate,
      customItemPrice: kit.customItemPrice,
    }
  ));

  return (
    <Container>
      <Typography variant="h3">Kits</Typography>
      <div className={classes.buttonContainer}>
        <Button
          variant="contained"
          color="primary"
          onClick={kitStore.addKit}
          data-testid="add-kit"
        >
          Add Kit
        </Button>
      </div>
      <div className={classes.kitsTableContainer}>
        <DataGrid
          rows={kitRows}
          columns={columns}
          disableSelectionOnClick
          onCellEditCommit={kitStore.updateKitField}
        />
      </div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
      >
        <Fade in={open}>
          <div style={modalStyle} className={classes.paper}>
            {editingKit
              && (
              <KitForm
                editingKit={editingKit}
                itemStore={itemStore}
                kitStore={kitStore}
                itemCategoryStore={itemCategoryStore}
                afterSave={() => { setOpen(false); }}
                onCancel={() => setOpen(false)}
              />
              )}
          </div>
        </Fade>
      </Modal>
    </Container>
  );
};

Kits.propTypes = {
  kitStore: PropTypes.any,
  itemStore: PropTypes.any,
  itemCategoryStore: PropTypes.any,
};

export default observer(Kits);
