import React from 'react';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';

const mapWushEventToCalendar = ({id, startDatetime, endDatetime, name, description, userEnrolled, ...rest}) => (
  {
    id: id,
    title: <span>{name}{' '}{userEnrolled && <DirectionsBikeIcon />}</span>,
    start: new Date(startDatetime),
    end: new Date(endDatetime),
    desc: description,
    userEnrolled: userEnrolled,
    ...rest
  }
)

const mapCalendarEventToWush = ({id, start, end, title, desc, ...rest}) => (
  {
    id: id,
    name: title,
    startDatetime: start,
    endDatetime: end,
    description: desc,
    ...rest
  }
)

export {mapCalendarEventToWush, mapWushEventToCalendar}