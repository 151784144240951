import { TextField, Autocomplete } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  itemsCombo: {
    maxWidth: '15rem',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

const KitItemsAutocomplete = ({
  itemStore, selectedItem, kitItems, addItem, editingKit,
}) => {
  const classes = useStyles();
  return (
    <Autocomplete
      id="kit-combo-box"
      options={itemStore.items}
      getOptionLabel={(option) => (option.name)}
      className={classes.itemsCombo}
      renderInput={(params) => <TextField {...params} label="Please select" variant="outlined" />}
      value={selectedItem}
      onChange={(_event, value) => {
        if (!kitItems
            || !kitItems.map((item) => item.itemName).includes(value?.name)) {
          if (value) {
            addItem({
              itemName: value.name, itemId: value.id, kitId: editingKit.id, ...value,
            });
          }
        }
      }}
    />
  );
};

KitItemsAutocomplete.propTypes = {
  itemStore: PropTypes.any,
  selectedItem: PropTypes.object,
  kitItems: PropTypes.array,
  addItem: PropTypes.func,
  editingKit: PropTypes.object,
};

export default KitItemsAutocomplete;
