import {
  Box, Button, CircularProgress, Grid, List, ListItem, ListItemIcon,
  ListItemText, Stack, Typography, FormGroup, FormLabel, FormControlLabel,
  Radio, RadioGroup, Backdrop, Checkbox,
} from '@mui/material';
import { format } from 'date-fns';
import React, { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import CancelIcon from '@mui/icons-material/Cancel';
import WushCard from '../../components/WushCard';
import config from '../../config';
import RequestService from '../../services/RequestService';
import WushModal from '../../components/WushModal';
import EventSeriesCheckout from './components/EventSeriesCheckout';
import EventSeriesEvents from './components/EventSeriesEvents';
import EventCapacityStatus from './components/EventCapacityStatus';

const ViewEventSeries = ({ alertStore }) => {
  const [eventSeries, setEventSeries] = useState(null);
  const [checked, setChecked] = React.useState([]);
  const [showCheckoutModal, setShowCheckoutModal] = React.useState(false);
  const [eventCount, setEventCount] = React.useState(null);
  const [eventSlots, setEventSlots] = React.useState([]);
  const match = useRouteMatch();
  const { eventSeriesId } = match.params;
  const [paymentProcessing, setPaymentProcessing] = useState(false);

  const history = useHistory();

  const getEventSeries = (callback) => {
    RequestService.get(`${config.backendUrl}/public/event_series/${eventSeriesId}`, (response) => {
      setEventSeries(response.data);
      if (response.data.restrictedPreEnrollment) setEventCount(response.data.eventCount);
      if (callback) callback();
    });
  };

  if (!eventSeries) {
    getEventSeries();
    return <CircularProgress />;
  }

  const eventsGroupedByStartDay = eventSeries.events.reduce((r, a) => {
    const grouped = r;
    const currentTimezoneOffset = new Date().getTimezoneOffset() * 60 * 1000;
    let dt = new Date(a.startDatetime);
    dt = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);
    dt = new Date(dt.valueOf() - currentTimezoneOffset);
    const key = format(dt, "EEEE's' 'at' hh:mm b");
    grouped[key] = grouped[key] || [];
    grouped[key].push(a);
    return grouped;
  }, Object.create(null));

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const mapSelectedEvents = () => {
    let selectedEvents = [];
    eventSlots.forEach((slot) => {
      selectedEvents = selectedEvents.concat(eventsGroupedByStartDay[slot]);
    });
    return selectedEvents;
  };

  const getSelectedEventIds = () => {
    if (eventSeries.restrictedPreEnrollment) {
      return mapSelectedEvents().map((event) => event.id);
    }

    return checked.map((event) => event.id);
  };

  const handleSuccess = (paymentMethodId, promoCode) => {
    setPaymentProcessing(true);
    setShowCheckoutModal(false);
    RequestService.post(`${config.backendUrl}/public/event_registrations`, {
      event_registration: {
        eventSeriesId: eventSeries.id,
        eventIds: getSelectedEventIds(),
        eventCount,
        paymentMethodId,
        timeSlots: eventSlots.length,
        promoCode,
      },
    }, () => {
      getEventSeries();
      setPaymentProcessing(false);
      setShowCheckoutModal(false);
      alertStore.setAlertMessage('You have successfully enrolled in this event series.');
      history.push('/my-events');
    });
  };

  const handleTimeSlotCheck = (selectedChecked, slot) => {
    if (selectedChecked) {
      setEventSlots(eventSlots.concat([slot]));
    } else {
      setEventSlots(eventSlots.filter((eventSlot) => eventSlot !== slot));
    }
  };

  const renderRestrictedPreEnrollment = () => (
    <Box mb={10}>
      <WushCard>
        <Grid container>
          <Grid item md={6}>
            <Box>
              <Box>
                <Typography variant="h6">
                  <strong>{eventSeries.eventCount}</strong>
                  {' '}
                  classes for
                  {' '}
                  <strong>
                    $
                    {eventSeries.costDollars}
                  </strong>
                </Typography>
              </Box>
              <Box mb={3} mt={3}>
                {/* eslint-disable-next-line */}
                <div dangerouslySetInnerHTML={{ __html: eventSeries.description }} />
                <Typography variant="body1">
                  This series starts on
                  {' '}
                  {format(new Date(eventSeries.startDatetime), 'MM/dd/yyyy')}
                  {' '}
                  and ends on
                  {' '}
                  {format(new Date(eventSeries.endDatetime), 'MM/dd/yyyy')}
                </Typography>
              </Box>
              <Box>
                <FormLabel>
                  <Typography variant="h6">
                    Select your time slot
                  </Typography>
                  <Typography variant="body1">
                    *You will be able to edit your schedule after
                    {' '}
                    {format(new Date(eventSeries.startDatetime), 'MM/dd/yyyy')}
                  </Typography>
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                >
                  {Object.keys(eventsGroupedByStartDay).map((slot) => (
                    <Box mb={2}>
                      <FormControlLabel
                        value={slot}
                        control={(
                          <Checkbox
                            disabled={
                          eventsGroupedByStartDay[slot][0].eventFull
                          || eventsGroupedByStartDay[slot][0].userEnrolled
                        }
                            onClick={(e) => {
                              handleTimeSlotCheck(e.target.checked, e.target.value);
                            }}
                          />
)}
                        label={(
                          <Typography>
                            {slot}
                            {' '}
                            {eventsGroupedByStartDay[slot][0].userEnrolled
                              && <em>(Already enrolled)</em>}
                            {' '}
                            <EventCapacityStatus event={eventsGroupedByStartDay[slot][0]} />
                          </Typography>
                          )}
                      />
                    </Box>
                  ))}
                </RadioGroup>
              </Box>
            </Box>
            <Box>
              <Stack flexDirection="column">
                <Button disabled={!eventSlots} onClick={() => setShowCheckoutModal(true)} variant="contained">Checkout</Button>
                <Button onClick={() => {
                  if (!eventCount) {
                    history.goBack();
                  } else {
                    setEventCount(null);
                  }
                }}
                >
                  Cancel
                </Button>
              </Stack>
            </Box>
          </Grid>
          <Grid item md={6}>
            <Box p={5}>
              <img width="80%" src={eventSeries.imageUrl} alt="Event Series" />
            </Box>
          </Grid>
        </Grid>
      </WushCard>
    </Box>
  );

  return (
    <Box pt={2}>
      <Box>
        {eventSeries.userEnrolled && (
        <Box pt={2}>
          <Box>
            <Typography variant="h4">{eventSeries.name}</Typography>
          </Box>
          <Typography variant="body1">
            *You are already enrolled in this event.
            {' '}
            You may purchase additional event packages now.
          </Typography>
        </Box>
        )}
        {eventSeries.restrictedPreEnrollment
          ? renderRestrictedPreEnrollment()
          : (
            <Box mb={2}>
              <WushCard>
                <Box p={2}>
                  <Grid container>
                    <FormGroup>
                      <Box mb={1}>
                        <FormLabel><Typography variant="h6">1. Select Cost Package</Typography></FormLabel>
                      </Box>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        value={eventCount}
                        onChange={(e) => setEventCount(e.target.value)}
                      >
                        {eventSeries.costMatrix.map((costRow) => (
                          <FormControlLabel
                            value={costRow.eventCount}
                            control={<Radio />}
                            label={(
                              <Typography>
                                {costRow.eventCount}
                                {' '}
                                classes for
                                {' '}
                                <strong>
                                  $
                                  {costRow.cost}
                                </strong>
                              </Typography>
)}
                          />
                        ))}
                      </RadioGroup>
                    </FormGroup>
                  </Grid>
                </Box>
              </WushCard>
            </Box>
          )}
        {!eventSeries.restrictedPreEnrollment && (
        <Grid container>
          <Grid item md={7}>
            <Box pr={2}>
              <List dense component="div" role="list">
                <EventSeriesEvents
                  title="2. Select events"
                  eventSeries={eventSeries}
                  events={eventSeries.userEnrolled
                    ? eventSeries.enrolledEvents : eventSeries.events}
                  selectedEvents={checked}
                  handleToggle={handleToggle}
                  disabled={!eventCount || checked.length >= eventCount}
                />
                <ListItem />
              </List>
            </Box>
          </Grid>
          {(checked.length > 0 || eventCount) && (
          <Grid item md={4}>
            <Box mt={4} sx={{ position: 'fixed' }}>
              <WushCard
                width="100%"
              >
                <Box>
                  <Box textAlign="center">
                    <Box mb={2}>
                      <Typography variant="h5">
                        Selected Events (
                        {checked.length}
                        )
                      </Typography>
                    </Box>
                    {eventSeries.costMatrix && eventCount && (
                    <Box mb={2}>
                      <Typography variant="h6">
                        {eventCount}
                        {' '}
                        classes for
                        {' '}
                        <strong>
                          $
                          {eventSeries.costMatrix.filter((costRow) => (
                            costRow.eventCount === eventCount
                          ))[0].cost}
                        </strong>
                      </Typography>
                    </Box>
                    )}
                    <Box>
                      <Stack flexDirection="column">
                        <Button disabled={checked.length > eventCount} onClick={() => setShowCheckoutModal(true)} variant="contained">Checkout</Button>
                        <Button onClick={() => setEventCount(null)}>Cancel</Button>
                      </Stack>
                    </Box>
                  </Box>
                  <Box>
                    <List dense component="div" role="list">
                      {checked.sort((a, b) => a && b && b.startDatetime - a.startDatetime)
                        .map((event) => {
                          const labelId = `transfer-list-item-${event.id}-label`;

                          return (
                            <ListItem
                              key={event.id}
                              role="listitem"
                              button
                              onClick={handleToggle(event)}
                            >
                              <ListItemIcon>
                                <CancelIcon />
                              </ListItemIcon>
                              <ListItemText id={labelId}>
                                <Grid container>
                                  <Grid item md={12}>
                                    {format(new Date(event.startDatetime), 'MM/dd H:mm b')}
                                    {' '}
                                    -
                                    {format(new Date(event.endDatetime), 'H:mm b')}
                                  </Grid>
                                </Grid>
                              </ListItemText>
                            </ListItem>
                          );
                        })}
                      <ListItem />
                    </List>
                  </Box>
                </Box>
              </WushCard>
            </Box>
          </Grid>
          )}
        </Grid>
        )}
        <WushModal
          open={showCheckoutModal}
          onClose={() => setShowCheckoutModal(false)}
        >
          <Box>
            <EventSeriesCheckout
              eventSeries={eventSeries}
              selectedEvents={
                eventSeries.restrictedPreEnrollment
                  ? mapSelectedEvents() : checked
              }
              eventCount={mapSelectedEvents().length}
              slotCount={eventSlots.length}
              handleSuccess={handleSuccess}
            />
          </Box>
        </WushModal>
      </Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={paymentProcessing}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default ViewEventSeries;
