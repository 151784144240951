import { observable, decorate, action } from 'mobx';
import config from '../config';
import RequestService from '../services/RequestService';
import BaseStore from './BaseStore';

class ItemCategoryStore extends BaseStore {
  constructor() {
    super()
    this.buildFromSession();
  }

  itemCategories = null;
  itemCategoriesPreLoaded = false;

  getItemCategories = () => {
    const endpoint = 'item-categories'
    !this.itemCategoriesPreLoaded && RequestService.get(`${config.backendUrl}/${endpoint}`, (response) => {
      response.data.sort((a,b) => { return a.id - b.id })
      this.itemCategories = response.data
      this.itemCategoriesPreLoaded = true
      this.writeToSession()
    })
  }

  addItemCategory = () => {
    const endpoint = 'item-categories'
    RequestService.post(`${config.backendUrl}/${endpoint}`, {itemCategory: {name: "placeholder"}}, (response) => {
      let updated = this.itemCategories;
      updated.splice(0, 0, response.data)
      this.itemCategories = updated
      this.writeToSession()
    })
  }

  updateItemCategory = (itemCategory) => {
    const updatePayload = {
      itemCategory: {
        [itemCategory.field]: itemCategory.value
      }
    }
    RequestService.post(`${config.backendUrl}/item-categories/${itemCategory.id}`, updatePayload, (response) => {
      let updateItemCategories = this.itemCategories;
      updateItemCategories.splice(updateItemCategories.findIndex((category) => category.id === itemCategory.id), 1, response.data)
      this.writeToSession()
    })
  }

  deleteItemCategory = (id) => {
    RequestService.delete(`${config.backendUrl}/item-categories/${id}`, () => {
      let updateItemCategories = this.itemCategories;
      updateItemCategories.splice(updateItemCategories.findIndex((itemCategory) => itemCategory.id === id), 1)
      this.itemCategories = updateItemCategories
      this.writeToSession()
    })
  }

  writeToSession = () => {
    const json = JSON.stringify(this);
    sessionStorage.setItem('ItemCategoryStore', json);
  }

  buildFromSession = () => {
    const json = JSON.parse(sessionStorage.getItem('ItemCategoryStore'))
    if (json) {
      this.itemCategories = json.itemCategories
      this.itemCategoriesPreLoaded = json.itemCategoriesPreLoaded
    }
  }
}

decorate(ItemCategoryStore, {
  itemCategories: observable,
  itemCategoriesPreLoaded: observable,
  getItemCategories: action,
});

export default ItemCategoryStore;