import { Box, Typography } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router';
import AddPaymentMethod from '../../payments/AddPaymentMethod';
import NextBackButton from '../components/NextBackButton';

const PaymentInformation = ({ previousStep, alertStore }) => {
  const history = useHistory();

  return (
    <Box pt={2}>
      <Box textAlign="left">
        <Typography variant="h4">Enter payment information</Typography>
      </Box>
      <Box textAlign="right" mb={5}>

        <AddPaymentMethod
          buttonText="Save Payment Method"
          handleSuccess={() => {
            alertStore.setAlertMessage('Successfully adding payment method and completed account setup!');
            history.push('/home');
          }}
        />
      </Box>
      <NextBackButton
        previousStep={previousStep}
        nextStep={() => {
          alertStore.setAlertMessage('Successfully completed setup!'); history.push('/home');
        }}
      />
    </Box>
  );
};

export default PaymentInformation;
