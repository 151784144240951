import {
  Alert, Box, CircularProgress, Typography,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router';

import config from '../../config';
import RequestService from '../../services/RequestService';
// import ExampleTemplate from './templates/ExampleTemplate';
import SurfContest from './templates/SurfContest';

// ({ authStore, customerStore, alertStore }
const PublicEvent = ({
  authStore, alertStore, baseStore,
}) => {
  const [event, setEvent] = useState(null);

  const [registering, setRegistering] = useState(false);
  const [successfullyRegistered, setSuccessfullyRegistered] = useState(false);
  const [showItemPurchaseModal, setShowItemPurchaseModal] = useState(false);

  const match = useRouteMatch();
  const { eventId } = match.params;
  const history = useHistory();

  const fetchEvent = () => {
    RequestService.get(
      `${config.backendUrl}/public/anonymous-events/${eventId}`,
      (response) => {
        setEvent(response.data);
      },
      () => {
        history.push('/login');
      },
    );
  };

  const handleRegistrationSuccess = (customerId, values, paymentIntent) => {
    RequestService.post(
      `${config.backendUrl}/public/anonymous-events/${eventId}/register/${customerId}`,
      {
        customer: {
          quantity: values.quantity,
          items: values.cart,
        },
        paymentIntent,
        participants: values.participants,
        customerIsParticipating: values.customerIsParticipating,
      },
      () => {
        if (values.quantity > 0) setSuccessfullyRegistered(true);
        setRegistering(false);
        setShowItemPurchaseModal(false);
        alertStore.setAlertMessage('Successfully registered! You should receive an email with your registration details.');
      },
    );
  };

  useEffect(() => {
    fetchEvent();
  }, []);

  if (!event) return <CircularProgress />;

  return (
    <>
      {successfullyRegistered && (
        <Box mb={2}>
          <Alert severity="success">
            <Box>
              <Typography>
                Your order was placed successfully! You will receive an email soon with order
                confirmation and details.
              </Typography>
            </Box>
          </Alert>
        </Box>
      )}
      <SurfContest
        event={event}
        handleRegistrationSuccess={handleRegistrationSuccess}
        registering={registering}
        setRegistering={setRegistering}
        successfullyRegistered={successfullyRegistered}
        showItemPurchaseModal={showItemPurchaseModal}
        alertStore={alertStore}
        authStore={authStore}
        baseStore={baseStore}
      />
    </>
  );
};

export default PublicEvent;
