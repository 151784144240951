import { observable, decorate, action } from 'mobx';
import config from '../config';
import RequestService from '../services/RequestService';
import BaseStore from './BaseStore';

class EmployeeStore extends BaseStore {
  constructor() {
    super()
    this.buildFromSession();
  }

  employees = false;
  employeesPreLoaded = false;
  currentEmployee = {};

  getEmployees = (successCallback) => {
    !this.employeesPreLoaded && RequestService.get(`${config.backendUrl}/employees`,
      (response) => {
        this.employees = response.data;
        this.employeesPreLoaded = true;
        this.writeToSession();
        successCallback && successCallback();
    });
  }

  setCurrentEmployee = (employee) => {
    this.currentEmployee = employee;
    this.writeToSession();
  };

  addEmployee = () => {
    this.addRecord('employees', {employee: {firstName: 'placeholder'}}, this.employees)
  }

  deleteEmployee = (id) => {
    RequestService.delete(`${config.backendUrl}/employees/${id}`, () => {
      let updatedEmployees = this.employees;
      updatedEmployees.splice(updatedEmployees.findIndex((employee) => employee.id === id), 1)
      this.employees = updatedEmployees
      this.writeToSession()
    })
  }

  updateEmployee = (employee) => {
    const updatePayload = {
      employee: {
        [employee.field]: employee.value
      }
    }
    RequestService.post(`${config.backendUrl}/employees/${employee.id}`, updatePayload, (response) => {
      let updatedEmployees = this.employees;
      updatedEmployees.splice(updatedEmployees.findIndex((emp) => emp.id === employee.id), 1, response.data)
      this.writeToSession()
    })
  }

  writeToSession = () => {
    const json = JSON.stringify(this);
    sessionStorage.setItem('EmployeeStore', json);
    sessionStorage.setItem('CurrentEmployeeId', this.currentEmployee.id)
  }

  buildFromSession = () => {
    const json = JSON.parse(sessionStorage.getItem('EmployeeStore'));
    if (json) {
      this.employees = json.employees;
      this.employeesPreLoaded = json.employeesPreLoaded;
      this.currentEmployee = json.currentEmployee;
    }
  }
}

decorate(EmployeeStore, {
  employees: observable,
  employeesPreLoaded: observable,
  currentEmployee: observable,
  addEmployee: action
});

export default EmployeeStore;