import {
  Box, Button, Divider, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Typography,
} from '@mui/material';
import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';

import { observer } from 'mobx-react';
import { FieldArray } from 'formik';
import WushInputV2 from '../../../../components/WushInputV2';
import NextBackButton from './NextBackButton';
import isMobile from '../../../../services/isMobile';

const emptyParticipant = {
  first_name: '',
  last_name: '',
  age: '',
  gender: '',
  date_of_birth: null,
  item_id: null,
};

const CustomerInformationV2 = ({
  event, formik, nextStep, previousStep, authStore,
}) => {
  if (authStore.loggedIn) {
    return (
      <Box mb={3}>
        <Typography variant="h6">
          Welcome back! You are logged in with
          {' '}
          {authStore.user.email}
          .
        </Typography>
        <Button
          onClick={() => {
            authStore.logout(() => {

            });
          }}
          variant="outlined"
        >
          Logout
        </Button>
        <NextBackButton nextStep={nextStep} previousStep={previousStep} />
      </Box>
    );
  }
  return (
    <Box mb={3} className="customer-info-box">
      <Box mb={3}>
        <Box mb={3}>
          <Typography variant="h6">
            Customer information
          </Typography>
        </Box>
        <Grid container>
          <WushInputV2
            formik={formik}
            name="firstName"
            label="First Name"
            gridCol={6}
          />
          <WushInputV2
            formik={formik}
            name="lastName"
            label="Last Name"
            gridCol={6}
          />
          <WushInputV2
            formik={formik}
            name="address1"
            label="Address 1"
            gridCol={6}
          />
          <WushInputV2
            formik={formik}
            name="address2"
            label="Address 2"
            gridCol={6}
          />
          <WushInputV2
            formik={formik}
            name="city"
            label="City"
            gridCol={8}
          />
          <WushInputV2
            formik={formik}
            name="state"
            label="State"
            gridCol={2}
          />
          <WushInputV2
            formik={formik}
            name="zip"
            label="Zip"
            gridCol={2}
          />
          <WushInputV2
            formik={formik}
            name="email"
            label="Email"
            gridCol={6}
          />
          <WushInputV2
            formik={formik}
            name="phoneNumber"
            label="Phone Number"
            helpText="You may enter your phone number to receive SMS updates for events you've registerd for."
            gridCol={6}
          />
          <>
            {formik.values.participants.length > 0 && (
            <Grid item md={12} my={2} width="100%">
              <Typography variant="h6">
                Participants
              </Typography>
              <Typography variant="caption">
                Please enter the information of the individuals participating in the event
              </Typography>
            </Grid>
            )}
            <FieldArray
              name="participants"
              render={
                (arrayHelpers) => (
                  <>
                    {formik.values.participants.map((participant, index) => (
                      <>
                        <Grid item md={12} textAlign="left" width="100%">
                          <Typography variant="body1">
                            Participant
                            {' '}
                            {index + 1}
                          </Typography>
                        </Grid>
                        <WushInputV2
                          formik={formik}
                          name={`participants.${index}.first_name`}
                          label="First name"
                          gridCol={3}
                        />
                        <WushInputV2
                          formik={formik}
                          name={`participants.${index}.last_name`}
                          label="Last name"
                          gridCol={3}
                        />
                        <WushInputV2
                          formik={formik}
                          name={`participants.${index}.date_of_birth`}
                          value={formik.values.participants[index].date_of_birth}
                          label="Date of Birth"
                          inputType="date"
                          gridCol={2}
                        />
                        <Grid item md={2} width="100%" textAlign="center">
                          <FormControl sx={{ minWidth: 120, p: '0.2rem' }} size="medium" fullWidth>
                            <InputLabel id="demo-customized-select-label">Select item</InputLabel>
                            <Select
                              value={formik.values.participants[index].item_id}
                              onChange={(e) => {
                                formik.setFieldValue(`participants.${index}.item_id`, e.target.value);
                              }}
                              labelId="demo-customized-select-label"
                            >
                              {
                                event.items[0].variations.map((itemVariation) => (
                                  <MenuItem
                                    value={itemVariation.id}
                                  >
                                    <Box textAlign="left">
                                      {itemVariation.name}
                                    </Box>

                                  </MenuItem>
                                ))
                              }
                              <Divider />
                              <MenuItem
                                value={-1}
                              >
                                I don`&apos;`t want one
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>

                        {!isMobile() && (
                        <Grid item md={2} pt={2}>
                          <IconButton onClick={() => {
                            formik.setFieldValue('quantity', formik.values.quantity - 1);
                            arrayHelpers.remove(index);
                          }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                        )}
                        <Grid item md={12} py={2}>
                          <Divider />
                        </Grid>
                      </>
                    ))}
                    {formik.values.participants.length > 0 && isMobile() && (
                      <Grid item md={12} my={2} width="100%">
                        <Button
                          color="secondary"
                          variant="outlined"
                          onClick={() => {
                            formik.setFieldValue('quantity', formik.values.quantity - 1);
                            arrayHelpers.remove(formik.values.participants.length - 1);
                          }}
                          fullWidth
                        >
                          Remove Participant
                        </Button>
                      </Grid>
                    )}
                    {
                      <Grid item md={12} my={2} width="100%">
                        <Button
                          variant={formik.values.participants.length > 0 ? 'outlined' : 'contained'}
                          onClick={() => {
                            formik.setFieldValue('quantity', formik.values.quantity + 1);
                            arrayHelpers.push(emptyParticipant);
                          }}
                          fullWidth
                        >
                          Add
                          {' '}
                          {formik.values.participants.length > 0 && 'Additional'}
                          {' '}
                          Participant
                        </Button>
                      </Grid>
                    }
                  </>
                )
              }
            />
          </>
        </Grid>
      </Box>
      <NextBackButton
        nextStep={nextStep}
        nextStepDisabled={!formik.isValid || formik.values.participants.length === 0}
        previousStep={previousStep}
      />
    </Box>
  );
};

export default observer(CustomerInformationV2);
export { emptyParticipant };
