import { observable, decorate } from 'mobx';

class AlertStore {
  alertMessage = '';
  alertType = '';

  constructor() {
    this.buildFromSession();
  }

  setAlertMessage = (message, type) => {
    this.alertMessage = message;
    this.alertType = type;
    this.writeToSession();
  }

  clearAlertMessage = () => {
    this.alertMessage = '';
    this.alertType = '';
    this.writeToSession();
  }

  buildFromSession = () => {
    const json = JSON.parse(sessionStorage.getItem('AlertStore'));
    if (json) {
      this.alertMessage = json.alertMessage;
      this.alertType = json.alertType;
    }
  }

  writeToSession = () => {
    const json = JSON.stringify(this);
    sessionStorage.setItem('AlertStore', json);
  }
}


decorate(AlertStore, {
  alertMessage: observable,
  alertType: observable
});

export default AlertStore;