import { observable, decorate } from 'mobx';
import config from '../config';
import RequestService from '../services/RequestService';

class KitStore {
  kits = null;
  kitsPreLoaded = false;

  getKits = (forceUpdate = false) => {
    if(forceUpdate) this.kitsPreLoaded = false
    !this.kitsPreLoaded && RequestService.get(`${config.backendUrl}/kits`, (response) => {
      response.data.sort((a,b) => { return a.id - b.id })
      this.kits = response.data
      this.kitsPreLoaded = true
    })
  }

  addKit = () => {
    const endpoint = 'kits'
    RequestService.post(`${config.backendUrl}/${endpoint}`, {kit: {name: "placeholder"}}, (response) => {
      let updated = this.kits;
      updated.splice(0, 0, response.data)
      this.kits = updated
      this.writeToSession()
    })
  }

  updateKitField = (kit) => {
    const updatePayload = {
      kit: {
        [kit.field]: kit.value
      }
    }
    RequestService.post(`${config.backendUrl}/kits/${kit.id}`, updatePayload, (response) => {
      let updatedKits = this.kits;
      updatedKits.splice(updatedKits.findIndex((k) => k.id === kit.id), 1, response.data)
      this.writeToSession()
    })
  }

  updateKit = (kit, successCallback) => {
    console.log("IN UPDATE")
    const updatePayload = {
      kit: {
        kitItems: kit.kitItems,
        ...kit
      }
    }
    RequestService.post(`${config.backendUrl}/kits/${kit.id}`, updatePayload, (response) => {
      console.log(response.data)
      let updatedKits = this.kits;
      updatedKits.splice(updatedKits.findIndex((k) => k.id === kit.id), 1, response.data)
      this.kits = updatedKits
      successCallback && successCallback()
    })
  }

  deleteKit = (id) => {
    RequestService.delete(`${config.backendUrl}/kits/${id}`, () => {
      let updatedKits = this.kits;
      updatedKits.splice(updatedKits.findIndex((kit) => kit.id === id), 1)
      this.kits = updatedKits
      this.writeToSession()
    })
  }
}

decorate(KitStore, {
  kits: observable,
  kitsPreLoaded: observable
});

export default KitStore;