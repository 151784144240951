import { observable, decorate, action } from 'mobx';
import config from '../config';
import RequestService from '../services/RequestService';
import BaseStore from './BaseStore';

class ItemStore extends BaseStore {
  constructor() {
    super()
    this.buildFromSession();
  }

  items = null;
  itemsPreLoaded = false;
  selectedItem = null;
  selectedItemPreLoaded = false;

  getItems = () => {
    !this.itemsPreLoaded && RequestService.get(`${config.backendUrl}/items`, (response) => {
      response.data.sort((a,b) => { return a.id - b.id })
      this.items = response.data
      this.itemsPreLoaded = true
      this.writeToSession()
    })
  }

  addItem = () => {
    this.addRecord('items', {item: {name: 'placeholder', weight: 0}}, this.items)
  }

  deleteItem = (id) => {
    RequestService.delete(`${config.backendUrl}/items/${id}`, () => {
      let updatedItems = this.items;
      updatedItems.splice(updatedItems.findIndex((item) => item.id === id), 1)
      this.items = updatedItems
      this.writeToSession()
    })
  }

  updateItem = (item) => {
    const updatePayload = {
      item: {
        [item.field]: item.value
      }
    }
    RequestService.post(`${config.backendUrl}/items/${item.id}`, updatePayload, (response) => {
      console.log(response.data)
      let updatedItems = this.items;
      updatedItems.splice(updatedItems.findIndex((item) => item.id === response.data.id), 1, response.data)
      this.writeToSession()
    })
  }

  getItem = (id, forceUpdate) => {
    (!this.selectedItemPreLoaded || parseInt(this.selectedItem.id) !== parseInt(id) || forceUpdate) && RequestService.get(`${config.backendUrl}/items/${id}`, (response) => {
      this.selectedItem = response.data
      this.selectedItemPreLoaded = true
      this.writeToSession()
    })
  }

  clearSelectedItem = () => {
    this.selectedItem = null;
    this.selectedItemPreLoaded = false;
    this.writeToSession();
  }

  makeTransfer = (itemId, startLocationId, endLocationId, quantity, successCallback) => {
    const transferPayload = {
      startLocationId: startLocationId,
      endLocationId: endLocationId,
      quantity: quantity
    }
    RequestService.post(`${config.backendUrl}/items/${itemId}/transfer`, transferPayload, (response) => {
      console.log(response)
      this.selectedItem = response.data
      this.selectedItemPreLoaded = true
      this.writeToSession()
      successCallback && successCallback()
    })
  }

  writeToSession = () => {
    const json = JSON.stringify(this);
    sessionStorage.setItem('ItemStore', json);
  }

  buildFromSession = () => {
    const json = JSON.parse(sessionStorage.getItem('ItemStore'))
    if (json) {
      this.items = json.items
      this.itemsPreLoaded = json.itemsPreLoaded
      this.selectedItem = json.selectedItem
      this.selectedItemPreLoaded = json.selectedItemPreLoaded
    }
  }
}

decorate(ItemStore, {
  items: observable,
  itemsPreLoaded: observable,
  selectedItem: observable,
  selectedItemPreLoaded: observable,
  addItem: action,
  makeTransfer: action
});

export default ItemStore;