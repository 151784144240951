import {
  Box, Divider, ListItem, ListItemButton, ListItemText, TextField, Typography,
} from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { FixedSizeList } from 'react-window';
import Fuse from 'fuse.js';
import WushCard from '../../../../components/WushCard';

const ItemSearch = ({ items, onItemSelect }) => {
  const [searchField, setSearchField] = React.useState(null);

  const fuse = new Fuse(items, {
    keys: ['name'],
  });

  const searchedItems = searchField ? fuse.search(searchField).map((item) => item.item) : items;

  const renderRow = (props) => {
    const { index, style } = props;

    return (
      <ListItem
        style={style}
        key={index}
        component="div"
        disablePadding
        secondaryAction={
          <Typography variant="span">{searchedItems[index].totalQuantity}</Typography>
        }
      >
        <ListItemButton onClick={() => onItemSelect(searchedItems[index])}>
          <ListItemText primary={searchedItems[index].name} />
        </ListItemButton>
      </ListItem>
    );
  };

  return (
    <WushCard>
      <Typography variant="h5">Item Search</Typography>
      <Box mb="1rem">
        <TextField
          fullWidth
          id="outlined-name"
          label="Search Item"
          value={searchField}
          onChange={(e) => { setSearchField(e.target.value); }}
        />
      </Box>
      <Divider />
      <Box>
        <FixedSizeList
          height={400}
          itemSize={46}
          itemCount={searchedItems.length}
          overscanCount={5}
        >
          {renderRow}
        </FixedSizeList>
      </Box>
    </WushCard>
  );
};

export default observer(ItemSearch);
