import React from 'react';
import {
  Box, FormControl, Grid,
} from '@mui/material';
import WushTextField from '../../../components/WushTextField';
import WushSelectField from '../../../components/WushSelectField';

const BIKE_WEIGHTS = [14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];
const BIKE_DRIVETRAINS = ['9speed', '10speed', '11speed', '12speed'];

const OrganizationPersonalInformationForm = ({ formik }) => (
  <Box>
    <FormControl fullWidth>
      <Grid container>
        <Grid item md={3}>
          <Box mb={2} px={2} width="100%">
            <WushTextField InputLabelProps={{ shrink: true }} formik={formik} name="dateOfBirth" label="Date of Birth" type="date" />
          </Box>
        </Grid>
        <Grid item md={3}>
          <Box mb={2} px={2} width="100%">
            <WushTextField formik={formik} name="bodyWeight" label="Body Weight (lbs)" type="number" />
          </Box>
        </Grid>
        <Grid item md={3}>
          <Box mb={2} px={2} width="100%">
            <WushTextField formik={formik} name="maxHeartrate" label="Max Heartrate (bpm)" />
          </Box>
        </Grid>
        <Grid item md={3} />
        <Grid item md={3}>
          <Box mb={2} px={2} width="100%">
            <WushSelectField
              formik={formik}
              name="bikeWeight"
              label="Bike Weight"
              options={BIKE_WEIGHTS.map((weight) => ({ label: `${weight} lbs`, value: weight })).concat({ label: 'Not sure', value: 0 })}
            />
          </Box>
        </Grid>
        <Grid item md={3}>
          <Box mb={2} px={2} width="100%">
            <WushSelectField
              formik={formik}
              name="bikeDrivetrain"
              label="Bike Drivetrain"
              options={BIKE_DRIVETRAINS.map((speed) => ({ label: `${speed}`, value: speed })).concat({ label: 'Not sure', value: 0 })}
            />
          </Box>
        </Grid>
        <Grid item md={3}>
          <Box mb={2} px={2} width="100%">
            <WushTextField
              formik={formik}
              name="ftp"
              label="FTP (watts)"
              type="number"
              InputProps={{ inputProps: { min: 50, max: 500 } }}
              defaultValue={50}
            />
          </Box>
        </Grid>
      </Grid>
    </FormControl>
  </Box>
);

export default OrganizationPersonalInformationForm;
