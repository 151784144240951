import { Box, Grid } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import loader from './loader.gif';

const Loader = ({ message }) => (
  <Box textAlign="center">
    <Grid item md={12}>
      <Box textAlign="center">
        <img width={150} src={loader} alt="loader" />
      </Box>
    </Grid>
    <Grid item md={12}>
      <Box textAlign="center">
        <em>{message}</em>
      </Box>
    </Grid>
  </Box>
);

Loader.propTypes = {
  message: PropTypes.string,
};

export default Loader;
