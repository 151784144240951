import {
  Box,
} from '@mui/material';
import { useFormik } from 'formik';
import React from 'react';
import NextBackButton from '../components/NextBackButton';
import PersonalInformationForm from '../components/PersonalInformationForm';

const PersonalInformation = ({ nextStep, customerStore }) => {
  const formik = useFormik({
    initialValues: customerStore.customer,
    onSubmit: customerStore.updateCustomer,
  });

  const handleSaveAndNextStep = () => {
    customerStore.updateCustomer(formik.values, () => {
      nextStep();
    });
  };

  return (
    <Box pt={2} textAlign="center">
      <PersonalInformationForm formik={formik} />
      <NextBackButton nextStep={handleSaveAndNextStep} />
    </Box>
  );
};

export default PersonalInformation;
