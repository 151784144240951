/* eslint-disable */
import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import Login from './containers/login/Login';
import Register from './containers/login/Register';
import Dashboard from './containers/dashboard/Dashboard';
import Home from './containers/home/Home';
import UnauthenticatedRoute from './components/routes/UnauthenticatedRoute';
import AuthenticatedRoute from './components/routes/AuthenticatedRoute';
import HomeSidebar from './containers/header/HomeSidebar';
import Public from './containers/public/Public';
import Profile from './containers/profile/Profile';
import Account from './containers/account/Account';
import Organization from './containers/organization/Organization';
import Events from './containers/events/Events';
import MyEvents from './containers/events/MyEvents';
import Payments from './containers/payments/Payments';
import SetupAccount from './containers/account/SetupAccount';
import PrivacyPolicy from './containers/privacyPolicy/PrivacyPolicy';
import ViewEventSeries from './containers/events/ViewEventSeries';
import MyEventSeries from './containers/events/MyEventSeries';
import ResetPassword from './containers/login/ResetPassword';
import ChangePassword from './containers/profile/ChangePassword';
import PublicEvent from './containers/events/PublicEvent';
import Referrals from './containers/referrals/Referrals';
import EventCategory from './containers/iframe/containers/eventCategories/EventCategory';
import ViewWaiver from './containers/waivers/ViewWaiver';

const UnauthenticatedRoutes = {
  home: '/home',
};

const Routes = ({ childProps }) => console.log(childProps.authStore) || (
  <Switch>
    <UnauthenticatedRoute path="/" exact component={Public} props={childProps} />
    <UnauthenticatedRoute path="/privacy" exact component={PrivacyPolicy} props={childProps} />
    <UnauthenticatedRoute path="/login" exact component={Login} props={childProps} />
    <UnauthenticatedRoute path="/register" exact component={Register} props={childProps} />
    <UnauthenticatedRoute path="/reset-password" exact component={ResetPassword} props={childProps} />

    <UnauthenticatedRoute path="/public-events/:eventId" exact component={PublicEvent} props={childProps} />

    <UnauthenticatedRoute path="/waivers/:waiverUuid" exact component={ViewWaiver} props={childProps} />

    <AuthenticatedRoute path="/setup-account" exact component={SetupAccount} props={childProps} />
    <AuthenticatedRoute path="/home" exact component={Home} props={childProps} />
    <AuthenticatedRoute path="/change-password" exact component={ChangePassword} props={childProps} />
    <AuthenticatedRoute path="/profile" exact component={Profile} props={childProps} />
    <AuthenticatedRoute path="/referrals" exact component={Referrals} props={childProps} />
    <AuthenticatedRoute path="/payments" exact component={Payments} props={childProps} />
    <AuthenticatedRoute path="/account" exact component={Account} props={childProps} />
    <AuthenticatedRoute path="/events" exact component={Events} props={childProps} />
    <AuthenticatedRoute path="/my-event-series" exact component={MyEventSeries} props={childProps} />
    <AuthenticatedRoute path="/my-events" exact component={MyEvents} props={childProps} />
    <AuthenticatedRoute path="/event-series/:eventSeriesId" exact component={ViewEventSeries} props={childProps} />

    <AuthenticatedRoute path="/organization" exact component={Organization} props={childProps} />
    <UnauthenticatedRoute path="/dashboard" exact component={HomeRoute} props={{ component: <Dashboard />, ...childProps }} />

    <Redirect to="/home" />
  </Switch>
);

const HomeRoute = ({ component }) => (
  <Row className="flex-xl-nowrap">
    <Col xl="2" md="3" className="pt-5 sidebar-wrapper flex-column d-flex">
      <HomeSidebar />
    </Col>
    <Col md="10" className="pt-3">
      {component}
    </Col>
  </Row>
);

Routes.propTypes = {
  childProps: PropTypes.object,
};

export { UnauthenticatedRoutes };
export default Routes;
/* eslint-enable */
