import { observable, decorate } from 'mobx';
import config from '../config';
import RequestService from '../services/RequestService';
import BaseStore from './BaseStore';

class PropertyItemStore extends BaseStore {
  propertyItems = null;
  propertyItemsPreLoaded = false;

  addPropertyItem = ({itemId, propertyId}) => {
    if(this.propertyItems.filter((propertyItem) => propertyItem.itemId === parseInt(itemId) && parseInt(propertyItem.propertyId)).length) return false
    this.addRecord('property-items', {propertyItem: {itemId: itemId, propertyId: propertyId}}, this.propertyItems)
  }

  getPropertyItems = (propertyId, forceUpdate = false) => {
    (!this.propertyItemsPreLoaded || forceUpdate )&& RequestService.get(`${config.backendUrl}/properties/${propertyId}/property-items`, (response) => {
      response.data.sort((a,b) => { return a.id - b.id })
      this.propertyItems = response.data
      this.propertyItemsPreLoaded = true
    })
  }

  updatePropertyItem = (propertyItem) => {
    const updatePayload = {
      propertyItem: {
        [propertyItem.field]: propertyItem.value
      }
    }
    RequestService.post(`${config.backendUrl}/property-items/${propertyItem.id}`, updatePayload, (response) => {
      let updatedPropertyItems = this.propertyItems
      updatedPropertyItems[updatedPropertyItems.findIndex((iPropertyItem) => propertyItem.id === iPropertyItem.id)][propertyItem.field] = propertyItem.value
      this.propertyItems = updatedPropertyItems
      this.writeToSession()
    })
  }

  deletePropertyItem = (id) => {
    RequestService.delete(`${config.backendUrl}/property-items/${id}`, () => {
      let updatedPropertyItems = this.propertyItems
      updatedPropertyItems.splice(updatedPropertyItems.findIndex((propertyItem) => propertyItem.id === id), 1)
      this.propertyItems = updatedPropertyItems
    })
  }

  writeToSession = () => {
    const json = JSON.stringify(this);
    sessionStorage.setItem('PropertyItemStore', json);
  }
  
  buildFromSession = () => {
    const json = JSON.parse(sessionStorage.getItem('PropertyItemStore'))
    if (json) {
      this.propertyItems = json.propertyItems
      this.propertyItemsPreLoaded = json.propertyItemsPreLoaded
    }
  }
}


decorate(PropertyItemStore, {
  propertyItems: observable,
  propertyItemsPreLoaded: observable
});

export default PropertyItemStore; 