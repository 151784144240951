import React from 'react';
import { Container } from '@mui/material';
import { Route, Switch, useRouteMatch } from 'react-router';
import Scanner from './Scanner';
import ScanReservation from './ScanReservation';
import LeaderBoard from './containers/LeaderBoard';
import Archives from './containers/Archives';

const ScannerBase = (props) => {
  const match = useRouteMatch();
  return (
    <Container>
      <Switch>
        <Route path={`${match.path}/leader-board`}>
          <LeaderBoard {...props} />
        </Route>
        <Route path={`${match.path}/archives`}>
          <Archives {...props} />
        </Route>
        <Route path={`${match.path}/service-tickets`}>
          <Scanner {...props} serviceTickets />
        </Route>
        <Route path={`${match.path}/:reservationId`}>
          <ScanReservation {...props} />
        </Route>
        <Route path={match.path}>
          <Scanner {...props} />
        </Route>
      </Switch>
    </Container>
  );
};

export default ScannerBase;
