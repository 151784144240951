import React from 'react';
import {
  Button, CircularProgress, IconButton, Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { format } from 'date-fns';

import { useHistory } from 'react-router';
import { observer } from 'mobx-react';
import { DataGrid } from '@material-ui/data-grid';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  propertiesTableContainer: {
    height: '1000px',
  },
  propertiesContainer: {
    paddingTop: '2rem',
  },
  buttonContainer: {
    marginBottom: '1rem',
  },
  clientsCombo: {
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: '8px',
  },
  paper: {
    position: 'absolute',
    width: '75%',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: 'center',
  },
}));

const StorageFacilities = ({ storagesStore }) => {
  const history = useHistory();
  const classes = useStyles();
  storagesStore.getStorages();
  if (!storagesStore.storagesPreLoaded) return <CircularProgress />;
  const columns = [
    {
      field: 'property',
      headerName: '',
      width: 50,
      renderCell: (params) => (
        <IconButton
          size="small"
          onClick={() => {
            history.push(`/property-management/properties/${params.id}`);
          }}
        >
          <EditOutlinedIcon />
        </IconButton>
      ),
      editable: false,
      sortable: false,
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
      editable: true,
    },
    {
      field: 'address1',
      headerName: 'Address 1',
      width: 150,
      editable: true,
    },
    {
      field: 'address2',
      headerName: 'Address 2',
      width: 150,
      editable: true,
    },
    {
      field: 'city',
      headerName: 'City',
      width: 150,
      editable: true,
    },
    {
      field: 'state',
      headerName: 'State',
      width: 150,
      editable: true,
    },
    {
      field: 'zip',
      headerName: 'Zip',
      width: 150,
      editable: true,
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      width: 150,
      editable: false,
      type: 'date',
    },
    {
      field: 'Actions',
      headerName: '',
      width: 100,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="secondary"
          onClick={() => storagesStore.deleteProperty(params.id)}
        >
          Delete
        </Button>
      ),
      editable: false,
      sortable: false,
    },
  ];

  const storageRows = storagesStore.storages.map((storage) => ({
    storage,
    id: storage.id,
    name: storage.name,
    address1: storage.address1,
    address2: storage.address2,
    city: storage.city,
    state: storage.state,
    zip: storage.zip,
    createdAt: storage.createdAt && format(new Date(storage.createdAt), 'PP'),
  }));

  return (
    <div>
      <Typography variant="h3">Storage Facilities</Typography>
      <div className={classes.buttonContainer}>
        <Button
          variant="contained"
          color="primary"
          onClick={storagesStore.addStorage}
        >
          Add Property
        </Button>
      </div>
      <div className={classes.propertiesTableContainer}>
        <DataGrid
          rows={storageRows}
          columns={columns}
          pageSize={20}
          disableSelectionOnClick
          onCellEditCommit={storagesStore.updateStorage}
        />
      </div>
    </div>
  );
};

StorageFacilities.propTypes = {
  storagesStore: PropTypes.any,
};

export default observer(StorageFacilities);
