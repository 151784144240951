import {
  Box,
  Button, Container, LinearProgress, Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React from 'react';
import PropTypes from 'prop-types';
import { DataGrid } from '@material-ui/data-grid';

const useStyles = makeStyles(() => ({
  categoriesWrapper: {
    paddingTop: '2rem',
  },
  categoriesTableContainer: {
    height: '500px',
  },
  buttonContainer: {
    marginBottom: '1rem',
  },
}));

const Categories = ({ itemCategoryStore }) => {
  const classes = useStyles();
  itemCategoryStore.getItemCategories();

  if (!itemCategoryStore.itemCategoriesPreLoaded) return (<LinearProgress />);

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
      editable: true,
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 500,
      editable: true,
    },
    {
      field: 'Actions',
      headerName: '',
      width: 100,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="secondary"
          onClick={() => itemCategoryStore.deleteItemCategory(params.id)}
        >
          Delete
        </Button>
      ),
      editable: false,
      sortable: false,
    },
  ];
  const categoriesRows = itemCategoryStore.itemCategories.map((itemCategory) => (
    {
      id: itemCategory.id,
      name: itemCategory.name,
      description: itemCategory.description,
    }
  ));

  return (
    <Container>
      <Typography variant="h3">Item Categories</Typography>
      <Box className={classes.buttonContainer}>
        <Button
          variant="contained"
          color="primary"
          onClick={itemCategoryStore.addItemCategory}
        >
          Add Item Category
        </Button>
      </Box>
      <Box className={classes.categoriesTableContainer}>
        <DataGrid
          rows={categoriesRows}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          onCellEditCommit={itemCategoryStore.updateItemCategory}
        />
      </Box>
    </Container>
  );
};

Categories.propTypes = {
  itemCategoryStore: PropTypes.any,
};

export default observer(Categories);
