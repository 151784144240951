import { observable, decorate } from 'mobx';
import config from '../config';
import RequestService from '../services/RequestService';
import BaseStore from './BaseStore';

class BagItemStore extends BaseStore {
  bagItems = null;
  bagItemsPreLoaded = false;

  addBagItem = ({itemId, bagId}) => {
    if(this.bagItems.filter((bagItem) => bagItem.itemId === parseInt(itemId) && parseInt(bagItem.bagId)).length) return false
    this.addRecord('bag-items', {bagItem: {itemId: itemId, bagId: bagId}}, this.bagItems)
  }

  getBagItems = (bagId) => {
    !this.bagItemsPreLoaded && RequestService.get(`${config.backendUrl}/bags/${bagId}/bag-items`, (response) => {
      response.data.sort((a,b) => { return a.id - b.id })
      this.bagItems = response.data
      this.bagItemsPreLoaded = true
    })
  }

  updateBagItem = (bagItem, callback) => {
    const updatePayload = {
      bagItem: {
        quantity: bagItem.value
      }
    }
    RequestService.post(`${config.backendUrl}/bag-items/${bagItem.id}`, updatePayload, (response) => {
      callback && callback()
      this.writeToSession()
    })
  }

  deleteBagItem = (id) => {
    RequestService.delete(`${config.backendUrl}/bag-items/${id}`, () => {
      let updatedBagItems = this.bagItems
      updatedBagItems.splice(updatedBagItems.findIndex((bagItem) => bagItem.id === id), 1)
      this.bagItems = updatedBagItems
    })
  }

  generateBags = (id) => {
    RequestService.post(`${config.backendUrl}/bag-items/${id}/generate`, () => {
      this.getBagItems()
    })
  }

  writeToSession = () => {
    const json = JSON.stringify(this);
    sessionStorage.setItem('BagItemStore', json);
  }
  
  buildFromSession = () => {
    const json = JSON.parse(sessionStorage.getItem('BagItemStore'))
    if (json) {
      this.bagItems = json.bagItems
      this.bagItemsPreLoaded = json.bagItemsPreLoaded
    }
  }
}

decorate(BagItemStore, {
  bagItems: observable,
  bagItemsPreLoaded: observable
});

export default BagItemStore;
