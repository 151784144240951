import { observable, decorate, action } from 'mobx';
import config from '../config';
import RequestService from '../services/RequestService';
import BaseStore from './BaseStore';

class CustomerStore extends BaseStore {
  constructor() {
    super()
    this.buildFromSession();
  }

  customer = null;
  customerPreLoaded = false;

  getCustomer = () => {
    !this.customerPreLoaded && RequestService.get(`${config.backendUrl}/public/customers/me`, (response) => {
      this.customer = response.data
      this.customerPreLoaded = true
      this.writeToSession()
    })
  }

  updateStoredCustomer = (field, value) => {
    let updateCustomer = this.customer;
    updateCustomer[field] = value;
    this.customer = updateCustomer;
  }

  joinOrganization = (organization) => {
    let updated = this.customer
    updated.organization = organization
    this.customer = updated
    this.writeToSession()
  }

  updateCustomer = (customer, callback) => {
    const updatePayload = {
      customer: customer
    }
    RequestService.patch(`${config.backendUrl}/public/customers/me`, updatePayload, (response) => {
      this.customer = response.data;
      this.writeToSession()
      callback && callback();
    })
  }

  writeToSession = () => {
    const json = JSON.stringify(this);
    sessionStorage.setItem('CustomerStore', json);
  }
  
  buildFromSession = () => {
    const json = JSON.parse(sessionStorage.getItem('CustomerStore'))
    if (json) {
      this.customer = json.customer
      this.customerPreLoaded = json.customerPreLoaded
    }
  }
}

decorate(CustomerStore, {
  customer: observable,
  customerPreLoaded: observable,
  updateOrganization: action
});

export default CustomerStore;