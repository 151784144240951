import { Box, Grid, Typography } from '@mui/material';
import { format } from 'date-fns';
import React from 'react';
import NextBackButton from './NextBackButton';

const EventInformation = ({ event, nextStep }) => (
  <Box mb={5} pl="2rem" pr="2rem">
    <Box>
      <Grid container>
        <Grid item md={12} width="100%">
          <Box>
            <Typography variant="h4" my={2}>
              <strong>{event.name}</strong>
            </Typography>
            <Typography variant="h6" mb={1}>
              {format(new Date(event.startDatetime), "LLLL do, y 'at' h:mm b")}
            </Typography>
            <Typography variant="h6" mb={1}>
              {event.address1}
            </Typography>
            <Typography variant="body1" mb={5} textAlign="left">
              {/* eslint-disable-next-line react/no-danger */}
              <div dangerouslySetInnerHTML={{ __html: event.description }} />
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
    <NextBackButton nextStep={nextStep} />
  </Box>
);

export default EventInformation;
