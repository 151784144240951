import React from 'react';
import DomainIcon from '@mui/icons-material/Domain';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import StoreIcon from '@mui/icons-material/Store';
import Clients from './containers/clients/Clients';
import Properties from './containers/properties/Properties';
import StorageFacilities from './containers/storageFacilities/StorageFacilities';

export const PAGES = {
  PROPERTIES: {
    title: "Properties",
    baseRoute: "properties",
    icon: <DomainIcon />,
    component: Properties
  },
  CLIENTS: {
    title: "Clients",
    baseRoute: "clients",
    icon: <TransferWithinAStationIcon />,
    component: Clients
  },
  STORAGE: {
    title: "Storage Facilities",
    baseRoute: "storage-facilities",
    icon: <StoreIcon />,
    component: StorageFacilities
  }
}
