import { Box, FormControl, Grid } from '@mui/material';
import React from 'react';
import WushTextField from '../../../components/WushTextField';

const PersonalInformationForm = ({ formik }) => (
  <Box>
    <FormControl fullWidth>
      <Grid container>
        <Grid item md={6}>
          <Box mb={2} px={2} width="100%">
            <WushTextField formik={formik} name="firstName" label="First Name" />
          </Box>
        </Grid>
        <Grid item md={6}>
          <Box mb={2} px={2} width="100%">
            <WushTextField formik={formik} name="lastName" label="Last Name" />
          </Box>
        </Grid>
        <Grid item md={6}>
          <Box mb={2} px={2} width="100%">
            <WushTextField formik={formik} name="address1" label="Address Line One" />
          </Box>
        </Grid>
        <Grid item md={6}>
          <Box mb={2} px={2} width="100%">
            <WushTextField formik={formik} name="address2" label="Address Line Two" />
          </Box>
        </Grid>
        <Grid item md={4}>
          <Box mb={2} px={2} width="100%">
            <WushTextField formik={formik} name="city" label="City" />
          </Box>
        </Grid>
        <Grid item md={4}>
          <Box mb={2} px={2} width="100%">
            <WushTextField formik={formik} name="state" label="State" />
          </Box>
        </Grid>
        <Grid item md={4}>
          <Box mb={2} px={2} width="100%">
            <WushTextField formik={formik} name="zip" label="Zip Code" />
          </Box>
        </Grid>
      </Grid>
    </FormControl>
  </Box>
);

export default PersonalInformationForm;
