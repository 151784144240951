import {
  Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from '@mui/material';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import ReservationRow from './ReservationRow';

const ReservationTable = ({
  reservations, scannerStore, selectRow, allowScanning = true, allowProcessing = false,
}) => (
  <TableContainer component={Paper}>
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>Property</TableCell>
          <TableCell />
          <TableCell>Date</TableCell>
          <TableCell align="right">Bags</TableCell>
          <TableCell align="right">Total Items</TableCell>
          <TableCell align="right">Scanned Items</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {
            reservations.map((reservation) => (
              <ReservationRow
                reservation={reservation}
                scannerStore={scannerStore}
                key={reservation.id}
                allowScanning={allowScanning}
                selectRow={selectRow}
                allowProcessing={allowProcessing}
              />
            ))
          }
      </TableBody>
    </Table>
  </TableContainer>
);

ReservationTable.propTypes = {
  reservations: PropTypes.array,
  scannerStore: PropTypes.any,
  allowScanning: PropTypes.bool,
  selectRow: PropTypes.func,
  allowProcessing: PropTypes.bool,
};

export default observer(ReservationTable);
