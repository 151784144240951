import {
  Alert, Box, Button, CircularProgress, Stack, TextField, Typography,
} from '@mui/material';
import React from 'react';
import CreditPayment from './CreditCardPayment';
import RequestService from '../../../services/RequestService';
import config from '../../../config';

const EventSeriesCheckout = ({
  eventSeries, selectedEvents, eventCount, handleSuccess, slotCount,
}) => {
  const [cost, setCost] = React.useState(null);
  const [promoCode, setPromoCode] = React.useState(null);
  const [promoCodeSuccess, setPromoCodeSuccess] = React.useState(null);

  const getEventSeriesCost = () => {
    RequestService.post(`${config.backendUrl}/public/event_series/${eventSeries.id}/cost`, { events: selectedEvents.length, eventCount }, (response) => {
      setCost(response.data.cost * slotCount);
    });
  };

  const checkPromoCode = () => {
    if (!promoCodeSuccess) {
      RequestService.post(`${config.backendUrl}/public/promo_codes/check`, {
        promoCode: {
          code: promoCode,
        },
      }, (response) => {
        setPromoCodeSuccess(true);
        setCost(cost - response.data.discountAmount);
      }, () => {
        setPromoCodeSuccess(false);
        getEventSeriesCost();
      });
    }
  };

  React.useEffect(getEventSeriesCost, []);

  if (!cost) return <CircularProgress />;

  return (
    <Box>
      <Typography variant="h5">{eventSeries.name}</Typography>
      <Typography variant="h6">
        {eventCount}
        {' '}
        event package:
        {' '}
        $
        {cost}
      </Typography>
      <Box marginY={2}>
        <Stack direction="row" spacing={2} marginBottom={1}>
          <TextField label="Promo Code" onChange={(e) => setPromoCode(e.target.value)} />
          <Button disabled={promoCodeSuccess} onClick={checkPromoCode} variant="contained">Apply</Button>
        </Stack>
        {promoCodeSuccess !== null && (
        <Stack>
          <Alert severity={promoCodeSuccess ? 'success' : 'warning'}>
            { promoCodeSuccess ? 'Promo code applied!' : 'Invalid promo code!' }
          </Alert>
        </Stack>
        )}
      </Box>
      <CreditPayment
        amount={cost * 100}
        promoCode={promoCode}
        description={`Event Series: ${eventSeries.name} ID: ${eventSeries.id}`}
        handleSuccess={handleSuccess}
      />
    </Box>
  );
};

export default EventSeriesCheckout;
