import React, { useState } from 'react';
import {
  Autocomplete, Box, TextField, Typography,
} from '@mui/material';
import NextBackButton from '../components/NextBackButton';

const SelectOrganization = ({
  previousStep, nextStep, organizationStore, customerStore,
}) => {
  const [selectedOrganization, setSelectedOrganization] = useState(
    customerStore.customer.organization,
  );

  const handleSaveAndNextStep = () => {
    organizationStore.joinOrganization(selectedOrganization.id, () => {
      customerStore.joinOrganization(selectedOrganization);
      nextStep();
    });
  };
  return (
    <Box pt={2}>
      <Box mb={2}>
        {
          customerStore.customer.organization
            ? (
              <Box textAlign="center" mb={3}>
                <Typography variant="h5">
                  Your enrolled at
                  {' '}
                  {customerStore.customer.organization.name}
                </Typography>
              </Box>
            )
            : (
              <Box mb={3}>
                <Autocomplete
                  getOptionLabel={(option) => option.name || 'N/A'}
                  options={organizationStore.organizations}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search organization"
                      InputProps={{
                        ...params.InputProps,
                        type: 'search',
                      }}
                    />
                  )}
                  onChange={(e, value) => setSelectedOrganization(value)}
                />
              </Box>
            )
        }

      </Box>
      <NextBackButton previousStep={previousStep} nextStep={handleSaveAndNextStep} />
    </Box>
  );
};

export default SelectOrganization;
