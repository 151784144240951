import { observable, decorate, action } from 'mobx';
import config from '../config';
import RequestService from '../services/RequestService';
import BaseStore from './BaseStore';

class StoragesStore extends BaseStore {
  constructor() {
    super()
    this.buildFromSession();
  }

  storages = null;
  storagesPreLoaded = false;

  getStorages = (forceUpdate = false, callback) => {
    (!this.storagesPreLoaded || forceUpdate) && RequestService.get(`${config.backendUrl}/storages`, (response) => {
      response.data.sort((a,b) => { return a.id - b.id })
      this.storages = response.data
      this.storagesPreLoaded = true
      this.writeToSession()
      callback && callback()
    })
  }

  addStorage = () => {
    this.addRecord('storages', {storage: {name: 'placeholder'}}, this.storages)
  }

  updateStorage = (storage) => {
    const updatePayload = {
      storage: {
        [storage.field]: storage.value
      }
    }
    RequestService.post(`${config.backendUrl}/storages/${storage.id}`, updatePayload, (response) => {
      console.log(response.data)
      this.selectedStorage = response.data
      let updatedStorages = this.storages;
      updatedStorages.splice(updatedStorages.findIndex((s) => s.id === storage.id), 1, response.data)
      this.storages = updatedStorages
      console.log(response.data)
      this.writeToSession()
    })
  }

  writeToSession = () => {
    const json = JSON.stringify(this);
    sessionStorage.setItem('StoragesStore', json);
  }
  
  buildFromSession = () => {
    const json = JSON.parse(sessionStorage.getItem('StoragesStore'))
    if (json) {
      this.properties = json.properties
      this.propertiesPreLoaded = json.propertiesPreLoaded
      this.selectedProperty = json.selectedProperty
    }
  }
}

decorate(StoragesStore, {
  getStorages: action,
  storages: observable,
  storagesPreLoaded: observable
});

export default StoragesStore;