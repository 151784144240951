import {
  Box, Divider, Grid, Step, StepLabel, Stepper,
} from '@mui/material';
import { Formik } from 'formik';
import React from 'react';
import StepWizard from 'react-step-wizard';
import * as Yup from 'yup';
import CustomerInformationV2 from './CustomerInformationV2';
import EventInformation from './EventInformation';
import EventInfoSnippet from './EventInfoSnippet';
import ItemSelection from './ItemSelection';
import OrderSummary from './OrderSummary';
import PublicCart from './PublicCart';

const steps = [
  'Event Info',
  'Customer Info',
  'Item Info',
  'Summary',
];

const skipRegistrationSteps = ['Item Info', 'Customer Info', 'Summary'];

const EventRegistrationSchema = Yup.object().shape({
  email: Yup.string().required('Required'),
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  participants: Yup.array().required('Must enter a participant').of(
    Yup.object().shape({
      first_name: Yup.string().required('Required'),
      item_id: Yup.string().required('Required'),
      date_of_birth: Yup.string().required('Required'),
    }),
  ),
});

const Navigator = ({ currentStep, skipRegistration }) => (
  <Box sx={{ width: '100%' }} mb="1rem" mt="2rem">
    <Stepper activeStep={currentStep} alternativeLabel>
      {(skipRegistration ? skipRegistrationSteps : steps).map((label) => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  </Box>
);

const PublicEventRegistration = ({
  event,
  handleSuccess,
  handleCancel,
  authStore,
  alertStore,
  baseStore,
  skipRegistration,
}) => (
  <Box pl={2} pr={2} className="public-event-registration">
    <Formik
      initialValues={{
        cart: [],
        quantity: 0,
        participants: [],
        email: '',
        password: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        dateOfBirth: null,
        customerIsParticipating: !skipRegistration,
      }}
      validationSchema={EventRegistrationSchema}
    >
      {
        (formik) => (
          <Grid container>
            <Grid item md={8} width="100%" textAlign="center" className="event-registration-checkout">
              <StepWizard
                nav={<Navigator skipRegistration={skipRegistration} />}
                isLazyMount
              >
                {!skipRegistration && <EventInformation event={event} formik={formik} />}
                <CustomerInformationV2
                  event={event}
                  formik={formik}
                  alertStore={alertStore}
                  authStore={authStore}
                  baseStore={baseStore}
                />
                {event.items.length > 0 && (
                <ItemSelection
                  event={event}
                  formik={formik}
                  skipRegistration={skipRegistration}
                  alertStore={alertStore}
                />
                )}
                <OrderSummary
                  event={event}
                  formik={formik}
                  handleSuccess={handleSuccess}
                  handleCancel={handleCancel}
                  alertStore={alertStore}
                />
              </StepWizard>
            </Grid>
            {window.innerWidth <= 768 && (
            <Grid item md={12} width="100%" textAlign="center">
              <Box my={2}>
                <Divider />
              </Box>
            </Grid>
            )}
            <Grid item md={4} width="100%" textAlign="center">
              <Box p={2}>
                <EventInfoSnippet event={event} />
                <PublicCart event={event} quantity={formik.values.quantity} formik={formik} />
              </Box>
            </Grid>
          </Grid>
        )
      }
    </Formik>
  </Box>
);

export default PublicEventRegistration;
