import {
  Box, Button, CircularProgress, Divider, TextField, Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';

import { useHistory } from 'react-router';
import WushCard from '../../components/WushCard';
import WushModal from '../../components/WushModal';

const ResetPassword = ({ authStore }) => {
  const history = useHistory();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <Box pt={10} textAlign="center" width="100%">
      <Box maxWidth="md" mr="auto" ml="auto">
        <WushCard>
          <Box p={5}>
            <Box mb={2}>
              <Typography variant="h4">Reset Password</Typography>
            </Box>
            <Formik
              initialValues={{ email: '' }}
              onSubmit={(values) => {
                setLoading(true);
                authStore.resetPassword(
                  values.email,
                  () => { setLoading(false); setShowSuccessModal(true); },
                );
              }}
            >
              {
                ({ values, handleChange, handleSubmit }) => (
                  <Form>
                    <Box>
                      <Box mb={2}>
                        <TextField
                          fullWidth
                          required
                          id="email"
                          label="Email"
                          type="email"
                          autoComplete="current-email"
                          variant="outlined"
                          onChange={handleChange}
                          value={values.email}
                        />
                      </Box>
                      <Box mb={2} textAlign="center">
                        {loading ? <CircularProgress />
                          : (
                            <Button variant="contained" fullWidth onClick={handleSubmit}>
                              Reset Password
                            </Button>
                          )}
                      </Box>
                      <Box mb={2}>
                        <Divider />
                      </Box>
                      <Box>
                        <Button variant="outlined" fullWidth onClick={() => history.push('/login')}>
                          Back to Login
                        </Button>
                      </Box>
                    </Box>
                  </Form>
                )
              }
            </Formik>
          </Box>
        </WushCard>
        <WushModal
          open={showSuccessModal}
          onClose={() => setShowSuccessModal(false)}
        >
          <Box>
            <Typography variant="body1">
              You will receive an email with a temporary password if your email is in our records.
            </Typography>
            <Box pt={5}>
              <Button onClick={() => history.push('/login')}>Back to Login</Button>
            </Box>
          </Box>
        </WushModal>
      </Box>
    </Box>
  );
};

export default ResetPassword;
