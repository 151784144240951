import { Box, CircularProgress, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import config from '../../../../config';
import RequestService from '../../../../services/RequestService';

const CurrentPropertyItems = ({ property }) => {
  const [propertyId, setPropertyId] = React.useState(null);
  const [currentItems, setCurrentItems] = React.useState(null);
  useEffect(() => {
    // eslint-disable-next-line
    if (!currentItems && property || (property && property.id !== propertyId)) {
      RequestService.get(`${config.backendUrl}/properties/${property.id}/current-items`, (response) => {
        setCurrentItems(response.data);
        setPropertyId(property.id);
      });
    }
  });
  if (!property) return <Typography variant="span">-</Typography>;
  if (!currentItems) return <CircularProgress />;
  if (currentItems.length === 0) return <Typography variant="span">-</Typography>;
  return (
    <Box>
      {currentItems.map((currentItem) => (
        <Box>
          <Typography>
            {currentItem.name}
            {' '}
            x
            {currentItem.quantity}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default CurrentPropertyItems;
