import React from 'react';
import GroupIcon from '@mui/icons-material/Group';
import { Box, Stack } from '@mui/material';

const EventCapacityStatus = ({ event }) => (
  <Box>
    <Stack direction="horizontal">
      <GroupIcon />
      {event.registrationsCount}
      {' '}
      /
      {' '}
      {event.capacity}
    </Stack>
  </Box>
);

export default EventCapacityStatus;
