import {
  Avatar, Box, CircularProgress, Typography, Grid, Table, TableHead,
  TableRow, TableCell, TableBody, ButtonGroup, Button,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '2rem',
  },
  first: {
    width: theme.spacing(30),
    height: theme.spacing(30),
    backgroundColor: 'gold',
  },
  second: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    backgroundColor: '#C0C0C0',
  },
  third: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    backgroundColor: '#CD7F32',
  },
  centered: {
    marginRight: 'auto',
    marginLeft: 'auto',
  },
}));

const LeaderBoard = ({ leaderBoardStore }) => {
  const classes = useStyles();
  const [timespan, setTimespan] = useState('day');
  const getLeaderBoard = (newTimespan, forceUpdate) => {
    leaderBoardStore.getLeaderBoard(newTimespan, forceUpdate);
    if (forceUpdate) setTimespan(newTimespan);
  };
  getLeaderBoard('day', false);
  if (!leaderBoardStore.leaderBoardPreLoaded) return <CircularProgress />;
  return (
    <Box className={classes.root}>
      {leaderBoardStore.leaderBoard[0] && (
      <Grid container>
        <Grid cell md={12}>
          <Avatar className={[classes.first, classes.centered]}>
            {leaderBoardStore.leaderBoard[0].firstName[0]}
            {leaderBoardStore.leaderBoard[0].lastName[0]}
          </Avatar>
          <Box textAlign="center">
            <Typography variant="h4" className={classes.centered}>
              {`${leaderBoardStore.leaderBoard[0].firstName} ${leaderBoardStore.leaderBoard[0].lastName}`}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      )}
      <Grid container>
        {leaderBoardStore.leaderBoard[1] && (
        <Grid cell md={6} className={classes.leaders}>
          <Avatar className={[classes.second, classes.centered]}>
            {leaderBoardStore.leaderBoard[1].firstName[0]}
            {leaderBoardStore.leaderBoard[1].lastName[0]}
          </Avatar>
          <Box textAlign="center">
            <Typography variant="h5" className={classes.centered}>
              {`${leaderBoardStore.leaderBoard[1].firstName} ${leaderBoardStore.leaderBoard[1].lastName}`}
            </Typography>
          </Box>
        </Grid>
        )}
        {leaderBoardStore.leaderBoard[2] && (
        <Grid cell md={6}>
          <Avatar className={[classes.third, classes.centered]}>
            {leaderBoardStore.leaderBoard[2].firstName[0]}
            {leaderBoardStore.leaderBoard[2].lastName[0]}
          </Avatar>
          <Box textAlign="center">
            <Typography variant="h5" className={classes.centered}>
              {`${leaderBoardStore.leaderBoard[2].firstName} ${leaderBoardStore.leaderBoard[2].lastName}`}
            </Typography>
          </Box>
        </Grid>
        )}
      </Grid>
      <Box textAlign="center" mt={5}>
        <Typography variant="h3">Leaderboards</Typography>
      </Box>
      <ButtonGroup color="primary" aria-label="outlined primary button group">
        <Button
          variant={timespan === 'day' ? 'contained' : 'outlined'}
          onClick={() => getLeaderBoard('day', true)}
        >
          Day
        </Button>
        <Button
          variant={timespan === 'week' ? 'contained' : 'outlined'}
          onClick={() => getLeaderBoard('week', true)}
        >
          Week
        </Button>
        <Button
          variant={timespan === 'month' ? 'contained' : 'outlined'}
          onClick={() => getLeaderBoard('month', true)}
        >
          Month
        </Button>
      </ButtonGroup>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              Name
            </TableCell>
            <TableCell>
              Scanned Tickets
            </TableCell>
            <TableCell>
              Scanned Items
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {leaderBoardStore.leaderBoard.map((employee) => (
            <TableRow>
              <TableCell>
                {employee.firstName}
              </TableCell>
              <TableCell>
                {employee.scannedTickets}
              </TableCell>
              <TableCell>
                {employee.scannedItems}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

LeaderBoard.propTypes = {
  leaderBoardStore: PropTypes.any,
};

export default observer(LeaderBoard);
