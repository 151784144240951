import { Divider, Typography, Box } from '@mui/material';
import React from 'react';
import WushCard from '../../../../components/WushCard';

const CustomerInformation = ({ customer }) => (
  <Box>
    <Typography variant="body1">
      {`${customer.firstName} ${customer.lastName}`}
    </Typography>
    <Typography variant="body1">
      {customer.address1}
    </Typography>
    <Typography variant="body1">
      {`${customer.city}, ${customer.state} ${customer.zip}`}
    </Typography>
    <Typography variant="body1">
      {customer.phone}
    </Typography>
    <Typography variant="body1">
      {customer.email}
    </Typography>
  </Box>
);

const SelectedCustomer = ({ customer }) => (
  <WushCard>
    <Box mb="0.5rem">
      <Typography variant="h6">
        Customer Information
      </Typography>
    </Box>
    <Divider />
    {customer ? (
      <Box p="1rem">
        <CustomerInformation customer={customer} />
      </Box>
    )
      : (
        <Box p="1rem">
          Please select a customer
        </Box>
      )}
  </WushCard>
);

export default SelectedCustomer;
export { CustomerInformation };
