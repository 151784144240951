import {
  Box, Divider, Grid, Typography, Button, FormLabel,
} from '@mui/material';
import { DateRangePicker } from 'materialui-daterange-picker';
import { Field } from 'formik';
import { observer } from 'mobx-react';
import React from 'react';
import { calculateItemCost, calculateReservationDays } from './CartItem';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '66%',
  height: '66%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const EditItemForm = ({
  addingItem, selectedItems, toggle, open, handleDateRangeChange, removeItem, setAddItemModalOpen,
}) => {
  const addedItemIndex = selectedItems.findIndex((item) => item.id === addingItem.id);
  return (
    <Box sx={modalStyle}>
      <Grid container>
        <Grid item md={4}>
          <Box p="1rem">
            <Typography variant="h5">Item</Typography>
            <Divider />
            <Grid container>
              <Grid item md={6}>
                <Box p="1rem">
                  <img src="https://via.placeholder.com/100.png/09f/fff" alt="placeholder" />
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box p="1rem">
                  <Typography variant="p">{addingItem?.name}</Typography>
                  <Field
                    type="number"
                    style={{ width: '50%' }}
                    name={`selectedItems.${addedItemIndex}.selectedQuantity`}
                  />
                </Box>
              </Grid>
            </Grid>
            <Typography variant="p">{addingItem?.description}</Typography>
          </Box>
        </Grid>
        <Grid item md={4}>
          <Box p="1rem">
            <Typography variant="h5">Rental Dates</Typography>
            <Divider />
            {selectedItems[addedItemIndex]?.dateRange
              && `${selectedItems[addedItemIndex]?.dateRange?.startDate} to 
              ${selectedItems[addedItemIndex]?.dateRange?.endDate}`}
            <Button onClick={toggle}>Select Dates</Button>
            <DateRangePicker
              open={open}
              toggle={toggle}
              onChange={(range) => handleDateRangeChange(range)}
              initialDateRange={
                selectedItems[addedItemIndex]?.dateRange
              }
            />
          </Box>
          <Box p="1rem">
            <Typography variant="span">
              <b>Days: </b>
              {calculateReservationDays(selectedItems[addedItemIndex])}
            </Typography>
          </Box>
          <Box p="1rem">
            <Typography variant="span">
              <b>Item Cost: </b>
              $
              {calculateItemCost(selectedItems[addedItemIndex])}
            </Typography>
          </Box>
        </Grid>
        <Grid item md={4}>
          <Box p="1rem">
            <Typography variant="h5">Receiving Method</Typography>
            <Divider />
            <Box pt="0.5rem" role="group" aria-labelledby="my-radio-group">
              <Box>
                <FormLabel>
                  <Field
                    type="radio"
                    name={`selectedItems.${addedItemIndex}.receivingMethod`}
                    value="customer"
                  />
                  Pickup In-Store
                </FormLabel>
              </Box>
              <Box>
                <FormLabel>
                  <Field
                    type="radio"
                    name={`selectedItems.${addedItemIndex}.receivingMethod`}
                    value="company"
                  />
                  Delivery
                </FormLabel>
              </Box>
            </Box>
          </Box>
          <Box p="1rem">
            <Typography variant="h5">Returning Method</Typography>
            <Divider />
            <Box pt="0.5rem" role="group" aria-labelledby="my-radio-group">
              <Box>
                <FormLabel>
                  <Field
                    type="radio"
                    name={`selectedItems.${addedItemIndex}.returningMethod`}
                    value="customer"
                  />
                  Return to store
                </FormLabel>
              </Box>
              <Box>
                <FormLabel>
                  <Field
                    type="radio"
                    name={`selectedItems.${addedItemIndex}.returningMethod`}
                    value="company"
                  />
                  Pickup
                </FormLabel>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid position="fixed" container bottom="25px" right="25px">
        <Box width="100%" textAlign="right">
          <Button
            onClick={() => {
              removeItem(addedItemIndex);
              setAddItemModalOpen(false);
            }}
            color="secondary"
          >
            Delete
          </Button>
          <Button
            variant="contained"
            onClick={() => setAddItemModalOpen(false)}
          >
            Save
          </Button>
        </Box>
      </Grid>
    </Box>
  );
};

export default observer(EditItemForm);
