import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {
  Box, CircularProgress, Container,
} from '@mui/material';
import { observer } from 'mobx-react';
import Routes from './Routes';
import './App.css';
import BaseStore from './stores/BaseStore';
import AuthStore from './stores/AuthStore';
import HealthStore from './stores/HealthStore';
import EmployeeStore from './stores/EmployeeStore';
import ItemCategoryStore from './stores/ItemCategoryStore';
import ItemStore from './stores/ItemStore';
import KitStore from './stores/KitStore';
import CustomerStore from './stores/CustomerStore';
import PropertyStore from './stores/PropertyStore';
import ClientStore from './stores/ClientStore';
import BagStore from './stores/BagStore';
import PropertyItemStore from './stores/PropertyItemStore';
import BagItemStore from './stores/BagItemStore';
import ScannerStore from './stores/ScannerStore';
import ReservationStore from './stores/ReservationStore';
import LeaderBoardStore from './stores/LeaderBoardStore';
import SearchStore from './stores/SearchStore';
import StoragesStore from './stores/StoragesStore';
import EventCategoryStore from './stores/EventCategoryStore';
import OrganizationStore from './stores/OrganizationStore';
import Header from './containers/header/Header';
import AlertStore from './stores/AlertStore';
import Footer from './containers/footer/Footer';
import WushAlert from './components/WushAlert';
import DomainStore from './stores/DomainStore';
import RequestService from './services/RequestService';
import config from './config';

import AppDisabled from './containers/error/AppDisabled';
import IframeRoutes from './IframeRoutes';

const App = () => {
  const [appEnabled, setAppEnabled] = useState(true);

  const checkAppEnabled = () => {
    RequestService.get(`${config.backendUrl}/public-app-enabled`, (response) => {
      if (!response.data.enabled) {
        setAppEnabled(false);
      }
    });
  };

  useEffect(checkAppEnabled, []);

  if (!appEnabled) return <AppDisabled />;

  const domainStore = new DomainStore();
  const organizationDomain = window.location.hostname.split('.')[0];

  const iframeApp = window.location.pathname.split('/')[1] === 'iframe';

  domainStore.getOrganizationByDomain(organizationDomain);
  if (!domainStore.organizationPreLoaded) {
    return (
      <Box textAlign="center" mt={10}>
        <CircularProgress />
      </Box>
    );
  }

  const theme = createTheme({
    palette: {
      primary: {
        light: domainStore.organization.primaryColor || '#0c6b74',
        main: domainStore.organization.primaryColor || '#0c6b74',
        dark: '#0c6b74',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ff7961',
        main: '#f44336',
        dark: '#ba000d',
        contrastText: '#fff',
      },
      background: {
        paper: '#ffffff',
      },
    },
  });

  const childProps = {
    user: 'Nik',
    baseStore: new BaseStore(),
    authStore: new AuthStore(),
    healthStore: new HealthStore(),
    employeeStore: new EmployeeStore(),
    itemCategoryStore: new ItemCategoryStore(),
    itemStore: new ItemStore(),
    kitStore: new KitStore(),
    customerStore: new CustomerStore(),
    propertyStore: new PropertyStore(),
    clientStore: new ClientStore(),
    bagStore: new BagStore(),
    bagItemStore: new BagItemStore(),
    propertyItemStore: new PropertyItemStore(),
    scannerStore: new ScannerStore(),
    reservationStore: new ReservationStore(),
    leaderBoardStore: new LeaderBoardStore(),
    searchStore: new SearchStore(),
    storagesStore: new StoragesStore(),
    eventCategoryStore: new EventCategoryStore(),
    organizationStore: new OrganizationStore(),
    alertStore: new AlertStore(),
    domainStore,
  };

  return (
    <ThemeProvider theme={theme}>
      { iframeApp
        ? (
          <div className="IframeApp">
            <IframeRoutes childProps={childProps} />
          </div>
        )
        : (
          <div className="App pb-5 mb-5">
            <Header {...childProps} />
            <Container component="main">
              <WushAlert alertStore={childProps.alertStore} />
              <Box pt={8}>
                <Routes childProps={childProps} />
              </Box>
            </Container>
            <Footer />
          </div>
        )}

    </ThemeProvider>
  );
};

export default withRouter(observer(App));
