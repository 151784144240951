import React from 'react';
import { Field, FieldArray, Formik } from 'formik';
import {
  Button, FormLabel, Grid, InputAdornment, TextField, Autocomplete,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import KitItemsTable from './KitItemsTable';
import KitItemsAutocomplete from './KitItemsAutocomplete';

const useStyles = makeStyles(() => ({
  itemsCombo: {
    maxWidth: '15rem',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  rateInput: {
    maxWidth: '10rem',
    marginRight: '.5rem',
  },
  descriptionContainer: {
    marginBottom: '2rem',
  },
  customItemPriceCheckbox: {
    margin: '.25rem',
  },
  addItemsWrapper: {
    marginTop: '5rem',
  },
}));

const KitForm = ({
  editingKit, afterSave, onCancel, itemStore, kitStore, itemCategoryStore,
}) => {
  const classes = useStyles();
  return (
    <Formik
      initialValues={editingKit}
    >
      {
        ({ values, handleChange, setFieldValue }) => (
          <Grid container>
            <Grid className={classes.descriptionContainer}>
              <h2 data-testid="kitName">
                <span>Editing: </span>
                {values.name}
              </h2>
              <h4>{values.description}</h4>
            </Grid>
            <Grid md={6}>
              <div>
                <Field className={classes.customItemPriceCheckbox} name="customItemPrice" type="checkbox" />
                <FormLabel>
                  {' Use custom item price'}
                </FormLabel>
              </div>
              <div>
                <Autocomplete
                  id="kit-category-combo-box"
                  options={itemCategoryStore.itemCategories}
                  getOptionLabel={(option) => (
                    option.name
                      || itemCategoryStore.itemCategories.filter((cat) => cat.id === option)[0].name
                  )}
                  className={classes.itemsCombo}
                  renderInput={(params) => <TextField {...params} label="Category" variant="outlined" />}
                  value={values.itemCategoryId}
                  onChange={(_event, value) => {
                    setFieldValue('itemCategoryId', value ? value.id : null);
                  }}
                />
              </div>
            </Grid>
            <Grid md={6}>
              {!values.customItemPrice && (
              <div className={classes.ratesContainer}>
                <Grid container>
                  <Grid md={4}>
                    <Field
                      as={TextField}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      label="Half day rate"
                      type="number"
                      value={values.halfDayRate}
                      handleChange={handleChange}
                      className={classes.rateInput}
                      name="halfDayRate"
                    />
                  </Grid>
                  <Grid md={4} justifyContent="center">
                    <Field
                      as={TextField}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      label="Full day rate"
                      type="number"
                      value={values.fullDayRate}
                      handleChange={handleChange}
                      className={classes.rateInput}
                      name="fullDayRate"
                    />
                  </Grid>
                  <Grid md={4} justifyContent="center">
                    <Field
                      as={TextField}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      label="Week rate"
                      type="number"
                      value={values.weekRate}
                      handleChange={handleChange}
                      className={classes.rateInput}
                      name="weekRate"
                    />
                  </Grid>
                </Grid>
              </div>
              )}
            </Grid>
            <Grid md={12} className={classes.addItemsWrapper}>
              <strong>Add Item</strong>
              <FieldArray
                name="kitItems"
                render={(arrayHelpers) => (
                  <div>
                    <KitItemsAutocomplete
                      itemStore={itemStore}
                      selectedItem={values.item}
                      kitItems={values.kitItems}
                      addItem={arrayHelpers.push}
                      editingKit={editingKit}
                    />

                    <KitItemsTable
                      removeKitItem={arrayHelpers.remove}
                      handleChange={handleChange}
                      {...values}
                    />

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => kitStore.updateKit(values, afterSave)}
                    >
                      Save
                    </Button>
                    <Button color="primary" onClick={onCancel}>Cancel</Button>
                  </div>
                )}
              />
            </Grid>
          </Grid>

        )
      }
    </Formik>
  );
};

KitForm.propTypes = {
  editingKit: PropTypes.object,
  afterSave: PropTypes.func,
  onCancel: PropTypes.func,
  itemStore: PropTypes.any,
  kitStore: PropTypes.any,
  itemCategoryStore: PropTypes.any,
};

export default observer(KitForm);
