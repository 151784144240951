import { observable, decorate } from 'mobx';
import config from '../config';
import RequestService from '../services/RequestService';

class HealthStore {
  healthy = false;

  healthCheck = (successCallback) => {
    RequestService.get(`${config.backendUrl}/health-check`,
      () => {
        this.healthy = true;
        successCallback && successCallback();
    });
  }
}

decorate(HealthStore, {
  healthy: observable,
});

export default HealthStore;
