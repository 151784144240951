import {
  Box, Divider, Grid, Typography,
} from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import WushCard from '../../../../components/WushCard';
import CartItem, { calculateItemCost } from './CartItem';

// eslint-disable-next-line
const calculateSubtotal = (selectedItems) => selectedItems.reduce((a, b) => a + (calculateItemCost(b) || 0), 0);

// eslint-disable-next-line
const calculateTax = (selectedItems) => +selectedItems.reduce((a, b) => a + +((calculateItemCost(b) * parseFloat(b.taxRate) || 0).toFixed(2)), 0).toFixed(2);

const ItemCart = ({ selectedItems, setItemQuantity, handleItemSelect }) => {
  const subTotal = calculateSubtotal(selectedItems);
  const tax = calculateTax(selectedItems);
  const total = subTotal + tax;
  return (
    <WushCard>
      <Box mb="0.5rem">
        <Typography variant="h5">Cart</Typography>
      </Box>
      <Divider />
      {selectedItems.length > 0
        ? (
          <Box>
            <Box>
              {selectedItems.map((item, index) => (
                <CartItem
                  item={item}
                  setItemQuantity={setItemQuantity}
                  index={index}
                  handleItemSelect={handleItemSelect}
                />
              ))}
            </Box>
            <Divider />
            <Grid container>
              <Grid item md={8}>
                <i>
                  Subtotal
                </i>
              </Grid>
              <Grid item md={4}>
                $
                {subTotal}
              </Grid>
              <Grid item md={8}>
                <i>
                  Tax
                </i>
              </Grid>
              <Grid item md={4}>
                $
                {tax}
              </Grid>
              <Divider />
              <Grid item md={8}>
                <strong>Order Total</strong>
              </Grid>
              <Grid item md={4}>
                $
                {total}
              </Grid>
            </Grid>
          </Box>
        )
        : (
          <Box py="2rem">
            <Typography variant="span"><em>There&apos;s nothing in your cart yet.</em></Typography>
          </Box>
        )}
    </WushCard>
  );
};

export default observer(ItemCart);
export { calculateSubtotal, calculateTax };
