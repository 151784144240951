import { Box, Dialog } from '@mui/material';
import React from 'react';

const style = {
  p: 4,
  borderRadius: '5px',
};

const WushModal = ({
  open, onClose, children, ...rest
}) => (
  <Dialog
    open={open}
    onClose={onClose}
    {...rest}
  >
    <Box sx={style}>
      {children}
    </Box>
  </Dialog>
);

export default WushModal;
