import {
  Box, Button, Divider, TextField, Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { useHistory } from 'react-router';
import WushCard from '../../components/WushCard';

const RegisterForm = Yup.object().shape({
  password: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Password is required'),
  repeatPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], "Passwords don't match!")
    .required('Repeat password is required'),
});

const ChangePassword = ({ authStore }) => {
  const history = useHistory();
  return (
    <Box pt={10} textAlign="center" width="100%">
      <Box width="50%" mr="auto" ml="auto">
        <WushCard>
          <Box p={5}>
            <Box mb={2}>
              <Typography variant="h4">Change Password</Typography>
              {authStore.needsNewPassword && <Typography variant="body1"><em>*You need to reset your password before accessing your account</em></Typography> }
            </Box>
            <Formik
              initialValues={{ password: '', repeatPassword: '' }}
              onSubmit={(values) => authStore.changePassword(values.password, () => { history.push('/home'); })}
              validationSchema={RegisterForm}
            >
              {
                ({
                  values, errors, handleChange, handleSubmit,
                }) => (
                  <Form>
                    <Box mb={2}>
                      <TextField
                        fullWidth
                        required
                        id="password"
                        label="Password"
                        type="password"
                        margin="normal"
                        autoComplete="current-password"
                        variant="outlined"
                        onChange={handleChange}
                        value={values.password}
                        error={errors.password}
                        helperText={errors.password}
                      />
                      <TextField
                        fullWidth
                        required
                        id="repeatPassword"
                        label="Repeat Password"
                        type="password"
                        margin="normal"
                        autoComplete="current-password"
                        variant="outlined"
                        onChange={handleChange}
                        value={values.repeatPassword}
                        error={errors.repeatPassword}
                        helperText={errors.repeatPassword}
                        onKeyUp={(e) => { if (e.key === 'Enter') handleSubmit(); }}
                      />
                    </Box>
                    <Box mb={2}>
                      <Button fullWidth variant="contained" onClick={handleSubmit}>
                        Update Password
                      </Button>
                    </Box>
                    <Box mb={2}>
                      <Divider />
                    </Box>
                    <Box>
                      <Button fullWidth variant="outlined" onClick={() => history.goBack()}>
                        Cancel
                      </Button>
                    </Box>
                  </Form>
                )
              }
            </Formik>
          </Box>
        </WushCard>
      </Box>
    </Box>
  );
};

export default ChangePassword;
