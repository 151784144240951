import {
  Avatar, Box, Divider, Grid, IconButton, ListItem, ListItemButton, ListItemText,
  Modal, TextField, Typography,
} from '@mui/material';
import React from 'react';
import Fuse from 'fuse.js';
import { FixedSizeList } from 'react-window';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { observer } from 'mobx-react';
import WushCard from '../../../../components/WushCard';
import AddCustomerForm from './AddCustomerForm';

const CustomerSearch = ({ customers, onSelect, createCustomer }) => {
  const [searchField, setSearchField] = React.useState(null);
  const [addCustomerModalOpen, setAddCustomerModalOpen] = React.useState(false);

  const fuse = new Fuse(customers, {
    keys: ['firstName'],
  });

  const searchedCustomers = searchField
    ? fuse.search(searchField).map((item) => item.item)
    : customers;
  const renderRow = (props) => {
    const { index, style } = props;

    return (
      <ListItem
        style={style}
        key={index}
        component="div"
        disablePadding
      >
        <ListItemButton onClick={() => onSelect(searchedCustomers[index])}>
          <Avatar>
            {`${searchedCustomers[index]?.firstName?.[0]}${searchedCustomers[index]?.lastName?.[0]}`}
          </Avatar>
          <ListItemText
            primary={`${searchedCustomers[index]?.firstName} ${searchedCustomers[index]?.lastName}`}
          />
        </ListItemButton>
      </ListItem>
    );
  };

  const addNewCustomerCallback = (newCustomer) => {
    onSelect(newCustomer);
    setAddCustomerModalOpen(false);
  };

  return (
    <WushCard>
      <Grid container>
        <Grid item md={8}>
          <Box mb="0.75rem" textAlign="left">
            <Typography variant="h5">Customer Search</Typography>
          </Box>
        </Grid>
        <Grid item md={4}>
          <Box textAlign="right">
            <IconButton onClick={() => setAddCustomerModalOpen(true)}>
              <AddCircleIcon />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
      <Box mb="1rem">
        <TextField
          fullWidth
          id="outlined-name"
          label="Search Item"
          value={searchField}
          onChange={(e) => { setSearchField(e.target.value); }}
        />
      </Box>
      <Divider />
      <Box>
        <FixedSizeList
          height={400}
          itemSize={46}
          itemCount={searchedCustomers.length}
          overscanCount={5}
        >
          {renderRow}
        </FixedSizeList>
      </Box>
      <Modal
        open={addCustomerModalOpen}
        onClose={() => setAddCustomerModalOpen(false)}
      >
        <AddCustomerForm
          onSubmit={(values) => createCustomer(values, addNewCustomerCallback)}
          handleCancel={() => setAddCustomerModalOpen(false)}
        />
      </Modal>
    </WushCard>
  );
};

export default observer(CustomerSearch);
