import {
  Box, Checkbox, Grid, List, ListItem, ListItemIcon, ListItemText, Typography, FormLabel,
} from '@mui/material';
import { format } from 'date-fns';
import React from 'react';
import ZoomInIcon from '@mui/icons-material/ZoomIn';

const EventSeriesEvents = ({
  eventSeries, events, selectedEvents, handleToggle, disabled, title,
}) => (
  <Box>
    <List dense component="div" role="list">
      <Box pl={3.5}>
        <FormLabel><Typography variant="h6">{title}</Typography></FormLabel>
      </Box>
      {events.map((event) => {
        const labelId = `transfer-list-item-${event.id}-label`;

        return (
          <ListItem
            key={event.id}
            role="listitem"
            button
            onClick={handleToggle(event)}
            disabled={(disabled && selectedEvents.indexOf(event) === -1) || event.eventFull}
          >
            <ListItemIcon>
              {eventSeries.userEnrolled ? <ZoomInIcon /> : (
                <Checkbox
                  checked={selectedEvents.indexOf(event) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              )}
            </ListItemIcon>
            <ListItemText id={labelId}>
              <Grid container>
                <Grid item md={8}>
                  {format(new Date(event.startDatetime), 'EEEE LLLL dd, yyyy H:mm b')}
                  {' '}
                  -
                  {format(new Date(event.endDatetime), 'H:mm b')}
                </Grid>
              </Grid>
            </ListItemText>
          </ListItem>
        );
      })}
    </List>
  </Box>
);

export default EventSeriesEvents;
