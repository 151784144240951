import React from 'react';
import CategoryIcon from '@mui/icons-material/Category';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import PeopleIcon from '@mui/icons-material/People';
import RedeemIcon from '@mui/icons-material/Redeem';
import Kits from './containers/kits/Kits';
import Items from './containers/items/Items';
import Categories from './containers/categories/Categories';
import Customers from './containers/customers/Customers';
import Dashboard from './containers/dashboard/Dashboard';

export const PAGES = {
  DASHBOARD: {
    title: "Dashboard",
    baseRoute: "dashboard",
    icon: <DashboardIcon />,
    component: Dashboard
  },
  CATEGORIES: {
    title: "Categories",
    baseRoute: "categories",
    icon: <CategoryIcon />,
    component: Categories
  },
  ITEMS: {
    title: "Items",
    baseRoute: "items",
    icon: <LibraryBooksIcon />,
    component: Items
  },
  KITS: {
    title: "Kits",
    baseRoute: "kits",
    icon: <RedeemIcon />,
    component: Kits
  },
  CUSTOMERS: {
    title: "Customers",
    baseRoute: "customers",
    icon: <PeopleIcon />,
    component: Customers
  }
}