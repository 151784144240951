import {
  Box, Button, Divider, Stack, Typography,
} from '@mui/material';
import { format } from 'date-fns';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import CreditPayment from './CreditCardPayment';

const EventInfo = ({
  event, handleEnrollment, handleUnenrollment, creditsRemaining, handleJoinWaitList,
}) => {
  const [enrolling, setEnrolling] = useState(false);
  const isEventSeries = event.isEventSeries || false;
  const history = useHistory();

  const handleEnrollmentButtonClick = () => {
    if (event.canUnenroll) {
      handleUnenrollment();
    }
    if (event.registrationsCount >= event.capacity) {
      return null;
    } if (creditsRemaining > 0) {
      if (event.enrollableWithCredits) {
        handleEnrollment();
      }
    } else if (event.isEventSeries) {
      history.push(`/event-series/${event.id}`);
    } else if (event.canEnroll) {
      setEnrolling(true);
    }
    return true;
  };

  const enrollmentButtonDisplay = () => {
    if (event.canUnenroll) {
      return 'Unenroll';
    }

    if (event.registrationsCount >= event.capacity) {
      return 'This event is full';
    }

    if (creditsRemaining > 0 && event.enrollableWithCredits) {
      return 'Enroll with credits';
    }

    if (event.canEnroll || event.isEventSeries) {
      return 'Enroll';
    }

    return 'You may not enroll at this time';
  };

  return (
    <Box>
      <Box mb={2}>
        <Box mb={2}>
          <Typography variant="h6">{event.name}</Typography>
          <Typography variant="body1"><strong>{format(new Date(event.startDatetime), 'EEEE LLLL dd, yyyy')}</strong></Typography>
        </Box>
        <Typography variant="body1">
          {/* eslint-disable-next-line */}
          <div dangerouslySetInnerHTML={{__html: event.description}} />
        </Typography>
        <Divider />
        <Typography variant="body2">{event.additionalInformation}</Typography>
      </Box>
      <Box>
        <Typography variant="body1">
          <strong>Starts at:</strong>
          {' '}
          {isEventSeries ? format(new Date(event.startDatetime), 'MM/dd') : format(new Date(event.startDatetime), 'H:mm b')}
        </Typography>
        <Typography variant="body1">
          <strong>Ends at:</strong>
          {' '}
          {isEventSeries ? format(new Date(event.endDatetime), 'MM/dd') : format(new Date(event.endDatetime), 'H:mm b')}
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography variant="body1">
          <strong>Currently enrolled:</strong>
          {' '}
          {event.registrationsCount}
        </Typography>
        <Typography variant="body1">
          <strong>Capacity:</strong>
          {' '}
          {event.capacity}
        </Typography>
        <Typography variant="body1">
          <strong>Cost:</strong>
          {' '}
          $
          {event.costDollars}
        </Typography>
      </Box>
      {event.userEnrolled && (
        <Box>
          <Typography variant="caption">You are currently enrolled</Typography>
        </Box>
      )}
      {!event.userEnrolled && event.userWaitlisted && (
        <Box>
          <Typography variant="caption">You are currently on the waitlist</Typography>
        </Box>
      )}
      <Box>
        <Stack spacing="1rem">
          {!enrolling && (
            <Button
              color={(event.userEnrolled && !event.isEventSeries) ? 'secondary' : 'primary'}
              variant="contained"
              onClick={handleEnrollmentButtonClick}
              disabled={event.eventFull && !event.userEnrolled}
            >
              {enrollmentButtonDisplay()}
            </Button>
          )}
          {
            event.eventFull && handleJoinWaitList
            && !event.userEnrolled && !event.userWaitlisted && (
            <Button
              variant="outlined"
              onClick={handleJoinWaitList}
            >
              Join Waitlist
            </Button>
            )
}
          {event.isEventSeries && event.userEnrolled && (
          <Button color="primary" variant="contained" onClick={() => (history.push('/my-event-series'))}>
            View Series
          </Button>
          )}
        </Stack>
      </Box>
      {
        enrolling && (
        <CreditPayment
          eventId={event.id}
          amount={event.costCents}
          description={event.name}
          handleSuccess={handleEnrollment}
        />
        )
      }
    </Box>
  );
};

export default EventInfo;
