import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import QRCode from 'react-qr-code';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { getScannerTicketReferenceNumber } from '../utils';

const ScannerTicketPrint = ({ scannerTicket }) => (scannerTicket ? (
  <Grid container>
    <Grid md={9}>
      <Box mb={2}>
        {scannerTicket.reservation.isServiceTicket
          && <Typography variant="h5"><strong>SERVICE TICKET</strong></Typography>}
        <Typography variant="h5"><strong>Ocean Rentals</strong></Typography>
        <Typography variant="body1">757-721-6210</Typography>
      </Box>
      <Box mb={2}>
        <Typography variant="h5">{scannerTicket?.reservation?.propertyName}</Typography>
        <Typography variant="body1">{scannerTicket?.reservation?.propertyAddressLineOne}</Typography>
        <Typography variant="body1">{scannerTicket?.reservation?.propertyAddressLineTwo}</Typography>
      </Box>
      <Box mb={3}>
        <Typography variant="body1">
          <strong>
            Start:
            {scannerTicket?.reservation.startDate && scannerTicket.reservation.startDate}
          </strong>
        </Typography>
        <Typography variant="body1">
          <strong>
            End:
            {scannerTicket?.reservation.endDate && scannerTicket.reservation.endDate}
          </strong>
        </Typography>
      </Box>
      <Box>
        <ul>
          {scannerTicket?.scannerTicketItems.map((item) => item.quantity > 0 && (
          <li>
            <Typography variant={scannerTicket.scannerTicketItems.filter((ticketItem) => ticketItem.quantity > 0).length > 5 ? 'body1' : 'h5'}>
              {item.quantity}
              {' '}
              {item.itemName}
            </Typography>
          </li>
          ))}
        </ul>
      </Box>
    </Grid>
    <Grid md={3} className="text-align-right">
      <Box>
        <Typography variant="body1">{getScannerTicketReferenceNumber(scannerTicket)}</Typography>
      </Box>
      <Box>
        <QRCode size="150" value={getScannerTicketReferenceNumber(scannerTicket)} />
      </Box>
      <Box>
        <Typography variant="body1">
          {scannerTicket?.ticketIndex}
          {' '}
          /
          {' '}
          {scannerTicket?.reservation?.scannerTickets.length}
          {' '}
          bags
        </Typography>
      </Box>
    </Grid>
  </Grid>
) : <Grid />);

ScannerTicketPrint.propTypes = {
  scannerTicket: PropTypes.object,
};

export default observer(ScannerTicketPrint);
