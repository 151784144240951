import {
  Box, Button, CircularProgress,
} from '@mui/material';
import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';

const SubmitButton = ({ handleSubmit, buttonText }) => {
  const [loading, setLoading] = useState(false);
  return (
    <Button fullWidth onClick={(e) => { setLoading(true); handleSubmit(e, setLoading); }} variant="contained" disabled={loading}>
      {loading ? <CircularProgress size={25} /> : buttonText}
    </Button>
  );
};

const CreditCardPayment = ({
  publicKey, clientSecret, handleSuccess, buttonText = 'Register', alertStore = null,
}) => {
  const [processing] = React.useState(false);

  const getOptions = () => ({
    clientSecret,
  });

  const stripePromise = loadStripe(publicKey);

  const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event, setLoading) => {
      // We don't want to let default form submission happen here,
      // which would refresh the page.
      event.preventDefault();

      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      try {
        const result = await stripe.confirmPayment({
          // `Elements` instance that was used to create the Payment Element
          elements,
          confirmParams: {
            return_url: 'https://google.com',
          },
          redirect: 'if_required',
        });

        if (result.error) {
          alertStore.setAlertMessage('Error processing payment', 'error');
          setLoading(false);
        } else {
          handleSuccess(result.paymentIntent);
        }
      } catch (e) {
        alertStore.setAlertMessage('Error processing payment', 'error');
        setLoading(false);
      }
    };

    return (
      <form onSubmit={(e) => { if (!processing) handleSubmit(e); }}>
        <Box mb="2rem">
          <PaymentElement />
        </Box>
        <SubmitButton handleSubmit={handleSubmit} buttonText={buttonText} />
      </form>
    );
  };

  return (
    <Box>
      <Elements stripe={stripePromise} options={getOptions()}>
        <CheckoutForm />
      </Elements>
    </Box>
  );
};

export default CreditCardPayment;
