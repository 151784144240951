import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Link,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { format } from 'date-fns';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PlaceIcon from '@mui/icons-material/Place';
import PersonIcon from '@mui/icons-material/Person';
import PublicEventRegistration from '../components/public/PublicEventRegistration';
import SurfboardButton from '../../../assets/images/Surfboard_Button.svg';
import Logo1 from '../../../assets/images/Ben_Gravy2x.png';
import Logo2 from '../../../assets/images/CatchSurf_Logo2x.png';
import Logo3 from '../../../assets/images/SA_Logo_SurfContest2x.png';
import Logo4 from '../../../assets/images/WAVE_BANDIT2x.png';
import LeftArt from '../../../assets/images/LeftArt2x.png';
import RightArt from '../../../assets/images/RightArt2x.png';

const SurfContest = ({
  authStore,
  baseStore,
  alertStore,
  event,
  registering,
  setRegistering,
  handleRegistrationSuccess,
}) => {
  if (!event) return <CircularProgress />;

  const dayOfEvent = format(new Date(event.startDatetime), 'iiii, LLLL d, u');
  const startTime = format(new Date(event.startDatetime), 'h bb');
  const endTime = format(new Date(event.endDatetime), 'h bb');

  return (
    <Box mb={1} mt={2} className="surf-contest-template">
      <Box mb={2}>
        <Grid container>
          <Grid item md={12} className="event-image">
            <img src={event.imageUrl} alt="Event" />
            <Box className="image-overlay-info">
              {/* <h2>{event.name}</h2> */}
              {/* <h5>{event.address1}</h5> */}
              {/* <Button
                disabled={event.eventFull}
                onClick={() => setRegistering(true)}
                className="surfboard-button"
              >
                <img src={SurfboardButton} alt="surfboard" />
                {event.eventFull ? <p>Event is full!</p> : <p>Reserve Your Spot!</p>}
              </Button> */}
            </Box>
          </Grid>

          <Box className="mobile-footer" display="flex" justifyContent="center">
            <Button
              disabled={event.eventFull}
              onClick={() => setRegistering(true)}
              className="sticky"
            >
              <h3>Reserve Your Spot</h3>
            </Button>
          </Box>

          <Box textAlign="center" width="100%" pt={5}>
            <Button
              disabled={event.eventFull}
              onClick={() => setRegistering(true)}
              className="surfboard-button"
            >
              <img src={SurfboardButton} alt="surfboard" />
              {event.eventFull ? <p>Event is full!</p> : <p>Reserve Your Spot!</p>}
            </Button>
          </Box>

          <Grid item md={12} display="flex" flexDirection="column" alignItems="center">
            <Box
              className="main-event-info"
              my={7}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <h4>{dayOfEvent}</h4>
              <h1>{event.name}</h1>
              {/* eslint-disable-next-line react/no-danger */}
              <div dangerouslySetInnerHTML={{ __html: event.description }} />
            </Box>

            <Grid container display="flex" className="three-sections">
              <Grid
                item
                md={4}
                xs={12}
                alignItems="center"
                display="flex"
                flexDirection="column"
                className="section"
                pr={1}
                textAlign="center"
              >
                <CalendarMonthIcon className="icon" />
                <h3>{dayOfEvent}</h3>
                <h3>
                  {startTime}
                  {' '}
                  to
                  {' '}
                  {endTime}
                </h3>
              </Grid>
              <Grid
                item
                md={4}
                xs={12}
                alignItems="center"
                display="flex"
                flexDirection="column"
                className="section"
              >
                <PlaceIcon className="icon" />
                <Typography variant="h3" textAlign="center">Swells’a Brewing in Kill Devil Hills</Typography>
                <p>{event.address1}</p>
              </Grid>
              <Grid
                item
                md={4}
                xs={12}
                alignItems="center"
                display="flex"
                flexDirection="column"
                className="section"
              >
                <PersonIcon className="icon" />
                <h3>Registration</h3>
                <p>
                  $
                  {event.costDollars}
                </p>
              </Grid>
            </Grid>
            <Box
              alignItems="center"
              display="flex"
              flexDirection="column"
              className="extra-info"
              my={3}
            >
              <h4>JOIN US FOR A DAY OF SHREDDING</h4>
              {/* eslint-disable-next-line react/no-danger */}
              <div dangerouslySetInnerHTML={{ __html: event.additionalInformation }} />
            </Box>
          </Grid>
        </Grid>

        <Box>
          <Grid container textAlign="center" spacing={0} direction="column" justifyContent="center">
            <Grid item spacing={0} direction="column" justifyContent="center" paddingTop={3}>
              <Box px={5} py={2} textAlign="center">
                <Button
                  disabled={event.eventFull}
                  onClick={() => setRegistering(true)}
                  className="surfboard-button second"
                >
                  <img src={SurfboardButton} alt="surfboard" />
                  {event.eventFull ? <p>Event is full!</p> : <p>Reserve Your Spot!</p>}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Dialog
        open={registering}
        onClose={() => setRegistering(false)}
        fullScreen
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setRegistering(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Event Registration
            </Typography>
          </Toolbar>
        </AppBar>
        <Box pb="5rem">
          <PublicEventRegistration
            event={event}
            handleSuccess={handleRegistrationSuccess}
            handleCancel={() => setRegistering(false)}
            alertStore={alertStore}
            authStore={authStore}
            baseStore={baseStore}
          />
        </Box>
      </Dialog>
    </Box>
  );
};

export default SurfContest;
