import { observable, action, decorate } from 'mobx';
import config from '../config';
import RequestService from '../services/RequestService';

class BaseStore {
  message = null;
  organization = null;
  messageLoading = true

  healthMessage = null;
  healthLoading = true;

  getHelloWorld = () => {
    if (this.messageLoading) {
      RequestService.get(`${config.backendUrl}/hello-world`, (response) => {
        this.message = response.data.message;
        this.organization = response.data.organization;
        this.messageLoading = false;
      });
    }
  }

  getHealthCheck = () => {
    if (this.healthLoading) {
      RequestService.get(`${config.backendUrl}/database-health-check`, (response) => {
        this.healthMessage = response.data.message;
        this.healthLoading = false;
      });
    }
  }

  getRecords = (preloadedObservable, endpoint, dataObservable) => {
    !preloadedObservable && RequestService.get(`${config.backendUrl}/${endpoint}`, (response) => {
      console.log(preloadedObservable)
      dataObservable = response.data
      preloadedObservable = true
      console.log(dataObservable)
      this.writeToSession()
    })
  }

  addRecord = (endpoint, updateData, observable, callback) => {
    RequestService.post(`${config.backendUrl}/${endpoint}`, updateData, (response) => {
      let updated = observable;
      updated.splice(0, 0, response.data)
      observable = updated
      this.writeToSession()
      callback && callback(response.data)
    })
  }
}

decorate(BaseStore, {
  message: observable,
  organization: observable,
  messageLoading: observable,
  healthMessage: observable,
  healthLoading: observable,
  getHelloWorld: action,
  getRecords: action
});

export default BaseStore;
