import {
  Box, FormControl, InputLabel, MenuItem, Select,
} from '@mui/material';
import React from 'react';

const WushSelectField = ({
  formik, name, label, options, ...rest
}) => (
  <Box sx={{ minWidth: 120 }}>
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={formik.values[name]}
        label={label}
        onChange={(e) => formik.setFieldValue(name, e.target.value)}
        {...rest}
      >
        {options?.map((option) => (
          <MenuItem value={option.value}>{option.label}</MenuItem>
        ))}
      </Select>
    </FormControl>
  </Box>
);

export default WushSelectField;
