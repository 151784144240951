import {
  Box, Breadcrumbs, Button, Grid, Link, Table, TableBody, TableCell, TableHead, TableRow,
  Typography,
} from '@mui/material';
// import { subDays, format } from 'date-fns';
import React, { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker, MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { subDays } from 'date-fns';
import { useHistory } from 'react-router';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

const Archives = ({ scannerStore }) => {
  const history = useHistory();
  const defaultStartDate = subDays(new Date(), 30);
  const defaultEndDate = new Date();
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [filter, setFilter] = useState(null);
  const [filteredArchives, setFilteredArchives] = useState(null);
  const getArchives = (forceUpdate = false) => {
    scannerStore.getArchives(forceUpdate, startDate, endDate);
  };
  getArchives();

  const applyFilter = (newFilter) => {
    if (newFilter === 'default') {
      setFilteredArchives(scannerStore.archivedReservations);
    } else {
      setFilteredArchives(scannerStore.archivedReservations.filter((reservation) => (
        reservation[newFilter]
      )));
    }
    setFilter(newFilter);
  };

  return (
    <Box>
      <Breadcrumbs aria-label="breadcrumb" mb={2}>
        <Link underline="hover" color="inherit" href="/scanner">
          Scanner
        </Link>
        <Typography color="text.primary">
          Archives
        </Typography>
      </Breadcrumbs>
      <Typography variant="h3">Archives</Typography>
      <Box border="1px solid white" padding={3}>
        <Typography variant="h5">Select Date Range</Typography>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container justifyContent="space-around">
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="yyyy-MM-dd"
              margin="normal"
              id="date-picker-inline"
              label="Start date"
              value={startDate}
              onChange={setStartDate}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="yyyy-MM-dd"
              margin="normal"
              id="date-picker-inline"
              label="End date"
              value={endDate}
              onChange={setEndDate}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            <Button variant="contained" onClick={() => getArchives(true)}>
              Refresh
            </Button>
          </Grid>
        </MuiPickersUtilsProvider>
      </Box>
      <Box border="1px solid white" padding={3} mb={5}>
        <Typography variant="h5">Other Filters (Coming Soon)</Typography>
        <Button
          variant={filter === 'isServiceTicket' ? 'contained' : 'outlined'}
          onClick={() => applyFilter('isServiceTicket')}
        >
          Service Tickets
        </Button>
        <Button
          variant={filter === 'default' ? 'contained' : 'outlined'}
          onClick={() => applyFilter('default')}
        >
          All
        </Button>
      </Box>
      <Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                Property
              </TableCell>
              <TableCell>
                Start date
              </TableCell>
              <TableCell>
                Is service ticket
              </TableCell>
              <TableCell>
                View
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {scannerStore.archivedReservationsPreLoaded
              && (filteredArchives || scannerStore.archivedReservations).map((reservation) => (
                <TableRow>
                  <TableCell>
                    {reservation.propertyName}
                  </TableCell>
                  <TableCell>
                    {reservation.startDate}
                  </TableCell>
                  <TableCell>
                    {reservation.isServiceTicket ? 'Yes' : 'No'}
                  </TableCell>
                  <TableCell>
                    <Button variant="contained" onClick={() => history.push(`/scanner/${reservation.id}`)}>
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

Archives.propTypes = {
  scannerStore: PropTypes.any,
};

export default observer(Archives);
