import { Box, Typography } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router';

const Public = ({ domainStore }) => {
  const history = useHistory();

  if (domainStore.organization.publicEvents?.length === 1) {
    history.push(`/public-events/${domainStore.organization.publicEvents[0].id}`);
  }

  return (
    <Box>
      <Typography>{domainStore.organization.name}</Typography>
    </Box>
  );
};

export default Public;
