import React from 'react';
import Deliveries from './containers/deliveries/Deliveries';
import DeliveryRoutes from './containers/deliveries/DeliveryRoutes';
import NewReservation from './containers/NewReservation';
import PickupRoutes from './containers/pickups/PickupRoutes';
import Pickups from './containers/pickups/Pickups';
import Reservations from './Reservations';

export const PAGES = {
  NEW_RESERVATION : {
    title: "New Reservation",
    baseRoute: "new-reservation",
    component: <NewReservation />
  },
  RESERVATIONS: {
    title: "Reservations",
    baseRoute: "reservations",
    component: <Reservations />,
  },
  DELIVERIES: {
    title: "Deliveries",
    baseRoute: "deliveries",
    component: <Deliveries />
  },
  DELIVERY_ROUTES: {
    title: "Delivery Routes",
    baseRoute: "delivery-routes",
    component: <DeliveryRoutes />
  },
  PICKUPS: {
    title: "Pickups",
    baseRoute: "pickups",
    component: <Pickups />
  },
  PICKUP_ROUTES: {
    title: "Pickup Routes",
    baseRoute: "pickup-routes",
    component: <PickupRoutes />
  }
};