import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import format from 'date-fns/format';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const EventInfoSnippet = ({ event }) => {
//   console.log(event);

  const dayOfEvent = format(new Date(event.startDatetime), 'MMM, d, y');
  const startTime = format(new Date(event.startDatetime), 'h:mm a');
  const endTime = format(new Date(event.endDatetime), 'h:mm a');

  return (
    <Box className="event-info-snippet">
      <Grid container>
        <Grid item xs={3}>
          <img
            src="https://images.unsplash.com/photo-1595704277884-edb89b06d4cf?ixlib=rb-4.0.3
            &ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
            alt="surfer"
          />
        </Grid>
        <Grid item xs={9} className="event-box">
          <Box className="event-title">
            <Typography>{event.name}</Typography>
          </Box>
          <Box className="event-date-time" display="flex" flexDirection="row">
            <Box display="flex" flexDirection="row" alignItems="center">
              <CalendarTodayIcon className="icon" />
              <Typography ml={0.5}>{dayOfEvent}</Typography>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center">
              <AccessTimeIcon className="icon" />
              <Typography ml={0.5}>
                {startTime}
                {' '}
                -
                {' '}
                {endTime}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center" className="locations">
              <LocationOnIcon className="icon" />
              <Typography ml={0.5}>{event.address1}</Typography>
            </Box>
          </Box>
          <Box className="registration">
            <Typography>
              Registration: $
              {event.costDollars}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EventInfoSnippet;
