import React from 'react';
import {
  Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Grid,
} from '@mui/material';
import WushTextField from '../../../components/WushTextField';

const AdditionalInformationForm = ({ formik }) => {
  const handleRadioChange = (name, value) => {
    formik.setFieldValue(name, value === 'true');
  };

  return (
    <FormControl>
      <Grid container>
        <Grid item md={6}>
          <Box mb={2} px={2} width="100%">
            <WushTextField formik={formik} name="phone" label="Phone number" />
          </Box>
        </Grid>
        <Grid item md={6}>
          <Box mb={2} px={2} width="100%">
            <WushTextField formik={formik} name="email" label="Email" disabled />
          </Box>
        </Grid>
      </Grid>
      <Box mx={2} mb={2}>
        <FormLabel id="demo-radio-buttons-group-label">Do you wish to receive email communications?</FormLabel>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
          onChange={(event) => handleRadioChange('optInEmail', event.target.value)}
          value={formik.values?.optInEmail}
        >
          <FormControlLabel value control={<Radio />} label="Yes" />
          <FormControlLabel value={false} control={<Radio />} label="No" />
        </RadioGroup>
      </Box>
      <Box mx={2}>
        <FormLabel id="demo-radio-buttons-group-label">Do you wish to receive SMS communications?</FormLabel>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
          onChange={(event) => handleRadioChange('optInSms', event.target.value)}
          value={formik.values?.optInSms}
        >
          <FormControlLabel value control={<Radio />} label="Yes" />
          <FormControlLabel value={false} control={<Radio />} label="No" />
        </RadioGroup>
      </Box>
    </FormControl>
  );
};

export default AdditionalInformationForm;
